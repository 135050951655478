import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import SignatureCanvas from "react-signature-canvas";
import "./styles.css";
import icons from "../../../constants/icons";
import { v4 as uuidv4 } from "uuid";

class NewSignature extends React.Component {
  state = {
    error: {},
    file: "",
    accounts: [],
    signatureUrl: "",
    password: "",
    user: {},
    digitalKey: uuidv4(),
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];
    delete error.user;

    this.setState({ error, [field]: e.target.value });
  }

  handleClear = () => {
    this.sigCanvas.clear();
    this.setState({ signatureUrl: "" });
  };

  handleGenerate = () => {
    this.setState({
      signatureUrl: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png"),
    });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.getBase64(file, (signatureUrl) => {
        this.setState({ file, signatureUrl });
      });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  handleUploadClick = () => {
    this.refs.refInput.click();
  };

  handleSendCode() {
    const { user } = this.state;

    this.setState({ isSendingCode: true });

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_PSRP_BASE_API}/authentication/admin`,
      data: {
        email: user.email,
        isPasscode: true,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({ isSendingCode: false, otpSent: true });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].send_otp_success +
            " " +
            user.email
        );
      })
      .catch((error) => {
        this.setState({ isSendingCode: false });

        toastMessage(
          "error",
          language[this.props.defaultLanguage].send_otp_error
        );
      });
  }

  validateForm() {
    let { error, password, user } = this.state;

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    }

    if (!user.id || user.email) {
      toastMessage(
        "error",
        language[this.props.defaultLanguage].something_went_wrong
      );

      error.user = language[this.props.defaultLanguage].something_went_wrong;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    try {
      const { password, signatureUrl, user, error, digitalKey } = this.state;

      if (Object.keys(error).length > 0)
        return toastMessage(
          "error",
          language[this.props.defaultLanguage].something_went_wrong
        );

      this.setState({ isSubmitting: true });

      const options = {
        method: "PUT",
        url: `${process.env.REACT_APP_PSRP_BASE_API}/account`,
        data: {
          id: user.id,
          email: user.email,
          signature: signatureUrl,
          digitalKey,
          password,
          returnSignature: true,
        },
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };

      await axios(options);

      this.setState({ isSubmitting: false, otpSent: false });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_signature_success
      );
    } catch (error) {
      toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_to_add_signature
      );

      this.setState({ isSubmitting: false });
    }
  };

  handleDownloadDigitalKey() {
    const { digitalKey, signatureUrl } = this.state;

    this.setState({ isDownloading: true });

    try {
      const content = `DIGITAL-KEY= ${digitalKey}-PSRP-S-SIGNATURE=${signatureUrl}`;
      const blob = new Blob([content], { type: "text/plain" });
      const file = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = file;
      a.download = "MY-PSRP-SIGNATURE-" + digitalKey + ".txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(file);

      this.setState({ isDownloading: false });
    } catch (error) {
      this.setState({ isDownloading: false });

      toastMessage("error", "Unable to download file ");
    }
  }

  handleCopyDigitalKey() {
    const { digitalKey } = this.state;
    navigator.clipboard
      .writeText(digitalKey)
      .then(() => {
        toastMessage("success", "Digital key copied to clipboard");
      })
      .catch((err) => {
        toastMessage(
          "error",
          "Unable to copy:",
          err ? JSON.stringify(err) : ""
        );
      });
  }

  render() {
    return (
      <div>
        <div>
          <div style={{ backgroundColor: "#eee", display: "flex" }}>
            <div className="sigCanvas">
              <SignatureCanvas
                ref={(ref) => {
                  this.sigCanvas = ref;
                }}
                penColor="black"
                canvasProps={{
                  width: window.innerWidth + "px",
                  height: 300,
                }}
              />
            </div>
            <div className="sigButtons">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <Button
                  text={language[this.props.defaultLanguage].clear}
                  onPress={this.handleClear.bind(this)}
                  className="btn-default btn-sm"
                  icon={icons.delete}
                />
                <Button
                  text={language[this.props.defaultLanguage].save}
                  onPress={this.handleGenerate.bind(this)}
                  className="btn-sm"
                  icon={icons.check}
                />
              </div>
              <div style={{ borderTop: "1px solid #ddd", paddingTop: "1rem" }}>
                <Button
                  text={language[this.props.defaultLanguage].upload_img}
                  onPress={this.handleUploadClick.bind(this)}
                  className="btn-sm btn-default"
                  icon={icons.photo}
                />
              </div>
            </div>
          </div>
          {this.state.signatureUrl !== "" && (
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="sig-img-container">
                    <img src={this.state.signatureUrl} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label={language[this.props.defaultLanguage].digital_key}
                    placeholder={
                      language[this.props.defaultLanguage]
                        .digital_key_placeholder
                    }
                    onChange={(e) => this.onChangeText("digitalKey", e)}
                    rightButtonText={language[this.props.defaultLanguage].copy}
                    rightButtonSubmitting={this.state.isSendingCode}
                    rightButtonPressed={this.handleCopyDigitalKey.bind(this)}
                    leftIcon={icons.key}
                    error={this.state.error.digitalKey}
                    value={this.state.digitalKey}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label={language[this.props.defaultLanguage].password}
                    placeholder={
                      language[this.props.defaultLanguage].password_placeholder
                    }
                    onChange={(e) => this.onChangeText("password", e)}
                    // rightButtonText={!this.state.otpSent && "Get Code"}
                    // rightButtonClassName={
                    //   !this.state.otpSent && "btn-transparent"
                    // }
                    // rightButtonSubmitting={this.state.isSendingCode}
                    // rightButtonPressed={this.handleSendCode.bind(this)}
                    leftIcon={icons.password}
                    // type="number"
                    // maxLength={6}
                    error={this.state.error.password}
                    type="password"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-bold">
                    Your digital signature has been generated
                  </h3>
                  <p>Download this file and keep it somewhere safe</p>
                  <br />
                  <Button
                    text="Download Digital Key"
                    className="btn-default"
                    onPress={this.handleDownloadDigitalKey.bind(this)}
                    isSubmitting={this.state.isDownloading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.signatureUrl !== "" && (
          <div className="modal-footer gap-2">
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        )}
        <input
          style={{ display: "none" }}
          type="file"
          onChange={this.handleFileChange.bind(this)}
          ref="refInput"
          accept="image/*"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSignature);
