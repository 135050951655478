export default (arr, level, institution) => {
  try {
    if (!arr) return [];
    return arr.sort((a, b) => {
      if (a[level] !== b[level]) {
        return a[level] - b[level];
      } else {
        return a[institution].localeCompare(b[institution]);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
