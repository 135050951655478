// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-container {
  display: flex;
  padding-top: 5rem;
  background-color: var(--primary-color-5);
  min-height: 200px;
}

.features-vertical-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.features-vertical-container .item-horizontal {
  display: flex;
  min-height: 130px;
  position: relative;
}

.features-vertical-container .item-horizontal .border-connector {
  position: absolute;
  top: 20px;
  left: 31px;
  border: 1px solid var(--primary-color);
  bottom: 0;
}

.features-container .feature-item {
  display: flex;
  margin-bottom: 2rem;
}

.features .icon-container {
  width: 45px;
  height: 45px;
  z-index: 999;
}

.features .icon-container span {
  color: var(--light-color);
}

.features .feature-description {
  margin-left: 1rem;
  flex: 1 1;
}

.features .feature-description p {
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Features/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,sCAAsC;EACtC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,SAAO;AACT;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".features-container {\n  display: flex;\n  padding-top: 5rem;\n  background-color: var(--primary-color-5);\n  min-height: 200px;\n}\n\n.features-vertical-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.features-vertical-container .item-horizontal {\n  display: flex;\n  min-height: 130px;\n  position: relative;\n}\n\n.features-vertical-container .item-horizontal .border-connector {\n  position: absolute;\n  top: 20px;\n  left: 31px;\n  border: 1px solid var(--primary-color);\n  bottom: 0;\n}\n\n.features-container .feature-item {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.features .icon-container {\n  width: 45px;\n  height: 45px;\n  z-index: 999;\n}\n\n.features .icon-container span {\n  color: var(--light-color);\n}\n\n.features .feature-description {\n  margin-left: 1rem;\n  flex: 1;\n}\n\n.features .feature-description p {\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
