import React from "react";
import { SingleRequest } from "../../../components/UserRequests";
import { withRouter } from "../../../utils/withRouter";

class ViewSingleRequestScreen extends React.Component {
  render() {
    return <SingleRequest requestId={this.props?.routeParams?.requestId} />;
  }
}

export default withRouter(ViewSingleRequestScreen);
