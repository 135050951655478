import React from "react";
import "./styles.css";
import { LandingPageLayout } from "../../../Layout";
import { connect } from "react-redux";
import { setActiveTab } from "../../../actions/LandingCategoryTab";
import language from "../../../language";

class CategoryTab extends React.Component {
  render() {
    const menus = [
      {
        title: language[this.props.defaultLanguage].individual_services,
      },
      {
        title: language[this.props.defaultLanguage].institution_services,
      },
    ];

    return (
      <LandingPageLayout className="category-tabs">
        <div className="tabs-container">
          {menus.map((menu, index) => {
            console.log(this.props);
            console.log({ menu });
            return (
              <div
                className={`category-tab ${
                  this.props.landingCategoryActiveTab === index ? "active" : ""
                }`}
                key={index}
                onClick={() => this.props.dispatch(setActiveTab(index))}
              >
                <span>{menu.title}</span>
              </div>
            );
          })}
        </div>
      </LandingPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { landingCategoryActiveTab } = state.LandingCategoryTab;
  return {
    landingCategoryActiveTab,
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CategoryTab);
