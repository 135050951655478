// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: var(--bg-color);
}

.splash-container .fetch-service-error-container {
  max-width: 300px;
  margin-top: 2rem;
}

.splash-container .fetch-service-error-container p {
  margin-bottom: 1rem;
}

.splash-logo-container {
}

.splash-logo-container img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.splash-logo-container span {
  color: var(--text-color);
  font-weight: 300;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .splash-container {
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 980px) {
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Splash/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA,uCAAuC;AACvC;EACE;EACA;AACF;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".splash-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  flex-direction: column;\r\n  background-color: var(--bg-color);\r\n}\r\n\r\n.splash-container .fetch-service-error-container {\r\n  max-width: 300px;\r\n  margin-top: 2rem;\r\n}\r\n\r\n.splash-container .fetch-service-error-container p {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.splash-logo-container {\r\n}\r\n\r\n.splash-logo-container img {\r\n  width: 60px;\r\n  height: 60px;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.splash-logo-container span {\r\n  color: var(--text-color);\r\n  font-weight: 300;\r\n}\r\n\r\n/* MEDIA QUERIES - RESPONSIVE CONTROL */\r\n@media screen and (max-width: 500px) {\r\n  .splash-container {\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n}\r\n\r\n@media screen and (max-width: 980px) {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
