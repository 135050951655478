import language from "../language";

export const validateNationalId = ({ props, field, value }) => {
  let error = {},
    validFields = {},
    {
      invalid_national_id,
      national_id_required,
      national_id_is_valid_and_is_fetching_info,
    } = language[props.defaultLanguage];

  if (!value || value === "") {
    error[field] = national_id_required;
  } else if (
    value.length !== Number(process.env.REACT_APP_NATIONAL_ID_LENGTH)
  ) {
    error[field] = invalid_national_id;
  } else {
    delete error[field];

    validFields[field] = national_id_is_valid_and_is_fetching_info;
  }

  return { error, validFields };
};

export const validatePassportNumber = ({ props, field, value }) => {
  let error = {},
    { invalid_passport_number, passport_number_required } =
      language[props.defaultLanguage];

  const expr = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

  if (!value || value === "") {
    error[field] = passport_number_required;
  }

  if (!expr.test(value)) {
    error[field] = invalid_passport_number;
  }

  return error;
};

export const validateEmployeeId = ({ props, field, value }) => {
  let error = {},
    validFields = {},
    {
      employee_required,
      employee_is_valid_and_is_fetching_info,
      invalid_employee_id,
    } = language[props.defaultLanguage];

  if (!value || value === "") {
    error[field] = employee_required;
  } else if (value.length < 9) {
    error[field] = invalid_employee_id;
  } else {
    delete error[field];

    validFields[field] = employee_is_valid_and_is_fetching_info;
  }

  return { error, validFields };
};
