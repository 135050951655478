import { connect } from "react-redux";
import "./styles.css";
import isJsonString from "../../../utils/isJsonString";
import { JSONStringRowView } from "../../../utils/deepFind";

const Questionnaire = (props) => {
  return (
    <>
      {props?.data?.map((el, i) => {
        if (el.answerType !== "file") {
          return (
            <div
              className={`questionnaire-item-container ${props.styles}`}
              key={i}
            >
              <div className="questionnaire-answer-count">Q</div>
              <div style={{ flex: 1 }}>
                <p className="question-text text-bold">{el.questionText}</p>
                {isJsonString(el.answer) || typeof el.answer === "object" ? (
                  JSONStringRowView(el.answer)
                ) : (
                  <p className="answer-text">{el.answer}</p>
                )}
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaire);
