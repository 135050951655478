import React, { useEffect } from "react";
import { Route, Routes, Outlet, useParams } from "react-router-dom";
import { HomeAdminDashboardLayout } from "../../Layout";
import sidebarMenus from "../../constants/adminSidebarMenus";
import ViewSingleServiceScreen from "./Services/ViewSingleServiceScreen";
import ViewSingleDepartmentScreen from "./Department/ViewSingleDepartmentScreen";
import { getStorage } from "../../utils/storage";
import ViewSingleRequestScreen from "../AdminDashboard/Requests/ViewSingleRequestScreen";

const HomeDashboardScreen = () => {
  const routes = [...sidebarMenus()];

  useEffect(() => {
    async function userInfo() {
      const user = await getStorage();

      // if (!user.token) return (window.location.href = "/landing");
    }

    userInfo();
  });

  return (
    <HomeAdminDashboardLayout {...useParams()}>
      <Routes>
        {routes.map((route, index) => {
          return (
            <>
              <Route key={index} path={route.path} element={route.component} />

              {route.menus && (
                <Route
                  key={index}
                  path={route.path + "/*"}
                  element={subMenusRoutes(route)}
                />
              )}
            </>
          );
        })}
        <Route
          path={"/services/single-view/:serviceId"}
          element={<ViewSingleServiceScreen />}
        />
        <Route
          path={"/requests/single-view/:requestId"}
          element={<ViewSingleRequestScreen />}
        />
        <Route
          path={"/departments/:departmentId"}
          element={<ViewSingleDepartmentScreen />}
        />
      </Routes>
      <Outlet />
    </HomeAdminDashboardLayout>
  );
};

const subMenusRoutes = (route) => {
  return (
    <Routes>
      <Route path="/" element={route.component} />
      {route.menus.map((el, index) => {
        return (
          <>
            <Route key={index} path={el.path} element={el.component} />
          </>
        );
      })}
    </Routes>
  );
};

export default HomeDashboardScreen;
