import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../../Input";
import { Button } from "../../../Button";
import language from "../../../../language";
import axios from "axios";
import toastMessage from "../../../../utils/toastMessage";
import availableOptions from "../../../../constants/availableOptions";
import { serviceCategoriesOptions } from "../../../../constants/servicesOptions";
import icons from "../../../../constants/icons";
import { getStorage } from "../../../../utils/storage";
import apiError from "../../../../utils/apiError";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class NewServiceCategory extends React.Component {
  state = {
    name: "",
    description: "",
    available: availableOptions[0],
    error: {},
    isSubmitting: false,
  };

  componentDidMount() {
    if (this.props._id) {
      let available = {
        label: this.props.available + "",
        value: this.props.available + "",
      };

      let type =
        serviceCategoriesOptions(this.props.defaultLanguage)?.find(
          (el) => el.value === this.props.type
        ) || [];

      this.setState({ ...this.props, type, available });
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { name, type, error } = this.state;

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].service_category_name_required;
    }

    if (!type) {
      error.type =
        language[this.props.defaultLanguage].service_category_type_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_service_category,
      error_update_service_category,
      add_service_category_success,
      update_service_category_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, type, description, available } = this.state,
        url = `${API_URL}/serviceCategory`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        type: type.value,
        description,
        available: available.value,
        institution: process.env.REACT_APP_INSTITUTION,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? update_service_category_success
              : add_service_category_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT"
              ? error_update_service_category
              : error_add_service_category
          );

          this.setState({
            isSubmitting: false,
          });

          const {
            error_update_service_category,
            error_add_service_category,
            service_category_exist,
          } = language[this.props.defaultLanguage];

          this.setState({
            isSubmitting: false,
          });

          apiError(error, {
            requestMethod: method,
            addErrorMessage: error_add_service_category,
            updateErrorMessage: error_update_service_category,
            dataExistErrorMessage: service_category_exist,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <Input
            leftIcon={icons.category}
            placeholder={
              language[this.props.defaultLanguage]
                .service_category_name_placeholder
            }
            label={language[this.props.defaultLanguage].service_category_name}
            required
            value={this.state.name}
            onChange={(e) => this.onChangeText("name", e)}
            error={this.state.error.name}
          />
          <Input
            leftIcon={icons.description}
            placeholder={
              language[this.props.defaultLanguage]
                .service_category_description_placeholder
            }
            label={
              language[this.props.defaultLanguage].service_category_description
            }
            value={this.state.description}
            onChange={(e) => this.onChangeText("description", e)}
            error={this.state.error.description}
          />
          <Select
            options={serviceCategoriesOptions(this.props.defaultLanguage)}
            leftIcon={icons.type}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].service_Category_type}
            required
            value={this.state.type}
            onChange={(e) => this.onChangeText("type", e)}
            error={this.state.error.type}
          />
          <Select
            options={availableOptions}
            leftIcon={icons.status}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].available}
            required
            value={this.state.available}
            onChange={(e) => this.onChangeText("available", e)}
            error={this.state.error.available}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewServiceCategory);
