import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { getStorage } from "../../utils/storage";
class RequestInfo extends React.Component {
  state = {
    isLoading: false,
    data: [],
    search_text: "",
    selected_data: {},
    error: {},
    csvData: [],
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].request_number,
        key: "_id",
      },
      {
        title: language[this.props.defaultLanguage].service,
        key: "service.name",
      },
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },
      {
        title: language[this.props.defaultLanguage].employee_id,
        key: "user.employeeId",
      },
      {
        title: language[this.props.defaultLanguage].firstName,
        key: "user.firstName",
      },
      {
        title: language[this.props.defaultLanguage].lastName,
        key: "user.lastName",
      },
      // {
      //   title: language[this.props.defaultLanguage].status,
      //   key: "status",
      //   type: "status",
      // },
      {
        title: language[this.props.defaultLanguage].date_requested,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    return (
      <>
        <Table
          data={this.props.data}
          isLoading={this.props.isLoading}
          headers={this.returnTableHeaders()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
