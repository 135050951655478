import React from "react";
import "./styles.css";
import { Input } from "../../Input";
import language from "../../../language";
import { connect } from "react-redux";
import { fetchedServices } from "../../../actions/Services";
import icons from "../../../constants/icons";

class Search extends React.Component {
  state = {
    searchInput: "",
    results: [],
  };

  componentDidMount() {
    let { copyServices } = this.props;

    if (this.state.searchInput === "") {
      this.props.dispatch(fetchedServices(copyServices));
    }
  }

  onChangeSearchInput(e) {
    let { copyServices } = this.props;
    let newServicesArray = [];
    let searchInput = e.target.value;

    this.setState({ searchInput });

    copyServices.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchInput.toLowerCase()) !==
        -1
      ) {
        newServicesArray.push(el);
      }
    });

    this.setState({
      results: newServicesArray,
    });

    // this.props.dispatch(fetchedServices(newServicesArray));
  }

  render() {
    return (
      <div className="search-container">
        <Input
          leftIcon="search"
          placeholder={language[this.props.defaultLanguage].search_service_text}
          value={this.state.searchInput}
          onChange={(e) => this.onChangeSearchInput(e)}
          inputClassName="shadow"
          styles={{ marginBottom: 0 }}
          className="no-border"
        />
        {this.state.searchInput !== "" && (
          <div className="search-results-container">
            {this.state.results.map((el, i) => {
              return (
                <div
                  className="search-results-item"
                  key={i}
                  onClick={() =>
                    (window.location.href =
                      this.props?.user?.token &&
                      this.props?.user?.role === "user"
                        ? `/public-servant/request/${el._id}`
                        : `/login?serviceId=${el._id}`)
                  }
                >
                  <div className="icon-container">
                    <span className={process.env.REACT_APP_ICONS_TYPE}>
                      {icons.service}
                    </span>
                  </div>
                  <div className="name">
                    <span>{el.name}</span>
                  </div>
                  <div className="icon-container right-icon">
                    <span className={process.env.REACT_APP_ICONS_TYPE}>
                      {icons.chevronRight}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { searchInput, copyServices } = state.Services;
  return {
    defaultLanguage,
    searchInput,
    copyServices,
  };
};

export default connect(mapStateToProps)(Search);
