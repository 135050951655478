import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { DashboardHeader } from "../../components/Header";
import { Notifications } from "../../components/Notifications";
import { Tabs } from "../../components/Tabs";

class NotificationScreen extends React.Component {
  render() {
    const { notifications_title, unread_notification, read_notification } =
      language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader title={notifications_title} />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: unread_notification,
                  data: <Notifications viewed={false} />,
                },
                {
                  title: read_notification,
                  data: <Notifications viewed />,
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NotificationScreen);
