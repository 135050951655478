import React from "react";
import { Modal } from "../Modal";
import { AttachmentPreview } from "../Attachment";

class UserGuideAttachments extends React.Component {
  state = {
    showAttachmentModal: false,
  };
  handleShowModal = (modal) => {
    this.setState({
      [modal]: true,
    });
  };

  handleCloseModal = (modal) => {
    this.setState({
      [modal]: false,
    });
  };
  render() {
    return (
      <>
        <div className="user-guide-attachment">
          {this.props.imageUrl && this.props.imageUrl instanceof Array ? (
            this.props.imageUrl.map((el) => {
              return (
                <div style={{ marginBottom: "1.5rem" }}>
                  <img
                    src={el}
                    loading="lazy"
                    onClick={this.handleShowModal.bind(
                      this,
                      "showAttachmentModal"
                    )}
                  />
                </div>
              );
            })
          ) : (
            <img
              src={this.props.imageUrl}
              loading="lazy"
              onClick={this.handleShowModal.bind(this, "showAttachmentModal")}
            />
          )}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentModal")}
          show={this.state.showAttachmentModal}
          size="lg"
        >
          <AttachmentPreview url={this.props.imageUrl} />
        </Modal>
      </>
    );
  }
}

export default UserGuideAttachments;
