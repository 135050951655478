import React, { useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import MyRequestsDashboard from "./Requests/MyRequestsDashboard";
import RequestServiceScreen from "../PublicServant/Requests/RequestServiceScreen";
import ViewSingleRequestScreen from "./Requests/ViewSingleRequestScreen";
import { HomePublicServantDashboardLayout } from "../../Layout";
import SettingScreen from "./SettingScreen";
import NotificationScreen from "../common/NotificationScreen";
import { getStorage } from "../../utils/storage";

const PublicServantScreen = () => {
  useEffect(() => {
    async function userInfo() {
      const user = await getStorage();

      if (!user.token) return (window.location.href = "/landing");
    }

    userInfo();
  });

  return (
    <HomePublicServantDashboardLayout>
      <Routes>
        <Route path={"/my requests"} element={<MyRequestsDashboard />} />
        <Route path={"/settings"} element={<SettingScreen />} />
        <Route
          path={"/request/:serviceId"}
          element={<RequestServiceScreen />}
        />
        <Route
          path={"/request/:serviceId/:requestId"}
          element={<RequestServiceScreen />}
        />
        <Route
          path={"/requests/single-view/:requestId"}
          element={<ViewSingleRequestScreen />}
        />
        <Route path={"/notifications"} element={<NotificationScreen />} />
      </Routes>
      <Outlet />
    </HomePublicServantDashboardLayout>
  );
};

export default PublicServantScreen;
