import language from "../../../language";

export default (that) => {
  let {
    error,
    nationalId,
    firstName,
    identificationDocumentType,
    ippisNumber,
    password,
  } = that.state;

  if (firstName === "") {
    error.firstName = language[that.props.defaultLanguage].first_name_required;
  }

  if (!identificationDocumentType) {
    error.identificationDocumentType =
      language[
        that.props.defaultLanguage
      ].identification_document_type_required;
  }

  if (
    identificationDocumentType.value === "ippisNumber" &&
    ippisNumber === ""
  ) {
    error.ippisNumber =
      language[that.props.defaultLanguage].ippis_number_required;
  }

  if (identificationDocumentType.value === "nationalId" && nationalId === "") {
    error.nationalId =
      language[that.props.defaultLanguage].national_id_required;
  }

  if (
    identificationDocumentType.value === "passportNumber" &&
    nationalId === ""
  ) {
    error.passportNumber =
      language[that.props.defaultLanguage].passport_number_required;
  }

  if (password === "") {
    error.password = language[that.props.defaultLanguage].password_required;
  }

  that.setState({ error });
};
