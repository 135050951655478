import axios from "axios";
import language from "../../../language";
import { setStorage } from "../../../utils/storage";
import toastMessage from "../../../utils/toastMessage";
import validateForm from "./validateForm";
import externalUserInfoQuery from "../../../utils/queries/externalUserInfoQuery";
import { addDepartment } from "../../../utils/queries/departmentQuery";
import { addPosition } from "../../../utils/queries/jobPositionQuery";
import apiError from "../../../utils/apiError";

export default async (that, e) => {
  const { defaultLanguage } = that.props;
  const { password, firstName, ippisNumber } = that.state;

  try {
    e.preventDefault();
    await validateForm(that);

    if (Object.keys(that.state.error).length === 0) {
      that.setState({ isSubmitting: true });

      const profileData = await externalUserInfoQuery(that, {
        params: {
          firstName,
          ippisNumber,
          employeeId: ippisNumber,
        },
      });

      if (!profileData?.employeeId) {
        that.setState({ isSubmitting: false });

        return toastMessage(
          "error",
          language[defaultLanguage].employee_id_not_find
        );
      }

      const userInfo = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        institution: profileData.institution,
        employeeId: ippisNumber,
        ippisNumber,
        department: profileData.department,
        position: profileData.position,
        password,
        email: profileData.email,
      };

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_PSRP_BASE_API}/ippis/user/signup`,
        data: userInfo,
      };

      await axios(options);

      onLogin(that, userInfo);
    }
  } catch (error) {
    toastMessage("error", error);

    that.setState({ isSubmitting: false });

    const { error_add_account, account_exist } = language[defaultLanguage];

    apiError(error, {
      requestMethod: "POST",
      addErrorMessage: error_add_account,
      dataExistErrorMessage: account_exist,
    });
  }
};

const onLogin = async (that, userInfo) => {
  const options = {
    method: "POST",
    url: `${process.env.REACT_APP_PSRP_BASE_API}/authentication/user`,
    data: {
      email: userInfo.email,
      password: userInfo.password,
    },
  };

  axios(options)
    .then((data) => {
      that.setState({ isSubmitting: false });

      if (data.data.status === "error")
        return toastMessage("error", data.data.message);

      onSuccess(that, {
        ...userInfo,
        token: data.data.token,
        id: data.data.id,
      });
    })
    .catch((error) => {
      that.setState({ isSubmitting: false });

      toastMessage(
        "error",
        language[that.props.defaultLanguage].wrong_email_password
      );
    });
};

const onSuccess = async (that, data) => {
  if (!data.displayName) {
    data.displayName = data?.email?.split("@")[0] || "-";
  }
  await setStorage(data);

  if (data.token && data.department && data.position) {
    await addDepartment(that.props.defaultLanguage, data.department);
    await addPosition(that.props.defaultLanguage, {
      department: data.department,
      position: data.position,
    });
  }

  window.location.href = "/public-servant/my requests";
};
