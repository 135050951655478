import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { DateFilter, DepartmentFilter, ServiceFilter } from ".";
import icons from "../../constants/icons";
import language from "../../language";
import "./styles.css";
import { Button } from "../Button";
import { onResetFilters } from "../../actions/Filters";
import { Empty } from "../Empty";
import requestQuery from "../../utils/queries/requestQuery";
import { Loading } from "../Loader";
import moment from "moment";
import { TimeAgo } from "../TimeAgo";

let copyData = [];

class MainFilters extends React.Component {
  state = {
    searchText: "",
    isLoading: false,
    searchResults: [],
  };

  componentDidMount = async () => {
    this.getRequests(true);
  };

  getRequests = async (isLoading, params = {}) => {
    const { search } = params;

    try {
      this.setState({ isLoading: isLoading });

      const { data } = await requestQuery(this.props.defaultLanguage, {
        page: 0,
        limit: 50,
        search,
      });
      const result = data.map((el) => {
        return {
          _id: el._id,
          type: el.type,
          createdAt: el.createdAt,
          employeeId: el.user.employeeId,
          name: el.user.firstName + " " + el.user.lastName,
        };
      });
      copyData = result.slice(0);

      this.setState({ isLoading: false });
    } catch (error) {
      console.log(
        "Query recent requests for search has failed with error: " + error
      );
      this.setState({ isLoading: false });
    }
  };

  onChangeSearchText(e) {
    this.setState({ searchText: e.target.value }, () => {
      this.handleSearch();
    });
  }

  handleSearch() {
    const { searchText } = this.state;

    this.setState({ isLoading: true });

    let result = [];

    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchText.toLowerCase()) !==
        -1
      ) {
        result.push(el);
      }
    });

    this.setState({ searchResults: result, isLoading: false }, () => {
      //ENABLE THIS IF YOU WANT TO QUERY FROM THE SERVER
      // if (result.length === 0) {
      //   this.getRequests(true, { search: searchText });
      // }
    });
  }

  render() {
    return (
      <div className="filters-container ">
        {this.props?.filters?.includes("search") && (
          <div style={{ position: "relative", flex: 1 }}>
            <Input
              leftIcon={icons.search}
              styles={{ margin: 0, flex: 1 }}
              placeholder={
                language[this.props.defaultLanguage].main_search_placeholder
              }
              className="sm"
              // rightButtonText={
              //   this.state.searchText !== "" ? "Search" : undefined
              // }
              value={this.state.searchText}
              onChange={this.onChangeSearchText.bind(this)}
              // rightButtonPressed={this.handleSearch.bind(this)}
              // rightButtonSubmitting={this.state.isLoading}
            />
            {this.state.searchText !== "" && (
              <div className="search-results-container">
                {this.state.isLoading ? (
                  <center>
                    <Loading />
                  </center>
                ) : this.state.searchResults.length === 0 ? (
                  <Empty title="No results found" />
                ) : (
                  this.state.searchResults.map((el, i) => {
                    return (
                      <div
                        className="search-results-item"
                        onClick={() =>
                          (window.location.href = `/dashboard/requests/single-view/${el._id}`)
                        }
                      >
                        <div className="search-results-item-info">
                          <p className="text-bold">{el.name}</p>
                          <p>{el.employeeId}</p>
                        </div>
                        <div className="d-flex gap-1">
                          <p>
                            <TimeAgo
                              date={moment(el.createdAt).format("lll")}
                            />
                          </p>
                          <div className={process.env.REACT_APP_ICONS_TYPE}>
                            {icons.chevronRight}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
        )}
        {this.props?.filters?.includes("department") && <DepartmentFilter />}
        {this.props?.filters?.includes("service") && <ServiceFilter />}
        {this.props?.filters?.includes("date_range") && <DateFilter />}
        <Button
          text="Clear Filter"
          className="btn-default btn-sm"
          onPress={() => this.props.dispatch(onResetFilters())}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MainFilters);
