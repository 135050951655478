// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire-item-container {
  /* padding: 1rem 0; */
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  min-height: 60px;
  margin-bottom: 1rem;
}

.questionnaire-item-container .questionnaire-answer-count {
  margin-right: 1rem;
  background-color: var(--primary-color);
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-color);
}

.questionnaire-item-container .question-text {
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.questionnaire-card-view-actions-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.questionnaire-card-view-actions-buttons .item {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.questionnaire-card-view-actions-buttons .item.active {
  opacity: 1;
}

.questionnaire-card-view-actions-buttons .item.active span {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/SingleRequest/QuestionnaireAnswers/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".questionnaire-item-container {\r\n  /* padding: 1rem 0; */\r\n  display: flex;\r\n  /* flex-wrap: wrap; */\r\n  gap: 1rem;\r\n  min-height: 60px;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.questionnaire-item-container .questionnaire-answer-count {\r\n  margin-right: 1rem;\r\n  background-color: var(--primary-color);\r\n  width: 28px;\r\n  height: 28px;\r\n  border-radius: var(--border-radius-half);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  color: var(--light-color);\r\n}\r\n\r\n.questionnaire-item-container .question-text {\r\n  margin-bottom: 0.5rem;\r\n  color: var(--text-color);\r\n}\r\n\r\n.questionnaire-card-view-actions-buttons {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n\r\n.questionnaire-card-view-actions-buttons .item {\r\n  height: 30px;\r\n  width: 30px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n  opacity: 0.5;\r\n}\r\n\r\n.questionnaire-card-view-actions-buttons .item.active {\r\n  opacity: 1;\r\n}\r\n\r\n.questionnaire-card-view-actions-buttons .item.active span {\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
