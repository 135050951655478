import React from "react";

import { Splash } from "../../components/Splash";
import { connect } from "react-redux";
import serviceQuery from "../../utils/queries/serviceQuery";
import {
  isFetchingServices,
  fetchedServices,
  makeCopyOfService,
} from "../../actions/Services";
import { Footer } from "../../components/Footer";
class SplashScreen extends React.Component {
  state = {
    error: "",
  };

  componentDidMount = async () => {
    this.getData();
  };

  getData = async () => {
    const { defaultLanguage } = this.props;
    try {
      this.setState({ error: "" });

      this.props.dispatch(isFetchingServices(true));

      const { data } = await serviceQuery(defaultLanguage, {
        page: 1,
        limit: 100,
        pageType: "landing",
      });

      await this.props.dispatch(fetchedServices(data));
      await this.props.dispatch(makeCopyOfService(data));

      this.props.dispatch(isFetchingServices(false));

      window.location.href = "/landing";
    } catch (error) {
      this.props.dispatch(isFetchingServices(false));

      this.setState({ error: "Unable to fetch services" });
    }
  };

  render() {
    return (
      <>
        <Splash
          handleRefresh={this.getData.bind(this)}
          error={this.state.error}
        />
        <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { services, copyServices } = state.Services;
  const { defaultLanguage } = state.Language;
  return {
    services,
    defaultLanguage,
    copyServices,
  };
};

export default connect(mapStateToProps)(SplashScreen);
