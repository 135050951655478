import { connect } from "react-redux";
import userImageProfilePlaceholder from "../../assets/userPhotoPlaceholder.png";
import moment from "moment";
import "./styles.css";
import isString from "../../utils/isString";
import isArray from "../../utils/isArray";

const User = (props) => {
  return (
    <div className="user-container">
      <div className="user-img-container">
        <img src={userImageProfilePlaceholder} />
      </div>
      <div
        className="user-name-container"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            <div>
              <h3>
                {props?.firstName || ""} {props?.lastName || ""}
              </h3>
            </div>
            {props.actionStatus && (
              <div className={`badge badge-${props.actionStatus}`}>
                <span className="text-bold">{props.actionStatus}</span>
              </div>
            )}
          </div>

          {props.institution && (
            <p className="text-disabled">{props.institution}</p>
          )}
          {props.department && (
            <p className="text-disabled">
              {isArray(props.department) ? (
                props.department.length > 0 &&
                props.department.map((el) => {
                  return <span>{el.name + " "}</span>;
                })
              ) : (
                <span>{props?.department?.name || props.department}</span>
              )}{" "}
            </p>
          )}

          {props.position && (
            <p className="text-disabled">
              {isArray(props.position) ? (
                props.position.length > 0 &&
                props.position.map((el) => {
                  return <span>{el.name + " "}</span>;
                })
              ) : (
                <span>({props?.position?.name || props.position})</span>
              )}
            </p>
          )}
        </div>
        {props.date && (
          <div className="requested-date-container">
            <span>{moment(props.date).format("lll")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(User);
