import Questionnaire from "../../widgets/Questionnaire";

export default (that) => {
  if (that.state.data?.questionnaireAnswer?.length > 0)
    return (
      <>
        <div style={{ padding: "0 1rem" }}>
          <Questionnaire
            questionnaireAnswer={that.state?.data?.questionnaireAnswer || []}
          />
        </div>
        <hr />
      </>
    );
};
