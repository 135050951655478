// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-container {
  background-color: var(--primary-color);
}

.intro-container .landing-section-content .intro-text-container {
  color: var(--light-color);
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.intro-container .landing-section-content .intro-text-container h1 {
  text-transform: capitalize;
}

.intro-container .landing-section-content .intro-text-container p {
  color: var(--primary-color-10);
  max-width: 500px;
  font-size: 15px;
  opacity: 0.7;
}

.intro-container .search-container {
  margin-bottom: -20px;
}

.intro-container .landing-section-content .row {
  align-items: center;
}

@media screen and (max-width: 500px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 980px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Introduction/styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;EACV;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;EACV;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;EACV;AACF","sourcesContent":[".intro-container {\n  background-color: var(--primary-color);\n}\n\n.intro-container .landing-section-content .intro-text-container {\n  color: var(--light-color);\n  gap: 1rem;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1rem;\n}\n\n.intro-container .landing-section-content .intro-text-container h1 {\n  text-transform: capitalize;\n}\n\n.intro-container .landing-section-content .intro-text-container p {\n  color: var(--primary-color-10);\n  max-width: 500px;\n  font-size: 15px;\n  opacity: 0.7;\n}\n\n.intro-container .search-container {\n  margin-bottom: -20px;\n}\n\n.intro-container .landing-section-content .row {\n  align-items: center;\n}\n\n@media screen and (max-width: 500px) {\n  .intro-container .search-container {\n    position: absolute;\n    bottom: -5px;\n    left: 0;\n    right: 0;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .intro-container .search-container {\n    position: absolute;\n    bottom: -5px;\n    left: 0;\n    right: 0;\n  }\n}\n\n@media screen and (max-width: 980px) {\n  .intro-container .search-container {\n    position: absolute;\n    bottom: -5px;\n    left: 0;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
