import React from "react";
import { HomeAnalyticsLayout } from "../../Layout";
import { MainFilters } from "../../components/Filters";
import {
  Analytics,
  Activities,
  CountContainer,
  RequestsContainer,
} from "../../components/HomeAnalytics";

class HomeScreen extends React.Component {
  render() {
    return (
      <HomeAnalyticsLayout>
        <div className="row">
          <div className="col-md-9">
            <MainFilters filters={["search", "date_range"]} />
            <Analytics />
            <RequestsContainer noHeight />
          </div>
          <div className="col-md-3" style={{ marginBottom: "1rem" }}>
            <Activities />
            <CountContainer />
          </div>
        </div>
      </HomeAnalyticsLayout>
    );
  }
}

export default HomeScreen;
