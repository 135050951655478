import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { DashboardHeader } from "../../../components/Header";
import { ServiceCategories, Services } from "../../../components/AdminServices";
import { Tabs } from "../../../components/Tabs";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";

class ServiceScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  returnTabsOptions = () => {
    const { user } = this.state;
    const { accessRole } = user;

    const canReadServiceCategories = defineRole({
      roles: accessRole,
      menu: "Service Categories",
      operation: "read",
    });

    const canReadServices = defineRole({
      roles: accessRole,
      menu: "Services",
      operation: "read",
    });

    let tabsOptions = [];

    if (canReadServices) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].services,
        data: <Services routeCategory={this.props.category} />,
      });
    }

    if (canReadServiceCategories) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].service_categories,
        data: <ServiceCategories routeCategory={this.props.category} />,
      });
    }

    return tabsOptions;
  };

  render() {
    const { services_title, services_description } =
      language[this.props.defaultLanguage];

    return (
      <>
        <DashboardHeader
          title={
            this.props.category
              ? this.props.category + " " + services_title
              : services_title
          }
          description={services_description}
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs options={this.returnTabsOptions()} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ServiceScreen);
