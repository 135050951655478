import { connect } from "react-redux";
import "./styles.css";

const Questionnaire = (props) => {
  return (
    <div className="questionnaire-container">
      {props?.questionnaireAnswer?.map((el, i) => {
        if (el.answerType !== "file") {
          return (
            <div className="questionnaire-item-container" key={i}>
              <div className="questionnaire-answer-count">Q</div>
              <div>
                <p className="question-text text-bold">{el.questionText}</p>
                <p className="answer-text">{el.answer}</p>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaire);
