import { Provinces } from "rwanda";

export default () => {
  return Provinces().map((el) => {
    return {
      label: el,
      value: el,
    };
  });
};
