import React from "react";
import { connect } from "react-redux";
import { DashboardHeader } from "../../Header";
import requestQuery from "../../../utils/queries/requestQuery";
import { onRemoveNotification } from "../../../actions/Notification";
import { Loading } from "../../Loader";
import User from "../widgets/User";
import { getStorage } from "../../../utils/storage";
import checkUserRole from "./utils/checkUserRole";
import renderQuestionnaire from "./widget/renderQuestionnaire";
import renderAttachment from "./widget/renderAttachment";
import renderComment from "./widget/renderComment";
import renderSubApprovals from "./widget/renderSubApprovals";
import renderApprovals from "./widget/renderApprovals";
import ApproverApprove from "./widget/ApproverApprove";
import ApproverReject from "./widget/ApproverReject";
import SubApproverApprove from "./widget/SubApproverApprove";
import SubApproverReject from "./widget/SubApproverReject";
import { Modal } from "../../Modal";

class SingleRequest extends React.Component {
  state = {
    isLoading: false,
    page: 1,
    limit: 1,
    data: {},
    selected_data: [],
    attachments: [],
    error: {},
    comment: "",
    requestAdminInfo: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, requestId } = this.props;

      this.setState({ isLoading });

      //read notification: check if notification exist in notification storage redux, then remove it, this will count down notifications
      this.props.dispatch(onRemoveNotification(requestId));

      const { data } = await requestQuery(defaultLanguage, {
        page,
        limit,
        id: requestId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      let attachments = [];

      for (let el of res.questionnaireAnswer || []) {
        if (el && el.answerType && el.answerType === "file") {
          attachments.push(el);
        }
      }

      checkUserRole(this, {
        approvals: res.approvals,
        subApprovals: res.subApprovals,
      });

      this.setState({
        data: res,
        isLoading: false,
        attachments,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  setUserName(account) {
    let name = "";
    if (account && account.username) {
      name = account.username;
    }

    return name;
  }

  setRequesterName(account) {
    let name = "";
    if (account && (account.firstName || account.lastName)) {
      name = account.firstName + " " + account.lastName;
    }

    return name;
  }

  setDepartmentName(account) {
    let department = "";
    if (account && account.department && account.department.length > 0) {
      department = account.department[0].name;
    }

    return department;
  }

  setPositionName(account) {
    let position = "";
    if (account && account.position && account.position.length > 0) {
      position = account.position[0].name;
    }

    return position;
  }

  setInstitution(userInfo) {
    let institution = "";

    if (userInfo && userInfo.institution) {
      institution = userInfo.institution;
    }

    return institution;
  }

  setUserTitle(userInfo) {
    let title = "";

    if (userInfo && userInfo.department && userInfo.position) {
      title = userInfo.department + " - " + userInfo.position;
    }

    return title;
  }

  getMessage(userInfo) {
    let message = "";
    if (userInfo.actionComment && userInfo.actionComment) {
      message = userInfo.actionComment;
    }

    return message;
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loading className="loader-fullscreen" />
        ) : (
          <div className="row">
            <div className="col-md-8 full-height">
              <DashboardHeader
                isLoading={this.state.isLoading}
                title={this.state?.data?.description}
                requestNumber={this.state?.data?._id}
                maxResponseTime={this.state?.data?.service?.maxResponseTime}
                status={this.state?.data?.status}
              />
              <User
                name={this.setRequesterName(this.state?.data?.user)}
                date={this.state.data.createdAt}
                userTitle={this.setUserTitle(this.state?.data?.user)}
                institution={this.state.data?.user?.institution}
              />
              <hr />
              {renderQuestionnaire(this)}
              {this.state?.attachments?.length > 0 && renderAttachment(this)}
              {this.state?.data?.status === "pending" && renderComment(this)}
            </div>
            <div className="col-md-4 full-height">
              <div className="card">
                <div className="card-body">
                  {renderSubApprovals(this)}
                  {renderApprovals(this)}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* approvers modals */}
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showApproverApproveModal"
          )}
          show={this.state.showApproverApproveModal}
        >
          <ApproverApprove
            request={this.state.data}
            requestId={this.state.data._id}
            approval={this.state.requestAdminInfo}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showApproverApproveModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.comment}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showApproverRejectModal"
          )}
          show={this.state.showApproverRejectModal}
        >
          <ApproverReject
            request={this.state.data}
            requestId={this.state?.data?._id}
            approval={this.state.requestAdminInfo}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showApproverRejectModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.comment}
          />
        </Modal>

        {/* sub approvals modal */}
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showSubApproverApproveModal"
          )}
          show={this.state.showSubApproverApproveModal}
        >
          <SubApproverApprove
            request={this.state.data}
            requestId={this.state.data._id}
            approval={this.state.requestAdminInfo}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSubApproverApproveModal"
            )}
            getData={this.props.getData}
            comment={this.state.comment}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showSubApproverRejectModal"
          )}
          show={this.state.showSubApproverRejectModal}
        >
          <SubApproverReject
            request={this.state.data}
            requestId={this.state?.data?._id}
            approval={this.state.requestAdminInfo}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSubApproverRejectModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.comment}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleRequest);
