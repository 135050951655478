import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import language from "../../language";

class SelectC extends React.Component {
  render() {
    return (
      <div
        className={`input-container ${this.props.className}
        ${this.props.disabled && "disabled-input"} ${
          this.props.error && "errored-input"
        } ${this.props.success && "success-input"}`}
        style={this.props.styles}
      >
        {this.props.label && (
          <label className="form-label">
            {this.props.label}{" "}
            {this.props.required && <span className="error-text">*</span>}
          </label>
        )}
        <div className={`input-content ${this.props.inputClassName}`}>
          {this.props.leftIcon && (
            <div className="left-container">
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.props.leftIcon}
              </span>
            </div>
          )}
          <div className="input-input">
            <Select
              options={this.props.options}
              value={this.props.value}
              onChange={this.props.onChange}
              placeholder={
                <div>
                  {this.props.placeholder ||
                    language[this.props.defaultLanguage].select}
                </div>
              }
              isDisabled={this.props.disabled}
              className={this.props.className}
              isLoading={this.props.isLoading}
              isMulti={this.props.isMulti}
              menuPosition="fixed"
            />
          </div>
          {this.props.rightText && this.props.rightText !== "" && (
            <div className="right-container">
              <Link className="text-bold" to="#">
                {this.props.rightText}
              </Link>
            </div>
          )}
        </div>
        {this.props.helperText && (
          <div className="helper-section">
            <p className="helper-text text-disabled">{this.props.helperText}</p>
          </div>
        )}

        {this.props.error && (
          <div className="helper-section">
            <p className="error-text">{this.props.error}</p>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SelectC);
