import axios from "axios";
import { getStorage } from "../storage";
import toastMessage from "../toastMessage";
import language from "../../language";

const fetchCounts = async (lng = "english", params) => {
  let { services = [] } = params;

  const servicesIds = services.map((service) => service.value);

  delete params.services;

  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_PSRP_ROOT_API}/analytics/request/count`,
      params: {
        ...params,
        ["body.service._id"]: servicesIds,
        institution: user.institution,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_counts);
  }
};

const fetchTrendsAnalytics = async (lng = "english", params) => {
  let { services = [] } = params;

  const servicesIds = services.map((service) => service.value);

  delete params.services;

  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_PSRP_ROOT_API}/analytics/request/trend`,
      params: {
        ...params,
        ["body.service._id"]: servicesIds,
        institution: user.institution,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_analytics);
  }
};

const fetchLastAnalytics = async (lng = "english", params) => {
  let { services = [] } = params;

  const servicesIds = services.map((service) => service.value);

  delete params.services;

  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_PSRP_ROOT_API}/analytics/request/last`,
      params: {
        ...params,
        ["body.service._id"]: servicesIds,
        institution: user.institution,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_analytics);
  }
};

const fetchRankAnalytics = async (lng = "english", params) => {
  let { services = [] } = params;

  const servicesIds = services.map((service) => service.value);

  delete params.services;

  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_PSRP_ROOT_API}/analytics/request/rank`,
      params: {
        ...params,
        ["body.service._id"]: servicesIds,
        institution: user.institution,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_analytics);
  }
};

export {
  fetchCounts,
  fetchTrendsAnalytics,
  fetchLastAnalytics,
  fetchRankAnalytics,
};
