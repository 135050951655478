import React from "react";
import { UserGuideApplicant } from "../../components/UserGuide";

class ApplicantUserGuideScreen extends React.Component {
  render() {
    return <UserGuideApplicant />;
  }
}

export default ApplicantUserGuideScreen;
