// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-switch-header {
  display: flex;
  padding: 0.5rem 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/AccountSwitch/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".account-switch-header {\r\n  display: flex;\r\n  padding: 0.5rem 0.8rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
