import { connect } from "react-redux";
import { Navbar } from "../components/Navbar";
import "./layout.css";

const UserGuideLayout = (props) => {
  return (
    <>
      <Navbar />
      <div className="dashboard-layout" style={{ overflowY: "hidden" }}>
        <div className="container">{props.children}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(UserGuideLayout);
