import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import handleSearch from "../../utils/handleSearch";
import { Table } from "../Table";
import language from "../../language";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";

let copyData = [];

class Comments extends React.Component {
  state = {
    data: [],
    csvData: [],
  };

  componentDidMount() {
    const data = [
      ...(this.props.subApprovalsComments || []),
      ...(this.props.approvalsComments || []),
    ];

    let comments = [];

    for (let el of data) {
      if (
        (el.action === "Rejection" && el.actionComment !== "") ||
        el.actionComment !== undefined
      ) {
        comments.push(el);
      }
    }
    this.setState({ data: comments });

    copyData = comments.slice(0);
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].message,
        key: "actionComment",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "actionAt",
        type: "timeAgo",
      },
    ];

    return headers;
  }

  downloadExcel() {
    try {
      this.setState({
        csvData: this.state.data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      //log error
    }
  }

  downloadPDF = async () => {
    const headers = this.returnTableHeaders();

    exportPDF("Comments", headers, this.state.data);
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={"Comments" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Comments);
