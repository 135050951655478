import React from "react";
import { CardCount } from "../../components/CardCount";
import icons from "../../constants/icons";
import { fetchCounts } from "../../utils/queries/analyticsQuery";
import { connect } from "react-redux";
import { MainFilters } from "../../components/Filters";
import moment from "moment";
import language from "../../language";
import Analytics from "../../components/Analytics/Analytics";

const sevenDaysRange = {
  endCreatedAt: moment().startOf("day").subtract(7, "days").valueOf(),
  startCreatedAt: moment(moment().subtract(7, "days").valueOf())
    .subtract(7, "d")
    .valueOf(),
};

const fifteenDaysRange = {
  startCreatedAt: moment(moment().subtract(15, "days").valueOf())
    .subtract(15, "days")
    .valueOf(),
  endCreatedAt: moment().subtract(15, "days").valueOf(),
};

const thirtyDaysRange = {
  endCreatedAt: moment().subtract(30, "days").valueOf(),
  startCreatedAt: moment(moment().subtract(30, "days").valueOf())
    .subtract(30, "d")
    .valueOf(),
};
class DashboardScreen extends React.Component {
  state = {
    totalCounts: 0,
    svDaysCount: 0,
    ftDaysCount: 0,
    tnDaysCount: 0,
  };

  componentDidMount() {
    //total counts
    this.getCounts(true, {
      fieldName: "totalCounts",
    });

    // 7days
    this.getCounts(true, {
      fieldName: "svDaysCount",
      ...sevenDaysRange,
      status: "pending",
    });

    // 15days
    this.getCounts(true, {
      fieldName: "ftDaysCount",
      ...fifteenDaysRange,
      status: "pending",
    });

    // above 30days
    this.getCounts(true, {
      fieldName: "tnDaysCount",
      ...thirtyDaysRange,
      status: "pending",
    });
  }

  getCounts = async (isLoading, params) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchCounts(defaultLanguage, params);

      this.setState({
        data: data,
        [params.fieldName]: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div>
        <MainFilters
          filters={["search", "institution", "service", "date_range"]}
        />
        <div className="row">
          <div className="col-md-3">
            <CardCount
              isLoading={this.state.isLoading}
              count={this.state.totalCounts}
              title={language[this.props.defaultLanguage].total_counts}
              color="#078ECE"
              icon={icons.article}
              onPress={() =>
                (window.location.href = "/dashboard/requests/pending")
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.svDaysCount}
              title={language[this.props.defaultLanguage].total_pending_7}
              color="#FF6D00"
              icon={icons.check}
              isLoading={this.state.isLoading}
              onPress={() =>
                (window.location.href = `/dashboard/requests/pending?startCreatedAt=${sevenDaysRange.startCreatedAt}&endCreatedAt=${sevenDaysRange.endCreatedAt}`)
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.ftDaysCount}
              title={language[this.props.defaultLanguage].total_pending_15}
              color="#008000"
              icon={icons.waiting}
              isLoading={this.state.isLoading}
              onPress={() =>
                (window.location.href = `/dashboard/requests/pending?startCreatedAt=${fifteenDaysRange.startCreatedAt}&endCreatedAt=${fifteenDaysRange.endCreatedAt}`)
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.tnDaysCount}
              title={language[this.props.defaultLanguage].total_pending_30}
              color="#D10000"
              icon={icons.calendar}
              isLoading={this.state.isLoading}
              onPress={() =>
                (window.location.href = `/dashboard/requests/pending?startCreatedAt=${thirtyDaysRange.startCreatedAt}&endCreatedAt=${thirtyDaysRange.endCreatedAt}`)
              }
            />
          </div>
        </div>
        <Analytics />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DashboardScreen);
