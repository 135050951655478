import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./style.css";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";
import ReactDOM from "react-dom";

const ControlledTabs = (props) => {
  const [key, setKey] = useState(
    props.options.length > 0 ? props.options[0].title.toLowerCase() : undefined
  );

  useEffect(() => {
    if (props.activeTab) {
      setKey(props.activeTab);
    }
  });

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={`tab gap-3 flex-nowrap ${props.className}`}
        style={props.styles}
      >
        {props.options.map((item, i) => {
          return (
            <Tab
              key={i}
              eventKey={item.title.toLowerCase()}
              title={
                <React.Fragment>
                  <div className="tab-title-container">
                    {item.title}
                    {item.count && item.count > 0 ? (
                      <div>
                        <NotificationBadgeCount count={item.count} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              }
              mountOnEnter
              unmountOnExit={false}
            >
              {item.data}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

{
  /* <React.Fragment>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                    className="active"
                  >
                    <div className="tab-name">{item.title}</div>
                    <NotificationBadgeCount count={2} styles={{}} />
                  </div>
                </React.Fragment> */
}

export default ControlledTabs;
