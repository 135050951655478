import language from "../language";

const data = Object.keys(language).map((el) => {
  return {
    label: el,
    value: el,
  };
});

export default data;
