import { Button } from "../Button";
import "./styles.css";

const Empty = (props) => {
  return (
    <div className="empty-activity" style={props.styles}>
      {props.icon && (
        <div className="icon-container">
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
      )}
      <center className="empty-activity-description-container">
        <p className="text-bold">{props.title}</p>
        {props.description && <p className="mb-1">{props.description}</p>}
        {props.subDescription && <p>{props.subDescription}</p>}
      </center>
      {props.actionButton && (
        <div style={{ marginTop: 20 }}>
          <Button
            text={props.actionButton}
            onPress={props.handleActionButton}
          />
        </div>
      )}
    </div>
  );
};

export default Empty;
