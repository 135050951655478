import React from "react";
import { Tabs } from "../../../components/Tabs";
import { connect } from "react-redux";
import language from "../../../language";
import { DashboardHeader } from "../../../components/Header";
import { Departments } from "../../../components/Departments";
import { JobPositions } from "../../../components/JobPositions";
import { AccessRoles } from "../../../components/AccessRole";
import { defineRole } from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";

class DepartmentScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  returnTabsOptions = () => {
    const { user } = this.state;
    const { accessRole } = user;

    const canReadDepartments = defineRole({
      roles: accessRole,
      menu: "Departments",
      operation: "read",
    });

    const canReadPositions = defineRole({
      roles: accessRole,
      menu: "Job Positions",
      operation: "read",
    });

    const canReadAccess = defineRole({
      roles: accessRole,
      menu: "Access Roles",
      operation: "read",
    });

    let tabsOptions = [];

    if (canReadDepartments) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].departments,
        data: <Departments />,
      });
    }

    if (canReadPositions) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].job_positions,
        data: <JobPositions />,
      });
    }

    if (canReadAccess) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].access_roles,
        data: <AccessRoles />,
      });
    }

    return tabsOptions;
  };

  render() {
    const { departments_title, departments_description } =
      language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader
          title={departments_title}
          description={departments_description}
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs options={this.returnTabsOptions()} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DepartmentScreen);
