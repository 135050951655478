// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  height: 1px;
  background-color: var(--text-color-10);
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/hr.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sCAAsC;EACtC,YAAY;AACd","sourcesContent":["hr {\n  height: 1px;\n  background-color: var(--text-color-10);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
