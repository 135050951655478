import { Route, Routes, Outlet } from "react-router-dom";
import SignupScreen from "../screens/PublicServant/SignupScreen";
import PublicServantScreen from "../screens/PublicServant";
import LoginScreen from "../screens/PublicServant/Authentication/LoginScreen";

const routes = [
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/signup",
    element: <SignupScreen />,
  },
  {
    path: "/signup/:serviceId",
    element: <SignupScreen />,
  },
  {
    path: "/public-servant/*",
    element: <PublicServantScreen />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
