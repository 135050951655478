import React from "react";

import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { setStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import loginSlidesContent from "../../constants/loginSlidesContent";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;
class Login extends React.Component {
  state = {
    username: "",
    isSubmitting: false,
    error: {},
    password: "",
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    let { error, username, password } = this.state;
    if (username === "") {
      error.username = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(username)) {
      error.username =
        language[this.props.defaultLanguage].invalid_email_required;
    }

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { username, password } = this.state;
      const email = username;

      const options = {
        method: "POST",
        url: `${API_URL}/authentication/admin`,
        data: {
          email,
          password,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          let userInfo = {
            ...data?.data,
            accessRole: data?.data?.accessRole,
            token: data?.data?.token,
            email: username,
            role: data.data.role,
            institution: data?.data?.institution,
            accountType: data.data.accountType,
          };

          if (data.data?.id) {
            userInfo.id = data.data?.id;
          }

          if (userInfo.ippisUserData) {
            userInfo["firstName"] = userInfo.ippisUserData.firstName;
            userInfo["lastName"] = userInfo.ippisUserData.lastName;

            userInfo.displayName =
              userInfo.ippisUserData.firstName +
              " " +
              userInfo.ippisUserData.lastName;
          } else if (!data.data.displayName) {
            userInfo.displayName = username.split("@")[0];
          }

          this.onSuccess(userInfo);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].wrong_email_password
          );
        });
    }
  };

  onSuccess = async (data) => {
    const { redirectURL } = this.props;

    await setStorage({ ...data });

    if (redirectURL && redirectURL !== "") {
      if (redirectURL === "refresh") {
        return window.location.reload();
      }
      return (window.location.href = redirectURL);
    }

    window.location.href = "/dashboard/home";
  };

  render() {
    return (
      <div className="row">
        {this.props.showSideDescriptionSlides && (
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides data={loginSlidesContent} viewSize={1} />
            </div>
          </div>
        )}
        <div className={`col-md-${this.props.formContainerSize || 5}`}>
          <div className="auth-form" style={this.props.styles}>
            {this.props.showFormTitle && (
              <div className="title">
                <h3 className="text-primary">
                  {language[this.props.defaultLanguage].admin_login}
                </h3>
              </div>
            )}
            <form>
              <Input
                label={language[this.props.defaultLanguage].email_address}
                placeholder={language[this.props.defaultLanguage].enter_email}
                required
                leftIcon={icons.user}
                value={this.state.username}
                error={this.state.error.username}
                onChange={(e) => this.onChangeText("username", e)}
              />
              <Input
                label={language[this.props.defaultLanguage].password}
                placeholder={
                  language[this.props.defaultLanguage].enter_password
                }
                required
                leftIcon={icons.password}
                value={this.state.password}
                error={this.state.error.password}
                onChange={(e) => this.onChangeText("password", e)}
                type="password"
              />
              <Button
                text={language[this.props.defaultLanguage].login_text}
                className="w-100"
                isSubmitting={this.state.isSubmitting}
                onPress={this.onSubmit.bind(this)}
              />
              <br />
              <div className="footer">
                <span>
                  <Link to="/forgot-password">
                    {language[this.props.defaultLanguage].forgot_password}
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Login);
