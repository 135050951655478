import React from "react";
import { SingleService } from "../../../components/AdminServices";
import { withRouter } from "../../../utils/withRouter";

class ViewSingleServiceScreen extends React.Component {
  render() {
    return <SingleService serviceId={this.props?.routeParams?.serviceId} />;
  }
}

export default withRouter(ViewSingleServiceScreen);
