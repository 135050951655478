import React from "react";
import icons from "../../constants/icons";
import "./styles.css";
import UserGuideAttachments from "./UserGuideAttachments";

class GuideContent extends React.Component {
  render() {
    return (
      <div className="card mb-4" id={this.props?.title?.replaceAll(" ", "_")}>
        <div className="card-header">
          <h2 className="text-bold">{this.props.title}</h2>
        </div>
        <div className="card-body">
          <div style={{ marginBottom: "1.5rem" }}>
            <p>{this.props.description}</p>
          </div>
          <div>
            {this.props.listActions?.length > 0 && (
              <div className="list-group">
                {this.props.listActions.map((action) => {
                  return (
                    <div className="list-group-item d-flex align-items-center gap-1">
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {icons.chevronRight}
                      </span>
                      <div className="flex-1">
                        <p className="text-bold">{action.title}</p>
                        {action.description && <p>{action.description}</p>}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {this.props.imageUrl && <UserGuideAttachments {...this.props} />}
        </div>
      </div>
    );
  }
}

export default GuideContent;
