export default (that, { approvals, subApprovals }) => {
  for (let admin of approvals || []) {
    if ((admin?.account?._id || admin.account) === that.state.user.id) {
      that.setState({
        requestAdminInfo: { ...admin, ftAdminType: "approval" },
      });
    }
  }

  for (let admin of subApprovals || []) {
    if ((admin?.account?._id || admin.account) === that.state.user.id) {
      that.setState({
        requestAdminInfo: { ...admin, ftAdminType: "subApproval" },
      });
    }
  }
};
