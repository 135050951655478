import { connect } from "react-redux";
import userImageProfilePlaceholder from "../../../assets/userPhotoPlaceholder.png";
import moment from "moment";
import "./styles.css";

const User = (props) => {
  return (
    <div className="user-container">
      <div className="user-img-container">
        <img src={userImageProfilePlaceholder} />
      </div>
      <div className="user-name-container">
        <div style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
          <div>
            <h3>{props.name}</h3>
          </div>
          {props.actionStatus && (
            <div className={`badge badge-${props.actionStatus}`}>
              <span className="text-bold">{props.actionStatus}</span>
            </div>
          )}
        </div>
        <p className="text-disabled">{props.userTitle}</p>
        <p className="text-disabled">{props.institution}</p>
      </div>
      {props.date && (
        <div className="requested-date-container">
          <span>{moment(props.date).format("lll")}</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(User);
