import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";

const API_URL = process.env.REACT_APP_PSRP_ROOT_API;

const fetchData = async (
  lng = "english",
  { page, limit, status, startCreatedAt, endCreatedAt }
) => {
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Alerts",
      operation: "read",
    });

    if (!canRead) return;

    const options = {
      method: "GET",
      url: `${API_URL}/event/alert`,
      params: {
        page: page,
        limit: limit,
        institution: user.institution,
        ["body.status"]: status,
        startCreatedAt,
        endCreatedAt,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;
