import React from "react";
import { connect } from "react-redux";
import { ListItem } from "../List";
import { withRouter } from "../../utils/withRouter";

const Sidebar = (props) => {
  return (
    <div className="user-guide-table-content-container">
      <div className="user-guide-table-content">
        {props.data &&
          props.data[props.defaultLanguage || "english"]?.map(
            (value, index) => {
              return (
                <ListItem
                  onPress={() =>
                    (window.location.href = `#${value.title.replaceAll(
                      " ",
                      "_"
                    )}`)
                  }
                  key={index}
                  name={value?.title}
                />
              );
            }
          )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
