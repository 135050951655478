// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sigCanvas {
  flex: 1 1;
  width: 100%;
  overflow-x: auto;
}

.sigButtons {
  min-width: 80px;
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
}

.sig-img-container img {
  aspect-ratio: 16/4;
  width: 100%;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/AdminSettings/Signatures/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".sigCanvas {\n  flex: 1;\n  width: 100%;\n  overflow-x: auto;\n}\n\n.sigButtons {\n  min-width: 80px;\n  background-color: var(--light-color);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 1rem 1.5rem;\n}\n\n.sig-img-container img {\n  aspect-ratio: 16/4;\n  width: 100%;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
