import { Doughnut } from "react-chartjs-2";
import "./styles.css";
import { CardHeader } from "../Header";
import { Loading } from "../Loader";

const options = {
  legend: {
    position: "right",
    display: true,
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  maintainAspectRatio: true,
  responsive: true,
};

const DoughnutChart = (props) => {
  return (
    <div className={`chart-container ${props.className}`}>
      {props.title && <CardHeader title={props.title} />}
      {props.isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 200,
          }}
        >
          <Loading />
        </div>
      ) : props?.data?.labels?.length > 0 ? (
        <div className="doughnut-chart">
          <div className="chart">
            <Doughnut data={props.data} options={options} />
          </div>
        </div>
      ) : (
        <div className="empty-chart">
          <span>No Data</span>
        </div>
      )}
    </div>
  );
};

export default DoughnutChart;
