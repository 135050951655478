import toastMessage from "./toastMessage";

export default (
  apiErrorResponse,
  {
    requestMethod,
    addErrorMessage,
    updateErrorMessage,
    deleteErrorMessage,
    dataExistErrorMessage,
  }
) => {
  try {
    const { response = {} } = apiErrorResponse;
    const { data = {} } = response;
    const { error = "" } = data;

    if (requestMethod === "PUT") {
      return toastMessage("error", updateErrorMessage);
    }

    if (requestMethod === "DELETE") {
      return toastMessage("error", deleteErrorMessage);
    }

    if (error.includes("E11000")) {
      return toastMessage("error", dataExistErrorMessage);
    }

    toastMessage("error", addErrorMessage);
  } catch (error) {
    console.log(error);
  }
};
