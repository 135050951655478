import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import loginVector from "../../assets/Login vector.png";
import language from "../../language";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";
class VerifyAccount extends React.Component {
  state = {
    username: "",
    isResending: true,
    otpCode: "",
  };

  getTranslations() {
    const { defaultLanguage } = this.props;

    return language[defaultLanguage];
  }

  componentDidMount() {
    const { searchParams } = this.props;
    const username = searchParams.get("p_e");

    this.setUsername(username);
  }

  setUsername(username) {
    this.setState({ username: username });
  }

  validateForm() {
    let { otpCode, error, username } = this.state;

    const { missing_phone_email, otp_code_required, otp_code_invalid } =
      this.getTranslations();

    if (!username || username === "") {
      toastMessage("error", missing_phone_email);
    }

    if (otpCode === "") {
      error.otpCode = otp_code_required;
    }

    if (typeof otpCode !== "number") {
      error.otpCode = otp_code_invalid;
    }

    this.setState({
      error,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const { unable_to_verify_code } = this.getTranslations();

    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length === 0) {
        this.setState({
          isSubmitting: true,
        });

        window.location.href = "/dashboard/home";

        const options = {
          method: "POST",
          url: `API endpoint`,
          data: {
            //data
          },
          headers: {
            authorization: `Bearer ---GET TOKEN`,
          },
        };

        await axios(options);
      }
    } catch (error) {
      console.log(
        "===================UNABLE TO VERIFY CODE FOR AUTH================="
      );
      console.log(error?.response?.data);
      console.log("=================END===================");
      toastMessage("error", unable_to_verify_code);
    }
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title: "Lorem ipsum dolor sit amet consectetur.",
                    description:
                      "Lorem ipsum dolor sit amet consectetur. Hendrerit sit ultricies pulvinar ac eget. Ut vel elementum habitant praesent.",
                    image: loginVector,
                  },
                  {
                    title: "Lorem ipsum dolor sit amet consectetur.",
                    description:
                      "Lorem ipsum dolor sit amet consectetur. Hendrerit sit ultricies pulvinar ac eget. Ut vel elementum habitant praesent.",
                    image: loginVector,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {language[this.props.defaultLanguage].verify_account}
                </h3>
                <p>
                  {language[this.props.defaultLanguage].enter_otp_received_on}{" "}
                  {this.state.username}
                </p>
              </div>
              <form>
                <Input
                  label={
                    language[this.props.defaultLanguage].phone_number_or_email
                  }
                  placeholder={
                    language[this.props.defaultLanguage]
                      .enter_phone_number_or_email
                  }
                  required
                  leftIcon="person"
                  disabled
                  value={this.state.username}
                />
                <Input
                  label={
                    language[this.props.defaultLanguage].otp_code_six_digits
                  }
                  placeholder="******"
                  required
                  leftIcon="key"
                  rightText={language[this.props.defaultLanguage].resend}
                  isResending={this.state.isResending}
                  type="number"
                />
                <Button
                  text={language[this.props.defaultLanguage].login_text}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <span>
                    {language[this.props.defaultLanguage].dont_have_account}{" "}
                    <Link to="/signup">
                      {language[this.props.defaultLanguage].register}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(VerifyAccount);
