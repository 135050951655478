import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import requestQuery from "../../utils/queries/requestQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";

let copyData = [];

class Requests extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.getData(true);
    }

    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnDataFilters() {
    const { page, limit } = this.state;
    const { filters = {} } = this.props;
    const { services, startDate, endDate } = filters;
    let { type, status, startCreatedAt, endCreatedAt } = this.props;

    if (!startCreatedAt) {
      startCreatedAt = new Date(startDate).getTime();
    }

    if (!endCreatedAt) {
      endCreatedAt = new Date(endDate).getTime();
    }

    return {
      page,
      limit,
      status,
      requestType: type,
      startCreatedAt,
      endCreatedAt,
      services,
    };
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        this.returnDataFilters()
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].request_number,
        key: "_id",
        type: "link",
        onPress: (item) =>
          (window.location.href =
            "/dashboard/requests/single-view/" + item._id),
      },
      {
        title: language[this.props.defaultLanguage].requester,
        key: "user.firstName",
        // type: "link",
        onPress: (item) =>
          (window.location.href =
            "/dashboard/requests/single-view/" + item._id),
      },
      {
        title: language[this.props.defaultLanguage].from_institution,
        key: "fromInstitution",
      },
      {
        title: language[this.props.defaultLanguage].service,
        key: "service.name",
        // type: "link",
        onPress: (item) =>
          (window.location.href =
            "/dashboard/requests/single-view/" + item._id),
      },
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },

      {
        title:
          language[this.props.defaultLanguage].avg_response_time_abbr +
          "(Days)",
        key: "service.maxResponseTime",
      },
      {
        title: language[this.props.defaultLanguage].date_requested,
        key: "createdAt",
        type: "timeAgo",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await requestQuery(
        defaultLanguage,
        this.returnDataFilters()
      );

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await requestQuery(
      defaultLanguage,
      this.returnDataFilters()
    );

    this.setState({
      isLoading: false,
    });

    exportPDF("Requests", headers, data);
  };

  returnFilters() {
    const { availableFilters = [], exportEnabled } = this.props;

    let listFilters = [];
    if (availableFilters.includes("institutions")) {
      listFilters.push({
        title: language[this.props.defaultLanguage].filter__by_institution,
        button_type: "dropdown",
        icon: icons.institution,
        filterName: "institution",
      });
    }

    if (availableFilters.includes("services")) {
      listFilters.push({
        title: language[this.props.defaultLanguage].filter_by_service,
        button_type: "dropdown",
        icon: icons.service,
        filterName: "service",
        filterType: this.props.type,
      });
    }

    if (availableFilters.includes("date_range")) {
      listFilters.push({
        title: language[this.props.defaultLanguage].date_range,
        button_type: "dropdown",
        icon: icons.service,
        filterName: "date_range",
      });
    }

    if (exportEnabled) {
      listFilters.push({
        type: "export",
        title: language[this.props.defaultLanguage].export_,
        button_type: "dropdown",
        icon: "download",
        options: [
          {
            name: "PDF",
            onPress: this.downloadPDF.bind(this),
          },
          {
            name: "CSV",
            onPress: this.downloadExcel.bind(this),
          },
        ],
      });
    }

    return listFilters;
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={this.returnFilters()}
          noHeight={this.props.noHeight}
        />
        <CSVLink
          ref="csvDownload"
          filename={"Requests" + new Date()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Requests);
