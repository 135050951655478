import { DEFAULT_LANGUAGE } from "../actions/Language";

const initialState = {
  defaultLanguage: "english",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_LANGUAGE:
      return {
        ...state,
        defaultLanguage: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
