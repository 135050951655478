import React from "react";
import { connect } from "react-redux";
import { Loading } from "../Loader";
import { fetchRankAnalytics } from "../../utils/queries/analyticsQuery";
import { Bar } from "react-chartjs-2";
import "./styles.css";
import { Empty } from "../Empty";
import language from "../../language";
import moment from "moment";
import { CardHeader } from "../Header";
import icons from "../../constants/icons";
import { Modal } from "../Modal";
import TableView from "./TableView";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class PerCategory extends React.Component {
  state = {
    isLoading: false,
    data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, filters } = this.props;
      const { startDate, endDate, services } = filters;

      let labels = [],
        chartData = [];

      this.setState({ isLoading });

      const data = await fetchRankAnalytics(defaultLanguage, {
        order: "category",
        startCreatedAt: moment(startDate).unix() * 1000,
        endCreatedAt: moment(endDate).endOf("day").unix() * 1000,
        services,
        status: "pending",
      });

      for (let i = 0; i < data.length; i++) {
        labels.push(data[i]._id);
        chartData.push(data[i].count);
      }

      this.setState({
        isLoading: false,
        tableData: data,
        data: {
          labels,
          datasets: [
            {
              label:
                language[this.props.defaultLanguage]
                  .total_requests_per_category,
              data: chartData,
              borderRadius: 15,
              backgroundColor: "rgba(7, 142, 206, 0.9)",
              borderColor: "rgba(7, 142, 206, 1)",
              barPercentage: 0.2,
            },
          ],
        },
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOpenModal = (modal) => {
    this.setState({ [modal]: true });
  };

  handleCloseModal = (modal) => {
    this.setState({ [modal]: false });
  };

  render() {
    return (
      <>
        <div className="chart-container mb-3 mt-3">
          <div className="card">
            <div className="card-header">
              <div style={{ flex: 1 }}>
                <CardHeader
                  title={
                    language[this.props.defaultLanguage].requests_per_category
                  }
                />
              </div>
              <div class="card-action-btn">
                <div
                  className="icon-container"
                  onClick={() => this.handleOpenModal("showTableModal")}
                  title="Table View"
                >
                  <span className={process.env.REACT_APP_ICONS_TYPE}>
                    {icons.table}
                  </span>
                </div>
                <div className="icon-container" title={this.props.resizeIcon}>
                  <span className={process.env.REACT_APP_ICONS_TYPE}>
                    {this.props.resizeIcon}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ height: 280 }}>
              {this.state.isLoading ? (
                <Loading />
              ) : this.state?.data?.labels?.length > 0 ? (
                <Bar data={this.state.data} options={options} />
              ) : (
                <Empty title="No Data" />
              )}
            </div>
          </div>
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showTableModal")}
          show={this.state.showTableModal}
          title={language[this.props.defaultLanguage].requests_per_category}
        >
          <TableView
            data={this.state.tableData}
            chartName={
              language[this.props.defaultLanguage].requests_per_category
            }
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(PerCategory);
