import { connect } from "react-redux";
import icons from "../../constants/icons";
import { Button } from "../Button";
import { Logo } from "../Logo";
import "./styles.css";
import { HorizontalLoader } from "../Loader";

import logoImage from "../../assets/logo.png";

const Splash = (props) => {
  const error =
    "Unable to fetch services, could be your internet connection, please check and try again";
  return (
    <div className="splash-container">
      <center>
        <center className="splash-logo-container">
          <img src={logoImage} alt="MIFOTRA PSRP" />
          <div>
            MIFOTRA <span>PSRP</span>
          </div>
        </center>
        {/* {props.isInitiatingServices && ( */}
        <div style={{ marginTop: 15 }}>
          <HorizontalLoader />
        </div>
        {/* )} */}
      </center>
      {props?.error !== "" && (
        <center className="fetch-service-error-container">
          <p>{error}</p>
          <br />
          <p>
            If the issue persists despite having a stable internet connection,
            please reach out to our support team for further assistance
          </p>
          <br />
          <div className="d-flex gap-4 align-content-center justify-content-center">
            <Button
              icon={icons.email}
              className="btn-sm btn-secondary"
              title="Contact Support Team"
              type="link"
              to={`mailto:info@huzalabs.com?subject=${error}`}
            />
            <Button
              icon={icons.refresh}
              title="Refresh"
              onPress={props.handleRefresh}
            />
          </div>
        </center>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isInitiatingServices } = state.Services;
  const { defaultLanguage } = state.Language;
  return {
    isInitiatingServices,
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Splash);
