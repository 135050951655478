// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container .card-header {
  background-color: var(--light-color);
  border-bottom: 0;
  display: flex;
  align-items: center;
}

.chart-container .card-header .title {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Analytics/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT","sourcesContent":[".chart-container .card-body {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.chart-container .card-header {\n  background-color: var(--light-color);\n  border-bottom: 0;\n  display: flex;\n  align-items: center;\n}\n\n.chart-container .card-header .title {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
