import LandingPageLayout from "./LandingPageLayout";
import HomeAdminDashboardLayout from "./HomeAdminDashboardLayout";
import HomePublicServantDashboardLayout from "./HomePublicServantDashboardLayout";
import HomeAnalyticsLayout from "./HomeAnalyticsLayout";
import CenterLayout from "./CenterLayout";
import UserGuideLayout from "./UserGuideLayout";

export {
  LandingPageLayout,
  HomeAdminDashboardLayout,
  HomeAnalyticsLayout,
  CenterLayout,
  HomePublicServantDashboardLayout,
  UserGuideLayout,
};
