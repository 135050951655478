import {
  ON_LOADING_SERVICES,
  ON_FETCH_SERVICES,
  ON_SEARCH_SERVICES,
  ON_COPY_SERVICES,
} from "../actions/Services.js";

const KEY_NAME = "psrp-services";
const KEY_NAME_COPY_SERVICES = "PSRP-COPY-SERVICES";

const getStorage = (key) => {
  let storage = localStorage.getItem(key);

  return storage !== null ? JSON.parse(storage) : null;
};

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

let services = getStorage(KEY_NAME) ? getStorage(KEY_NAME) : {};
let copyServices = getStorage(KEY_NAME_COPY_SERVICES)
  ? getStorage(KEY_NAME_COPY_SERVICES)
  : [];

const initialState = {
  services,
  isInitiatingServices: false,
  searchInput: "",
  copyServices,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_LOADING_SERVICES:
      return {
        ...state,
        isInitiatingServices: action.isLoading,
      };

    case ON_FETCH_SERVICES:
      setLocalStorage(KEY_NAME, action.payload);

      return {
        ...state,
        services: action.payload,
        isInitiatingServices: false,
      };

    case ON_COPY_SERVICES:
      setLocalStorage(KEY_NAME_COPY_SERVICES, action.payload);

      return {
        ...state,
        copyServices: action.payload,
      };

    case ON_SEARCH_SERVICES:
      return {
        ...state,
        searchInput: action.searchInput,
      };
    default:
      return state;
  }
};
export default reducer;
