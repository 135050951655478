import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import language from "../../language";
import { Link } from "react-router-dom";

const MobileNavbarRef = React.forwardRef((props, ref) => {
  const { user } = props;

  const menus = [
    {
      name: language[props.defaultLanguage].dashboard,
      link: "/dashboard/home",
      access: ["protected"],
    },
    {
      name: language[props.defaultLanguage].individuals,
      link: "/landing/services/individual",
      access: ["public", "protected"],
    },
    {
      name: language[props.defaultLanguage].institutions,
      link: "/landing/services/institution",
      access: ["public", "protected"],
    },
    {
      name: language[props.defaultLanguage].signup,
      link: "/signup",
      access: ["public"],
    },
  ];
  return (
    <div className="container">
      <div className={`mobile-navbar ${props.className}`} ref={ref}>
        {menus.map((menu, i) => {
          if (user.token && menu.access.includes("protected")) {
            return (
              <div className="nav-item" key={i}>
                <Link to={menu.link}>{menu.name}</Link>
              </div>
            );
          }

          if (!user.token && menu.access.includes("public")) {
            return (
              <div className="nav-item" key={i}>
                <Link to={menu.link}>{menu.name}</Link>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MobileNavbarRef);
