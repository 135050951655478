import React from "react";
import { connect } from "react-redux";
import { ParagraphOne, TitleLoader } from "../Loader";
import "./styles.css";
import language from "../../language";
import { Button } from "../Button";
import { getStorage } from "../../utils/storage";
class Header extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    const props = this.props;
    return (
      <div className="dashboard-header-container">
        <div className="left-container">
          {this.state.user.fromInstitution && (
            <div className="d-flex gap-2 flex-grow-1 mb-3">
              {this.state.user.fromInstitution.length > 0 && (
                <div>
                  <div className="badge badge-default">
                    <span>{this.state.user.fromInstitution[0]}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {props.isLoading ? (
            <TitleLoader />
          ) : (
            <>
              {props.size === "md" ? (
                <h3 className="text-capitalized text-bold">
                  {props.name || props.title}
                </h3>
              ) : (
                <h2 className="text-capitalized text-bold">
                  {props.name || props.title}
                </h2>
              )}
              {props.description && <p>{props.description} </p>}
            </>
          )}
          <div className="dashboard-header-info-container">
            {props.isLoading ? (
              <ParagraphOne />
            ) : (
              props.category && (
                <div className="d-flex">
                  <div className="dashboard-header-info-item bg">
                    <span>{props?.category?.name || props.category}</span>
                  </div>
                </div>
              )
            )}

            {!props.isLoading && props.maxResponseTime && (
              <div className="dashboard-header-info-item">
                <span className="text-primary">
                  {language[props.defaultLanguage].avg_response_time}:{" "}
                  {props.maxResponseTime} Day(s)
                </span>
              </div>
            )}
            {!props.isLoading && props.isEdit && (
              <div className="dashboard-header-info-item">
                <Button
                  icon="edit"
                  text="Edit"
                  className="btn-transparent primary btn-sm"
                  onPress={props.onPressEdit}
                />
              </div>
            )}
          </div>
        </div>
        <div className="right-container d-flex align-items-center gap-3">
          {props.status && (
            <div
              className={`badge badge-lg badge-${props.status.toLowerCase()}`}
            >
              {props.status}
            </div>
          )}
          {props.actionButton && (
            <Button
              text={props.actionButton}
              onPress={props.handleActionButton}
              className="btn-sm"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Header);
