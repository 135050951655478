import "./layout.css";

const LandingPageLayout = (props) => {
  return (
    <div className={`landing-section ${props.className}`}>
      <div className="container">
        <div className="row content">
          <div className="col-md-10">
            <div className="landing-section-content">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageLayout;
