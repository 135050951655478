import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import { Button } from "../../Button";
import icons from "../../../constants/icons";
import { Modal } from "../../Modal";
import { AttachmentPreview } from "../../Attachment";

class Attachments extends React.Component {
  state = {
    attachmentData: {},
  };

  handleShowModal(attachmentData) {
    this.setState({
      attachmentData: attachmentData,
      showAttachmentModal: true,
    });
  }

  handleCloseModal() {
    this.setState({ showAttachmentModal: false });
  }

  render() {
    return (
      <>
        <div className="attachment-container">
          <div className="row gap-2">
            {this.props?.data?.map((el, i) => {
              const ext =
                el?.answer
                  ?.substr(el?.answer.lastIndexOf(".") + 1)
                  ?.toLowerCase() || "";

              if (el?.answerType === "file") {
                return (
                  <div className="col-md-2 attachment-item-container" key={i}>
                    {(ext === "png" || ext === "jpg" || ext === "jpeg") && (
                      <img
                        src={process.env.REACT_APP_PSRP_URL + "/" + el.answer}
                      />
                    )}
                    {ext === "pdf" && (
                      <object
                        data={process.env.REACT_APP_PSRP_URL + "/" + el.answer}
                        type="application/pdf"
                        width="100%"
                        height="150px"
                      ></object>
                    )}
                    <div className="download">
                      <Button
                        className="btn-sm"
                        icon={icons.eye_on}
                        onPress={() =>
                          this.handleShowModal({
                            url:
                              process.env.REACT_APP_PSRP_URL + "/" + el.answer,
                            extension: ext,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentModal")}
          show={this.state.showAttachmentModal}
        >
          <AttachmentPreview {...this.state.attachmentData} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Attachments);
