import { connect } from "react-redux";
import "./styles.css";
import language from "../../../language";
import icons from "../../../constants/icons";

const Features = (props) => {
  const listFeatures = [
    {
      icon: icons.request_service,
      backgroundColor: "#078ECE",
      title: language[props.defaultLanguage].request_service_title,
      description: "Initiate your service request online, effortlessly.",
    },
    {
      icon: icons.time_loading,
      backgroundColor: "#FF6D00",
      title: language[props.defaultLanguage].support_24_title,
      description: "Always open for your service requests.",
    },
    {
      icon: icons.notification,
      backgroundColor: "#008000",
      title: language[props.defaultLanguage].get_instant_notification_title,
      description:
        "Stay updated in real-time as your service request progresses.",
    },
  ];

  return (
    <div div className={`features row ${props.className}`}>
      {listFeatures.map((item, i) => {
        return (
          <div
            className={`feature-item col-md-${props.columnSize || 4} ${
              props.itemClassName
            }`}
            key={i}
          >
            <div
              className="icon-container"
              style={{ backgroundColor: item.backgroundColor }}
            >
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {item.icon}
              </span>
            </div>
            <div className="feature-description">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
            {i !== listFeatures.length - 1 && props.type === "vertical" ? (
              <div
                className={`border-connector `}
                style={{
                  borderStyle: i % 2 === 0 ? "solid" : "dashed",
                  borderColor: i % 2 === 0 ? "#1565c0" : "#cbe0f2",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

const mapStateProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateProps)(Features);
