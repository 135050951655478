import language from "../../language";
import sortLevels from "../../utils/sortLevels";
import ReviewersLogs from "../Reviewers/ReviewersLogs";
import { connect } from "react-redux";

const Sidebar = (props) => {
  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <div className="card" style={{ marginBottom: "1.5rem" }}>
        <div className="card-header">
          <span className="text-bold">
            {language[props.defaultLanguage].sub_approvals_logs}
          </span>
        </div>
        <div className="card-body">
          <ReviewersLogs
            data={sortLevels(
              props.data.subApprovals.map((el) => {
                if (el.account) {
                  el.institution = el.account.institution;
                } else if (el.accessRole) {
                  el.institution = `(${el.accessRole.name})`;
                }
                return el;
              }),
              "level",
              "institution"
            )}
          />
        </div>
        <div className="card-header">
          <span className="text-bold">
            {language[props.defaultLanguage].approvals_logs}
          </span>
        </div>

        <div className="card-body">
          <ReviewersLogs
            data={sortLevels(
              props.data.approvals.map((el) => {
                if (el.account) {
                  el.institution = el.account.institution;
                } else if (el.accessRole) {
                  el.institution = `(${el.accessRole.name})`;
                }
                return el;
              }),
              "level",
              "institution"
            )}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Sidebar);
