import { DashboardHeader } from "../../../Header";
import Reviewers from "../../widgets/Reviewers";
import language from "../../../../language";

export default (that) => {
  if (that.state?.data?.subApprovals?.length > 0) {
    const subApprovals = that.state.data?.subApprovals;
    const sortedArray = subApprovals.sort((a, b) => a.level - b.level);
    return (
      <>
        {/* sub approvals */}
        <div style={{ marginBottom: "1rem" }}>
          <div>
            {that.state.data?.subApprovals?.length > 0 && (
              <>
                <DashboardHeader
                  title={`${
                    language[that.props.defaultLanguage].sub_approvals
                  } (${that.state.data?.subApprovals?.length})`}
                  size="md"
                />
                <Reviewers data={that.state.data} reviewers={sortedArray} />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};
