// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-count-container {
  border-top-width: 3px;
  border-top-style: solid;
}

.card-count-container .card-body {
  display: flex;
  min-height: 112px;
  align-items: center;
  gap: 1.5rem;
}

.card-count-container .card-count-icon-container {
  width: 34px;
  height: 34px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-count-container .card-count-icon-container span {
  color: var(--light-color);
}

.card-count-container .card-count-content {
  flex: 1 1;
}

.card-count-container .card-count-content .count {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/CardCount/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".card-count-container {\n  border-top-width: 3px;\n  border-top-style: solid;\n}\n\n.card-count-container .card-body {\n  display: flex;\n  min-height: 112px;\n  align-items: center;\n  gap: 1.5rem;\n}\n\n.card-count-container .card-count-icon-container {\n  width: 34px;\n  height: 34px;\n  border-radius: var(--border-radius-half);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.card-count-container .card-count-icon-container span {\n  color: var(--light-color);\n}\n\n.card-count-container .card-count-content {\n  flex: 1;\n}\n\n.card-count-container .card-count-content .count {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
