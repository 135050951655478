// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-view-container {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/SingleRequest/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".request-view-container {\r\n  height: 100vh;\r\n  overflow-y: auto;\r\n  padding-bottom: 3rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
