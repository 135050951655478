import language from "../../../language";
import axios from "axios";
import toastMessage from "../../toastMessage";
import formatSelectData from "../../formatSelectData";
import { getStorage } from "../../storage";

export const fetchIntegratedApi = async (lng = "english", params = {}) => {
  try {
    const {
      apiUrl,
      dropdownFieldName,
      dropdownFieldValue,
      responseKey = "data",
      apiParams,
      apiMethod,
    } = params;

    const user = await getStorage();

    if (!apiUrl || !dropdownFieldName || !dropdownFieldValue) return;

    let newApiParams = await parseApiParams(apiParams);

    const options = {
      method: apiMethod || "GET",
      url: `${apiUrl}`,
      params: {
        ...newApiParams,
        ippisToken: user.ippisToken,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const data = await axios(options);

    return formatSelectData(
      data[responseKey],
      dropdownFieldName,
      dropdownFieldValue
    );
  } catch (error) {
    // toastMessage("error", language[lng].unable_to_fetch_ippis_info);
  }
};

const parseApiParams = async (apiParams) => {
  let params = {};

  if (apiParams && apiParams !== "") {
    const parsed = JSON.parse(apiParams);

    params = await getFormattedParams(parsed);
  }

  return params;
};

const getFormattedParams = async (object) => {
  const { paramValue, paramName, paramValueField, paramValueType } = object;
  const user = await getStorage();
  let userParamValue = user;

  if (paramValue) {
    const properties = paramValue.split(".");

    for (const prop of properties) {
      userParamValue = userParamValue[prop];
    }

    if (paramValueType === "array" && userParamValue) {
      userParamValue?.forEach((prop2El) => {
        userParamValue = prop2El[paramValueField];
      });
    }
  }
  return {
    [paramName]: userParamValue,
  };
};
