import React from "react";
import { Tabs } from "../../../components/Tabs";
import { connect } from "react-redux";
import language from "../../../language";
import { DashboardHeader } from "../../../components/Header";
import { Requests } from "../../../components/AdminRequests";
import { withRouter } from "../../../utils/withRouter";

class RequestScreen extends React.Component {
  render() {
    const {
      request_title,
      individual_requests,
      institution_requests,
      request_description,
    } = language[this.props.defaultLanguage];
    console.log(this.props);
    return (
      <>
        <DashboardHeader
          title={(this.props.status || "") + " " + request_title}
          description={request_description}
          dateRange={{
            startDate: this?.props?.searchParams?.get("startCreatedAt"),
            endDate: this?.props?.searchParams?.get("endCreatedAt"),
          }}
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: individual_requests,
                  count:
                    this.props.status === "pending"
                      ? this.props.countPendingRequestsPerType["individual"]
                      : "",
                  data: (
                    <Requests
                      type="individual"
                      status={this.props.status || "pending"}
                      startCreatedAt={this?.props?.searchParams?.get(
                        "startCreatedAt"
                      )}
                      endCreatedAt={this?.props?.searchParams?.get(
                        "endCreatedAt"
                      )}
                      availableFilters={["services", "date_range"]}
                      exportEnabled
                    />
                  ),
                },
                {
                  title: institution_requests,
                  count:
                    this.props.status === "pending"
                      ? this.props.countPendingRequestsPerType["institution"]
                      : "",
                  data: (
                    <Requests
                      type="institution"
                      status={this.props.status || "pending"}
                      startCreatedAt={this?.props?.searchParams?.get(
                        "startCreatedAt"
                      )}
                      endCreatedAt={this?.props?.searchParams?.get(
                        "endCreatedAt"
                      )}
                      availableFilters={["services", "date_range"]}
                      exportEnabled
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { countPendingRequestsPerType } = state.Request;
  return {
    defaultLanguage,
    countPendingRequestsPerType,
  };
};

export default connect(mapStateToProps)(withRouter(RequestScreen));
