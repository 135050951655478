import React from "react";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { DashboardHeader } from "../../components/Header";
import { Alerts } from "../../components/Alerts";
import moment from "moment";

class AlertScreen extends React.Component {
  render() {
    const {
      alerts_title,
      alerts_description,
      pending_30_days,
      pending_90_days,
    } = language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader
          title={alerts_title}
          description={alerts_description}
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: pending_30_days,
                  data: (
                    <Alerts
                      startCreatedAt={new Date(
                        moment().subtract(30, "days").format("YYYY-MM-DD")
                      ).getTime()}
                      endCreatedAt={new Date(
                        moment().endOf("D").format("YYYY-MM-DD")
                      ).getTime()}
                    />
                  ),
                },
                {
                  title: pending_90_days,
                  data: (
                    <Alerts
                      startCreatedAt={new Date(
                        moment().subtract(90, "days").format("YYYY-MM-DD")
                      ).getTime()}
                      endCreatedAt={new Date(
                        moment().endOf("D").format("YYYY-MM-DD")
                      ).getTime()}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AlertScreen);
