// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  min-height: 250px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.chart-container.border {
  border: 1px solid var(--text-color-10);
  border-radius: 8px;
}

.chart-container .header-container {
  min-height: 50px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-bottom: 0;
}

.chart-container .doughnut-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
}

.chart-container .empty-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
}

.chart-container .empty-chart span {
  font-weight: var(--font-weight-md);
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Charts/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;EAClC,YAAY;AACd","sourcesContent":[".chart-container {\n  min-height: 250px;\n  border-radius: 8px;\n  margin-bottom: 1rem;\n}\n\n.chart-container.border {\n  border: 1px solid var(--text-color-10);\n  border-radius: 8px;\n}\n\n.chart-container .header-container {\n  min-height: 50px;\n  padding: 0 1rem;\n  display: flex;\n  align-items: center;\n  border-bottom: 0;\n}\n\n.chart-container .doughnut-chart {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1rem;\n  position: relative;\n}\n\n.chart-container .empty-chart {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 180px;\n}\n\n.chart-container .empty-chart span {\n  font-weight: var(--font-weight-md);\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
