// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-counter {
  top: 0;
  right: 5px;
  background-color: var(--error-color);
  height: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-color);
  padding-left: 5px;
  padding-right: 5px;
}

.notification-counter.primary-border {
  border: 2px solid var(--primary-color);
}

.notification-counter span {
  color: var(--light-color);
  font-size: 11px;
  font-weight: var(--font-weight-md);
}

.notification-counter:hover span {
  color: var(--light-color) !important;
}

.notification-counter.small span {
  font-size: 9px;
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Notifications/counter.styles.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,UAAU;EACV,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,YAAY;AACd","sourcesContent":[".notification-counter {\r\n  top: 0;\r\n  right: 5px;\r\n  background-color: var(--error-color);\r\n  height: 20px;\r\n  border-radius: 16px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  border: 2px solid var(--light-color);\r\n  padding-left: 5px;\r\n  padding-right: 5px;\r\n}\r\n\r\n.notification-counter.primary-border {\r\n  border: 2px solid var(--primary-color);\r\n}\r\n\r\n.notification-counter span {\r\n  color: var(--light-color);\r\n  font-size: 11px;\r\n  font-weight: var(--font-weight-md);\r\n}\r\n\r\n.notification-counter:hover span {\r\n  color: var(--light-color) !important;\r\n}\r\n\r\n.notification-counter.small span {\r\n  font-size: 9px;\r\n  height: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
