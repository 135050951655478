import "./counter.styles.css";

const NotificationBadgeCount = (props) => {
  return (
    <div
      className={`notification-counter ${props.borderClassName} ${props.className}`}
      style={props.styles}
    >
      <span>{props.count}</span>
    </div>
  );
};

export default NotificationBadgeCount;
