import React from "react";
import { connect } from "react-redux";
import { Input, Select, Switch } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import departmentQuery, {
  addDepartment,
} from "../../utils/queries/departmentQuery";
import jobPositionQuery, {
  addPosition,
} from "../../utils/queries/jobPositionQuery";
import { Permissions } from "../AccessRole";
import accessRoleQuery from "../../utils/queries/accessRoleQuery";
import validateEmail from "../../utils/validateEmail";
import { activeOptions } from "../../constants/account";
import externalUserInfoQuery from "../../utils/queries/externalUserInfoQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import validatePassword from "../../utils/validatePassword";
import apiError from "../../utils/apiError";
import accountRoles from "../../constants/accountRoles";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class NewAccount extends React.Component {
  state = {
    email: "",
    username: "",
    ippisNumber: "",
    password: "",
    error: {},
    isSubmitting: false,
    departments: [],
    accessRoles: [],
    jobPositions: [],
    roles: [],
    status: activeOptions[0],
    showAccessRoles: false,
    firstName: "",
    outsideOrganization: false,
    accountRole: accountRoles[0],
    institution: "",
    lastName: "",
  };

  componentDidMount() {
    this.setState({ ...this.props });

    this.getDepartments(true);
    this.getRoles(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.profileData !== this.state.profileData) {
      const {
        firstName,
        lastName,
        email,
        employeeId,
        department,
        position,
        institution,
      } = this.state.profileData;

      this.setState({
        username: firstName + " " + lastName,
        lastName,
        firstName,
        email,
        ippisNumber: employeeId,
        positionName: position,
        institution,
      });

      if (department !== "" && position !== "") {
        this.handleDepartmentAndPositions(department, position);
      }
    }
  }

  handleDepartmentAndPositions = async (department, position) => {
    if ((!department || department === "") && (!position || department === ""))
      return;

    this.setState({ isFetchingDepartment: true, isFetchingPosition: true });

    await addDepartment(this.props.defaultLanguage, department);
    await addPosition(this.props.defaultLanguage, {
      department,
      position,
    });

    this.getDepartments(true, department);
  };

  getDepartments = async (
    isFetchingDepartment,
    departmentName = this.props?.department?.name
  ) => {
    try {
      this.setState({
        isFetchingDepartment,
      });

      const data = await departmentQuery(this.props.defaultLanguage, {
        type: "dropdown",
        name: departmentName,
      });

      this.setSelectedDepartment(data, departmentName);

      this.setState({
        departments: data,
        isFetchingDepartment: false,
      });
    } catch (error) {
      this.setState({ isFetchingDepartment: false });
    }
  };

  getJobPositions = async (isFetchingPosition, { department, position }) => {
    try {
      this.setState({
        isFetchingPosition,
      });

      const data = await jobPositionQuery(this.props.defaultLanguage, {
        department,
        type: "dropdown",
        positionName: position,
      });

      this.setSelectedPosition(
        data,
        this.props?.position || this.state.positionName
      );

      this.setState({
        jobPositions: data,
        isFetchingPosition: false,
      });
    } catch (error) {
      this.setState({ isFetchingPosition: false });
    }
  };

  getRoles = async (isFetchingAccessRole) => {
    try {
      this.setState({
        isFetchingAccessRole,
      });

      const data = await accessRoleQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedRole(data);

      this.setState({
        roles: data,
        isFetchingAccessRole: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccessRole: false });
    }
  };

  setSelectedDepartment(departments, department) {
    const selectedDepartment = departments.find(
      (el) =>
        el.value === department._id ||
        el.value === department ||
        el.label === department
    );

    if (selectedDepartment?._id) {
      this.setState(
        {
          department: {
            label: selectedDepartment.name,
            value: selectedDepartment._id,
          },
        },
        () => {
          this.getJobPositions(true, {
            department: selectedDepartment._id,
            position: this.state.positionName,
          });
        }
      );
    }
  }

  setSelectedPosition(positions, position) {
    const selectedPosition = positions.find(
      (el) =>
        el.value === position._id ||
        el.value === position ||
        el.label?.replaceAll(" ", "") === position?.replaceAll(" ", "")
    );

    if (selectedPosition?._id) {
      this.setState({
        jobPosition: {
          label: selectedPosition?.name,
          value: selectedPosition._id,
        },
      });
    }
  }

  setSelectedRole(roles) {
    const { accessRole } = this.props;
    if (accessRole) {
      const selectedRole = roles.find(
        (el) => el._id === accessRole._id || accessRole
      );

      if (selectedRole._id) {
        this.setState({
          accessRole: {
            label: selectedRole.name,
            value: selectedRole._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let { defaultLanguage } = this.props;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    if (field === "password" && validatePassword(defaultLanguage, inputValue)) {
      error[field] = validatePassword(defaultLanguage, inputValue);
    }

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "department") {
      this.setState({ jobPosition: null });
      this.getJobPositions(true, {
        department: inputValue.value,
        position: this.state.positionName,
      });
    }

    if (field === "firstName") {
      this.setState({
        email: "",
        username: "",
        password: "",
        isSubmitting: false,
        department: null,
        jobPosition: null,
      });
    }

    if (field === "accessRole") {
      this.setState({
        outsideOrganization:
          e.outsideOrganization !== true ? false : e.outsideOrganization,
      });
    }
  };

  onCheck() {
    this.setState({
      outsideOrganization: !this.state.outsideOrganization,
    });
  }

  validateForm() {
    let {
      username,
      email,
      password,
      department,
      jobPosition,
      accessRole,
      error,
      ippisNumber,
      _id,
      firstName,
      lastName,
    } = this.state;

    if (ippisNumber === "") {
      error.ippisNumber =
        language[this.props.defaultLanguage].ippis_number_required;
    }

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    if (lastName === "") {
      error.lastName = language[this.props.defaultLanguage].last_name_required;
    }

    if (username === "") {
      error.username = language[this.props.defaultLanguage].username_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (!_id || _id === "") {
      if (password === "") {
        error.password = language[this.props.defaultLanguage].password_required;
      } else if (validatePassword(this.props.defaultLanguage, password)) {
        error.password = `${validatePassword(
          this.props.defaultLanguage,
          password
        )} `;
      }
    }

    if (!department) {
      error.department =
        language[this.props.defaultLanguage].department_required;
    }

    if (!jobPosition) {
      error.jobPosition =
        language[this.props.defaultLanguage].job_position_required;
    }

    if (!accessRole) {
      error.accessRole =
        language[this.props.defaultLanguage].access_role_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          username,
          email,
          password,
          department,
          jobPosition,
          accessRole,
          _id,
          ippisNumber,
          status,
          outsideOrganization,
          institution,
          accountRole,
          firstName,
          lastName,
        } = this.state,
        url = `${API_URL}/account`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        firstName,
        lastName,
        username,
        email,
        password,
        position: [jobPosition.value],
        department: [department.value],
        accessRole: [accessRole.value],
        ippisNumber,
        employeeId: ippisNumber,
        isActive: status.value === "active",
        institution,
        outsideOrganization,
        role: accountRole.value,
      };

      if (outsideOrganization) {
        requestedBody.accountType = "account-subApprover";
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
        delete requestedBody.password;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_account_success"
                : "add_account_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((resError) => {
          this.setState({
            isSubmitting: false,
          });

          const { error_add_account, error_update_account, account_exist } =
            language[this.props.defaultLanguage];

          apiError(resError, {
            requestMethod: method,
            addErrorMessage: error_add_account,
            updateErrorMessage: error_update_account,
            dataExistErrorMessage: account_exist,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].first_name_placeholder
                }
                label={language[this.props.defaultLanguage].firstName}
                required
                value={this.state.firstName}
                onChange={(e) => this.onChangeText("firstName", e)}
                error={this.state.error.firstName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.identification}
                placeholder={
                  language[this.props.defaultLanguage].ippis_number_placeholder
                }
                label={language[this.props.defaultLanguage].ippis_number}
                required
                value={this.state.ippisNumber}
                onChange={(e) => this.onChangeText("ippisNumber", e)}
                error={this.state.error.ippisNumber}
                rightButtonIcon={icons.search}
                rightButtonPressed={() =>
                  externalUserInfoQuery(this, {
                    params: {
                      employeeId: this.state.ippisNumber,
                      firstName: this.state.firstName,
                    },
                  })
                }
                isLoading={this.state.isRetrievingUserInfo}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.username}
                onChange={(e) => this.onChangeText("username", e)}
                error={this.state.error.username}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.institution}
                placeholder={
                  language[this.props.defaultLanguage].institution_placeholder
                }
                label={language[this.props.defaultLanguage].institution}
                required
                value={this.state.institution}
                onChange={(e) => this.onChangeText("institution", e)}
                error={this.state.error.institution}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Select
                leftIcon={icons.department}
                options={this.state.departments}
                label={language[this.props.defaultLanguage].department}
                required
                value={this.state.department}
                onChange={(e) => this.onChangeText("department", e)}
                error={this.state.error.department}
                isLoading={this.state.isFetchingDepartment}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Select
                leftIcon={icons.position}
                options={this.state.jobPositions}
                label={language[this.props.defaultLanguage].job_position}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.jobPosition}
                onChange={(e) => this.onChangeText("jobPosition", e)}
                error={this.state.error.jobPosition}
                isLoading={this.state.isFetchingPosition}
                disabled
              />
            </div>

            {/* <div className="col-md-6">
              <Select
                leftIcon={icons.user}
                options={accountRoles}
                label={language[this.props.defaultLanguage].account_role}
                required
                value={this.state.accountRole}
                onChange={(e) => this.onChangeText("accountRole", e)}
                error={this.state.error.accountRole}
              />
            </div> */}
            {!this.state._id && (
              <div className="col-md-6">
                <Input
                  leftIcon={icons.password}
                  placeholder={
                    language[this.props.defaultLanguage].enter_password
                  }
                  label={language[this.props.defaultLanguage].password}
                  required
                  value={this.state.password}
                  onChange={(e) => this.onChangeText("password", e)}
                  error={this.state.error.password}
                  type="password"
                  helperText={`${
                    language[this.props.defaultLanguage].password_min_error
                  }, ${
                    language[this.props.defaultLanguage]
                      .password_contains_uppercase_letter
                  }, ${
                    language[this.props.defaultLanguage]
                      .password_contains__lowercase_letter
                  }, ${
                    language[this.props.defaultLanguage]
                      .password_contains_number
                  }`}
                />
              </div>
            )}

            <div className="col-md-6">
              <Select
                leftIcon={icons.role}
                options={this.state.roles}
                label={language[this.props.defaultLanguage].access_role}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.accessRole}
                onChange={(e) => this.onChangeText("accessRole", e)}
                error={this.state.error.accessRole}
                isLoading={this.state.isFetchingAccessRole}
              />
              <div>
                {this.state.showAccessRoles &&
                  this.state?.accessRole?.permissions &&
                  this.state?.accessRole?.permissions?.menus.map((menu, m) => {
                    return (
                      <Permissions
                        title={menu}
                        key={m}
                        operations={
                          this.state?.accessRole?.permissions?.operations
                        }
                        menuItem={menu}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="col-md-6">
              <Select
                leftIcon={icons.status}
                options={activeOptions}
                label={language[this.props.defaultLanguage].status}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
              />
            </div>
            <div className="col-md-12">
              <Switch
                checked={this.state.outsideOrganization}
                onCheck={this.onCheck.bind(this)}
                title={
                  language[this.props.defaultLanguage]
                    .outside_organization_title
                }
                description={
                  language[this.props.defaultLanguage]
                    .outside_organization_description
                }
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
            disabled={Object.keys(this.state.profileData || {}).length === 0}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewAccount);
