import { connect } from "react-redux";
import language from "../../../language";
import React from "react";
import { Table } from "../../Table";
import { Modal } from "../../Modal";
import { Attachments } from "../../Attachment";
import sortLevels from "../../../utils/sortLevels";

class ViewSingleApproverInfo extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    try {
      const data = [];
      this.props.data.forEach((el) => {
        // if (!el.action || el.action === "") return;

        if (el.account) {
          el.institution = el.account.institution;
        } else if (el.accessRole) {
          el.institution = `(${el.accessRole.name})`;
        }

        data.push({
          ...el,
          actionName:
            el.action === "Approval"
              ? "Approved"
              : el.action === "Rejections"
              ? "Rejected"
              : "-",
          totalAttachments:
            el.questionnaireAction?.length > 0
              ? `${el.questionnaireAction?.length} Attachment(s)`
              : "",
        });
      });

      console.log("error", this.props.data);
      console.log("data", data);

      this.setState({ data: sortLevels(data, "level", "institution") });
    } catch (error) {
      console.log("error", error);
    }
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].level,
        key: "level",
      },
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },
      {
        title: language[this.props.defaultLanguage].comment,
        key: "actionComment",
      },
      {
        title: language[this.props.defaultLanguage].attachments,
        key: "totalAttachments",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showAttachmentsModal",
            language[this.props.defaultLanguage].document_attached,
            item
          ),
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "actionAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  render() {
    return (
      <div className="card-body">
        <Table data={this.state?.data} headers={this.returnTableHeaders()} />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentsModal")}
          show={this.state.showAttachmentsModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div className="card-body">
            <Attachments
              data={this.state.selectedData?.questionnaireAction || []}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ViewSingleApproverInfo);
