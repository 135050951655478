import { Button } from "../../../Button";
import { Input } from "../../../Input";
import renderUserInfoComment from "./renderUserInfoComment";
import language from "../../../../language";
import icons from "../../../../constants/icons";

export default (that) => {
  if (that.state.data?.status?.toLowerCase() === "pending")
    return (
      <div className="comments-container" style={{ paddingTop: "1rem" }}>
        <hr />
        <div className="comment-description">
          {[...that.state.data.subApprovals, ...that.state.data.approvals].map(
            (el, i) => {
              if (el.actionComment && el.actionComment !== "") {
                return (
                  <>
                    {renderUserInfoComment(that, {
                      account: el.account,
                      actionStatus: el.action,
                    })}
                    <div
                      style={{ paddingTop: "1.5rem", marginLeft: 75 }}
                      key={i}
                    >
                      <span>{el.actionComment}</span>
                    </div>
                    <hr />
                  </>
                );
              }
            }
          )}
        </div>

        {(!that.state.requestAdminInfo.actionComment ||
          that.state.requestAdminInfo.actionComment === "") && (
          <>
            {renderUserInfoComment(that, {
              account: that.state.requestAdminInfo.account,
              actionStatus: that.state.requestAdminInfo.action,
            })}
            <Input
              textarea
              placeholder={language[that.props.defaultLanguage].write_message}
              value={that.state.comment}
              onChange={(e) => that.onChangeText("comment", e)}
              error={that.state.error.comment}
            />
            <div className="buttons-actions d-flex gap-2">
              {/* <div style={{ flex: 1 }}>
                <Button
                  text="Send"
                  className="btn-default"
                  icon={icons.send}
                  onPress={() => sendComment(that)}
                  isSubmitting={that.state.isSubmittingComment}
                />
              </div> */}
              <Button
                text={language[that.props.defaultLanguage].reject}
                className="btn-default"
                icon={icons.fail}
                onPress={() =>
                  that.handleShowModal(
                    that.state?.user?.accountType === "account-subApprover"
                      ? "showSubApproverRejectModal"
                      : "showApproverRejectModal"
                  )
                }
              />
              <Button
                text={language[that.props.defaultLanguage].approve}
                icon={icons.success}
                onPress={() =>
                  that.handleShowModal(
                    that.state?.user?.accountType === "account-subApprover"
                      ? "showSubApproverApproveModal"
                      : "showApproverApproveModal"
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    );
};
