import { connect } from "react-redux";
import icons from "../../constants/icons";
import { Button } from "../Button";
import "./styles.css";
import language from "../../language";

const AttachmentPreview = (props) => {
  const { extension, url } = props;
  console.log(`Attachment Preview`, props);
  return (
    <div className="attachment-preview-container">
      {/* {(extension === "png" || extension === "jpg" || extension === "jpeg") && (
        <img src={url} />
      )} */}
      {extension === "pdf" ? (
        <object data={url} type="application/pdf" width="100%"></object>
      ) : (
        extension === "doc" ||
        (extension === "docx" ? (
          <iframe
            src={url}
            width="100%"
            height="714"
            frameborder="0"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
            allowfullscreen
          >
            {" "}
          </iframe>
        ) : (
          <img src={url} />
        ))
      )}

      <div className="buttons-container">
        <Button
          text={language[props.defaultLanguage].download}
          icon={icons.download}
          onPress={() => window.open(url, "_blank")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AttachmentPreview);
