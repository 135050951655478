import { Link } from "react-router-dom";
import "./styles.css";

const ServiceItem = (props) => {
  return (
    <div className="service-item">
      <Link
        to={
          props?.user?.token && props?.user?.role === "user"
            ? `/public-servant/request/${props._id}`
            : `/login?serviceId=${props._id}`
        }
        className="service-item-name"
      >
        {props.name}
      </Link>
    </div>
  );
};

export default ServiceItem;
