// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-activity {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 270px;
  color: var(--text-color-60);
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--light-color);
}

.empty-activity .icon-container {
  background-color: var(--primary-color-5);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.empty-activity .icon-container span {
  font-size: 24px;
  color: var(--primary-color);
}

.empty-activity-description-container {
  max-width: 30vw;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Empty/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,2BAA2B;EAC3B,gBAAgB;EAChB,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;EACE,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".empty-activity {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-height: 270px;\r\n  color: var(--text-color-60);\r\n  overflow-y: auto;\r\n  flex-direction: column;\r\n  background-color: var(--light-color);\r\n}\r\n\r\n.empty-activity .icon-container {\r\n  background-color: var(--primary-color-5);\r\n  width: 60px;\r\n  height: 60px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\n.empty-activity .icon-container span {\r\n  font-size: 24px;\r\n  color: var(--primary-color);\r\n}\r\n\r\n.empty-activity-description-container {\r\n  max-width: 30vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
