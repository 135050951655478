import { useSearchParams, useParams } from "react-router-dom";

export const withRouter = (WrappedComponent) => (props) => {
  const [searchParams] = useSearchParams();

  return (
    <WrappedComponent
      {...props}
      routeParams={useParams()}
      searchParams={searchParams}
    />
  );
};
