import React from "react";
import { getStorage, setStorage } from "../../utils/storage";
import { connect } from "react-redux";
import "./styles.css";
import { ListItem } from "../List";
import icons from "../../constants/icons";

class AccountSwitch extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleSection = async (item) => {
    let { user } = this.state;

    user.fromInstitution = [item.entitySectorName, item.lineMinistryId];
    // item.entitySectorName + "_" + item.lineMinistryId;

    await setStorage(user);

    window.location.reload();
  };

  getEntityData() {
    const { ippisUserData = {} } = this.state.user;
    const { userEntitySectors = [] } = ippisUserData;

    let data = [];

    userEntitySectors.forEach((el) => {
      if (el.lineMinistryId) {
        data.push(el);
      }
    });

    return data;
  }

  render() {
    return (
      <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
        {this.getEntityData().length > 0 && this.props.title && (
          <div className="account-switch-header">
            <span className="text-bold text-disabled">{this.props.title}</span>
          </div>
        )}
        {this.getEntityData()?.map((item, i) => {
          if (!item.lineMinistryId) return;

          let fromInstitution = false;

          if (
            this.state.user &&
            this.state.user.fromInstitution &&
            Array.isArray(this.state.user.fromInstitution)
          ) {
            fromInstitution =
              this.state.user.fromInstitution.includes(item.lineMinistryId) &&
              this.state.user.fromInstitution.includes(item.entitySectorName);
          }

          return (
            <div key={i}>
              <ListItem
                name={item.entitySectorName}
                onPress={() => this.handleSection(item)}
                iconClassName={fromInstitution && "active"}
                icon={fromInstitution ? icons.check : icons.institution}
                items={item.roles}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AccountSwitch);
