import React from "react";
import "./styles.css";
import { CardHeader } from "../Header";
import { Empty } from "../Empty";
import { Link } from "react-router-dom";
import { getStorage } from "../../utils/storage";
import socket from "../../utils/socket/connection";

class Activities extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    socket.on("notification", async (data) => {
      console.log("=================socket listing...===================");
      console.log(data);
      console.log("==================END OF SOCKET==================");

      const user = await getStorage();
      const { approver, subApprover, body } = data;

      console.log();

      if (
        user.id === body?.user ||
        approver?.includes(user.id) ||
        subApprover?.includes(user.id)
      ) {
        this.addToActivities(...body);
      }
    });
  }

  addToActivities(body) {
    let { data } = this.state;

    const findIndex = data.findIndex((el) => el.id === body._id);

    console.log({ findIndex });

    if (findIndex !== -1) {
      data.push(body);
    }

    console.log("====================================");
    console.log(data);
    console.log("====================================");

    this.setState({ data });
  }

  render() {
    return (
      <div className="card activities-container hidden-sm hidden-xs">
        <CardHeader title="Activities" expandMore />
        <div className="card-body">
          {this.state.data.length === 0 ? (
            <Empty title="Live activies stream (empty)" />
          ) : (
            <div>
              {this.state.data.map((el, i) => {
                return (
                  <div className="activity-item-container" key={i}>
                    <span className="text-primary">
                      User <Link to="/">({el?.user})</Link>
                    </span>
                    <span className="activity-definition">
                      has made a request on
                    </span>
                    <span>
                      <Link to="" className="long-text">
                        {el.description}
                      </Link>
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Activities;
