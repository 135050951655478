import React from "react";
import { DashboardHeader } from "../../../components/Header";
import { Accounts } from "../../../components/Accounts";
import language from "../../../language";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import { withRouter } from "../../../utils/withRouter";

class ViewSingleDepartmentScreen extends React.Component {
  render() {
    const { accounts_title, accounts_description } =
      language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader
          title={language[this.props.defaultLanguage].department}
        />
        <div className="card card-dashboard-content">
          <Tabs
            options={[
              {
                title: "Recent Individual Requests",
                data: <Accounts />,
              },
              {
                title: "Recent Institution Requests",
                data: <Accounts />,
              },
              {
                title: "Pending Requests in 30 Days",
                data: <></>,
              },
              {
                title: "Pending Requests in 90 Days",
                data: <></>,
              },
              {
                title: "Employees",
                data: <></>,
              },
              {
                title: "Job Positions",
                data: <></>,
              },
            ]}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ViewSingleDepartmentScreen));
