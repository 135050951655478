// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-list-container .service-item-content {
  max-height: 60vh;
  overflow-y: auto;
  background-color: var(--light-color);
}

.service-list-container .loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
}

.service-list-container .service-list-item {
  display: flex;
  min-height: 55px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--text-color-5);
  transition: background-color 0.4s;
}

.service-list-container .service-list-item .name {
  flex: 1 1;
}

.service-list-container .service-list-item .name .service-name {
  text-transform: capitalize;
}

.service-list-container .service-list-item span {
  color: var(--text-color);
  font-weight: var(--font-weight);
}

.service-list-container .service-list-item:hover {
  background-color: var(--text-color-5);
  cursor: pointer;
}

.service-item-content-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/UserServices/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,4CAA4C;EAC5C,iCAAiC;AACnC;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;EACxB,+BAA+B;AACjC;;AAEA;EACE,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".service-list-container .service-item-content {\r\n  max-height: 60vh;\r\n  overflow-y: auto;\r\n  background-color: var(--light-color);\r\n}\r\n\r\n.service-list-container .loader-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-height: 30vh;\r\n}\r\n\r\n.service-list-container .service-list-item {\r\n  display: flex;\r\n  min-height: 55px;\r\n  align-items: center;\r\n  padding: 0.5rem 1rem;\r\n  border-bottom: 1px solid var(--text-color-5);\r\n  transition: background-color 0.4s;\r\n}\r\n\r\n.service-list-container .service-list-item .name {\r\n  flex: 1;\r\n}\r\n\r\n.service-list-container .service-list-item .name .service-name {\r\n  text-transform: capitalize;\r\n}\r\n\r\n.service-list-container .service-list-item span {\r\n  color: var(--text-color);\r\n  font-weight: var(--font-weight);\r\n}\r\n\r\n.service-list-container .service-list-item:hover {\r\n  background-color: var(--text-color-5);\r\n  cursor: pointer;\r\n}\r\n\r\n.service-item-content-footer {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-height: 60px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
