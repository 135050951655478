// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-container {
  margin: 1.5rem 0.5rem;
}

.steps-container .step-item-container {
  display: flex;
  align-items: center;
  min-height: 80px;
  position: relative;
  opacity: 0.3;
}

.steps-container .step-item-container.active {
  opacity: 1;
}

.steps-container .step-item-container .icon-container {
  background-color: var(--primary-color-10);
  margin-right: 1rem;
}

.steps-container .step-item-container.active .icon-container {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.steps-container .step-item-container.active .icon-container span {
  color: var(--light-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Reviewers/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".steps-container {\n  margin: 1.5rem 0.5rem;\n}\n\n.steps-container .step-item-container {\n  display: flex;\n  align-items: center;\n  min-height: 80px;\n  position: relative;\n  opacity: 0.3;\n}\n\n.steps-container .step-item-container.active {\n  opacity: 1;\n}\n\n.steps-container .step-item-container .icon-container {\n  background-color: var(--primary-color-10);\n  margin-right: 1rem;\n}\n\n.steps-container .step-item-container.active .icon-container {\n  background-color: var(--primary-color);\n  color: var(--light-color);\n}\n\n.steps-container .step-item-container.active .icon-container span {\n  color: var(--light-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
