import { ON_SET_COUNT, ON_COUNT_REQUESTS_PER_TYPE } from "../actions/Request";

const initialState = {
  requestCounts: {},
  countPendingRequestsPerType: {
    individual: 0,
    institution: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_SET_COUNT:
      Object.keys(action.payload).forEach((key) => {
        state.requestCounts[key] = action.payload[key];
      });

      return {
        ...state,
        requestCounts: { ...state.requestCounts },
      };
    case ON_COUNT_REQUESTS_PER_TYPE:
      return {
        ...state,
        countPendingRequestsPerType: { ...action.payload },
      };
    default:
      return state;
  }
};
export default reducer;
