// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activities-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.activities-container .card-body {
  height: 295px;
  overflow-y: auto;
}

.home-analytics-count-container .card {
  margin-bottom: 1rem;
}

.home-analytics-count-container .card .count-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s;
}

.home-analytics-count-container .card .count-item:hover {
  opacity: 0.5;
}

.home-analytics-count-container .card .count-item .count {
  min-width: 50px;
}

.home-analytics-count-container .card .count-item .count-title-container {
  flex: 1 1;
  /* margin: 0 1rem; */
}

.activity-item-container {
  min-height: 45px;
  padding: 0.5rem;
}

.activities-container .activity-definition {
  margin: 0 0.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/HomeAnalytics/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".activities-container {\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.activities-container .card-body {\n  height: 295px;\n  overflow-y: auto;\n}\n\n.home-analytics-count-container .card {\n  margin-bottom: 1rem;\n}\n\n.home-analytics-count-container .card .count-item {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  opacity: 1;\n  transition: opacity 0.4s;\n}\n\n.home-analytics-count-container .card .count-item:hover {\n  opacity: 0.5;\n}\n\n.home-analytics-count-container .card .count-item .count {\n  min-width: 50px;\n}\n\n.home-analytics-count-container .card .count-item .count-title-container {\n  flex: 1;\n  /* margin: 0 1rem; */\n}\n\n.activity-item-container {\n  min-height: 45px;\n  padding: 0.5rem;\n}\n\n.activities-container .activity-definition {\n  margin: 0 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
