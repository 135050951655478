import language from "../language";

const API_ACCESS = "/api/access";
const EVENT_ACCESS = "api/event";

const serviceApi = `${API_ACCESS}/service`;
const accountApi = `${API_ACCESS}/account`;
const notificationApi = `${EVENT_ACCESS}/notification`;
const accessRoleApi = `${API_ACCESS}/accessRole`;
const departmentApi = `${API_ACCESS}/department`;
const positionApi = `${API_ACCESS}/position`;
const requestApi = `${API_ACCESS}/request`;
const alertApi = `${EVENT_ACCESS}/alert`;
const serviceCategoryApi = `${API_ACCESS}/serviceCategory`;

export const menus = (lng) => {
  return [
    {
      label: language[lng].services,
      value: "Services",
      paths: {
        _POST: [serviceApi],
        _GET: [serviceApi, notificationApi, accountApi],
        _PUT: [serviceApi],
        _DELETE: [serviceApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].service_categories,
      value: "Service Categories",
      paths: {
        _POST: [serviceCategoryApi],
        _GET: [serviceCategoryApi, notificationApi, accountApi],
        _PUT: [serviceCategoryApi],
        _DELETE: [serviceCategoryApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].accounts,
      value: "Accounts",
      paths: {
        _POST: [accountApi],
        _GET: [accessRoleApi, accountApi, departmentApi],
        _PUT: [accountApi],
        _DELETE: [accountApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].active_accounts,
      value: "Active Accounts",
      paths: {
        _POST: [accountApi],
        _GET: [accessRoleApi, accountApi, departmentApi],
        _PUT: [accountApi],
        _DELETE: [accountApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].inactive_accounts,
      value: "Inactive Accounts",
      paths: {
        _POST: [accountApi],
        _GET: [accessRoleApi, accountApi, departmentApi],
        _PUT: [accountApi],
        _DELETE: [accountApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].departments,
      value: "Departments",
      paths: {
        _POST: [departmentApi],
        _GET: [departmentApi],
        _PUT: [departmentApi],
        _DELETE: [departmentApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].job_positions,
      value: "Job Positions",
      paths: {
        _POST: [positionApi],
        _GET: [positionApi],
        _PUT: [positionApi],
        _DELETE: [positionApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].access_roles,
      value: "Access Roles",
      paths: {
        _POST: [accessRoleApi],
        _GET: [accessRoleApi],
        _PUT: [accessRoleApi],
        _DELETE: [accessRoleApi],
      },
      operations: [
        { label: "Create", value: "create" },
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
        {
          label: "Delete",
          value: "delete",
        },
      ],
    },
    {
      label: language[lng].requests,
      value: "Requests",
      paths: {
        _POST: [requestApi],
        _GET: [requestApi, serviceApi, notificationApi],
        _PUT: [requestApi],
        _DELETE: [],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Update", value: "update" },
      ],
    },
    {
      label: language[lng].alerts,
      value: "Alerts",
      paths: {
        _POST: [],
        _GET: [alertApi],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      label: language[lng].notifications,
      value: "Notifications",
      paths: {
        _POST: [],
        _GET: [notificationApi],
        _PUT: [notificationApi],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      label: language[lng].home,
      value: "Home",
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      label: language[lng].dashboard,
      value: "Dashboard",
      paths: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
  ];
};

export const operationsList = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  {
    label: "Delete",
    value: "delete",
  },
];
