import "./styles.css";

const ListItem = (props) => {
  return (
    <div className="list-item" onClick={props.onPress}>
      {props.image && (
        <div className="list-img-container">
          <img src={props.image} alt={props.name} />
        </div>
      )}
      {props.icon && (
        <div className={`icon-container mr-0-5 ${props.iconClassName}`}>
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
      )}
      <div className="content">
        <div>
          {props.title ? (
            <h3>{props.title}</h3>
          ) : (
            <span className="list-item-name">{props.name}</span>
          )}
          {props.items && props.items.length > 0 && (
            <div>
              {props.items.map((role, r) => {
                return (
                  <div className="d-flex gap-2 flex-grow-1" key={r}>
                    <div className="badge badge-default">
                      <span>{role}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {props.rightIcon && (
        <div className="list-right">
          <span className={`bx ${props.rightIcon}`}></span>
        </div>
      )}
    </div>
  );
};

export default ListItem;
