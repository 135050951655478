// const API_URL_IMAGE = `${process.env.REACT_APP_PSRP_URL}/userguide`;
const API_URL_IMAGE =
  "https://res.cloudinary.com/djjwt8ewl/image/upload/v1706958918/psrp/applicant-guide/";

export const data = {
  english: [
    {
      title: "Account Login",
      description:
        "User account of the system must provide their username and password for authentication. This credentials are the same as your current IPPIS credentials",
      listActions: [
        {
          title: "Navigate to https://services.mifotra.gov.rw/",
        },
        {
          title: "Enter Email Address and password",
        },
        {
          title: "Press Login button",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/user-login_zl4iso.png`,
    },
    {
      title: "User Account Registration",
      description:
        "Registering a user requires user employee ID and user first name and other information",
      listActions: [
        {
          title: "Navigate to https://services.mifotra.gov.rw/signup",
        },
        {
          title: "Choose document type: DEFAULT = IPPIS Employee ID",
        },
        {
          title: "Enter your first name",
        },
        {
          title: "Enter your employee ID",
        },
        {
          title: "Enter your password",
        },
        {
          title: "Press Register button",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/applicant-registration_kdaf9z.png`,
    },
    {
      title: "New Request Application",
      description: "To create a new service request ",
      listActions: [
        {
          title: "Login first",
        },
        {
          title: "Click on New Request button",
        },
        {
          title: "Search a service or choose service from the list",
        },
        {
          title: "Fill the required information",
        },
        {
          title: "Upload required documents",
        },
        {
          title: "Click Submit button",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/new_request_hqavxq.png`,
        `${API_URL_IMAGE}/choose_service_1_mr90ns.png`,
        `${API_URL_IMAGE}/fill_request_form_vc2jdz.png`,
      ],
    },
    {
      title: "Notifications",
      description: "All received notifications",
      listActions: [
        {
          title: "Login first",
        },
        {
          title: "SIDEMENUS: Click on Notification",
        },
        {
          title:
            "Unread Notification / new notifications or Read notifications",
        },
      ],
      imageUrl: [`${API_URL_IMAGE}/notifications_hhj4sb.png`],
    },
    {
      title: "Account settings",
      description: "",
      listActions: [
        {
          title: "Login first",
        },
        {
          title: "SIDEMENUS: Click on Settings",
        },
      ],
      imageUrl: [`${API_URL_IMAGE}/applicat-settings_sopfov.png`],
    },
  ],
};
