import language from "../language";

export const sizeLimits = [
  {
    label: "MB",
    value: "MB",
  },
  {
    label: "KB",
    value: "KB",
  },
];

export const fileExtensions = [
  {
    label: "PDF",
    value: "pdf",
  },
  {
    label: "DOC",
    value: "docs",
  },
  {
    label: "PNG",
    value: "png",
  },
];

export const input_requirements = (lng = "english") => {
  return [
    {
      label: language[lng].mandatory,
      value: "mandatory",
    },
    {
      label: language[lng].optional,
      value: "optional",
    },
  ];
};

export const common_input_types = (lng = "english") => {
  return [
    {
      label: language[lng].text_input,
      value: language[lng].text_input,
      type: "textInput",
    },
    {
      label: language[lng].multiple_line_text,
      value: "multipleLineText",
      type: "multipleLineText",
    },
    {
      label: language[lng].number,
      value: "number",
      type: "number",
    },
    {
      label: language[lng].multiple_choice,
      value: "multipleChoice",
      type: "multipleChoice",
    },
    {
      label: language[lng].date_time,
      value: language[lng].date_time,
      type: "datetime-local",
    },
    {
      label: language[lng].date,
      value: "date",
      type: "date",
    },
    {
      label: language[lng].time,
      value: "time",
      type: "time",
    },
    {
      label: "Levels",
      value: "levels",
      type: "integration",
      editable: false,
      integrationSettings: {
        apiUrl: "https://psrp.huzalabs.com/api/access/ippis/structure/levels",
        apiParams: "",
        apiMethod: "GET",
        dropdownFieldName: "name",
        dropdownFieldValue: "id",
        ippisToken: true,
      },
    },
    {
      label: "Scales",
      value: "scales",
      type: "integration",
      integrationSettings: {
        apiUrl: "https://psrp.huzalabs.com/api/access/ippis/structure/scales",
        apiParams: "",
        apiMethod: "GET",
        dropdownFieldName: "name",
        dropdownFieldValue: "id",
        ippisToken: true,
      },
    },
    {
      label: "Payroll Group",
      value: "payrollGroup",
      type: "integration",
      integrationSettings: {
        apiUrl:
          "https://psrp.huzalabs.com/api/access/ippis/payroll/payroll-groups",
        apiParams:
          '{"paramName":"payrollGroup","paramValue":"ippisUserData.userEntitySectors","paramValueType":"array","paramValueField":"entitySectorId"}',
        apiMethod: "GET",
        dropdownFieldName: "name",
        dropdownFieldValue: "id",
        ippisToken: true,
      },
    },
    {
      label: "Entity Sector Unity",
      value: "entitySectorUnity",
      type: "integration",
      integrationSettings: {
        apiUrl:
          "https://psrp.huzalabs.com/api/access/ippis/structure/entity-sector-units",
        apiParams:
          '{"paramName":"sectorUnit","paramValue":"ippisUserData.userEntitySectors","paramValueType":"array","paramValueField":"entitySectorId"}',
        apiMethod: "GET",
        dropdownFieldName: "unitName",
        dropdownFieldValue: "unitId",
        ippisToken: true,
      },
    },
    {
      label: "Source of Funds",
      value: "sourceOfFunds",
      type: "integration",
      integrationSettings: {
        apiUrl:
          "https://psrp.huzalabs.com/api/access/ippis/payroll/source-of-funds",
        apiParams:
          '{"paramName":"sourceOfFund","paramValue":"ippisUserData.userEntitySectors","paramValueType":"array","paramValueField":"entitySectorId"}',
        apiMethod: "GET",
        dropdownFieldName: "name",
        dropdownFieldValue: "id",
        ippisToken: true,
      },
    },
    {
      label: "Cost Center",
      value: "costCenter",
      type: "integration",
      integrationSettings: {
        apiUrl:
          "https://psrp.huzalabs.com/api/access/ippis/payroll/cost-Centers",
        apiParams:
          '{"paramName":"costCenter","paramValue":"ippisUserData.userEntitySectors","paramValueType":"array","paramValueField":"entitySectorId"}',
        apiMethod: "GET",
        dropdownFieldName: "name",
        dropdownFieldValue: "id",
        ippisToken: true,
      },
    },
    {
      label: language[lng].integration,
      value: "integration",
      type: "integration",
      query: "integration",
    },
  ];
};

export const input_types = (lng = "english") => {
  return [
    {
      label: "Text",
      value: "text",
    },
  ];
};
