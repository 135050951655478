export const ON_SET_COUNT = "ON_SET_COUNT";
export const ON_COUNT_REQUESTS_PER_TYPE = "ON_COUNT_REQUESTS_PER_TYPE";

export const onSetRequestCount = (payload) => {
  return {
    type: ON_SET_COUNT,
    payload,
  };
};

export const onCountRequestPerType = (payload) => {
  return {
    type: ON_COUNT_REQUESTS_PER_TYPE,
    payload,
  };
};
