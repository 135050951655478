import React from "react";
import { UserGuidePublic } from "../../components/UserGuide";

class HomeScreen extends React.Component {
  render() {
    return <UserGuidePublic />;
  }
}

export default HomeScreen;
