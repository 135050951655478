import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import { Modal } from "../Modal";
import { AttachmentPreview } from ".";
import AttachmentItem from "./AttachmentItem";

class Attachments extends React.Component {
  state = {
    attachmentData: {},
  };

  handleShowModal(attachmentData) {
    this.setState({
      attachmentData: attachmentData,
      showAttachmentModal: true,
    });
  }

  handleCloseModal() {
    this.setState({ showAttachmentModal: false });
  }

  render() {
    return (
      <>
        <div className="attachment-container">
          <div className=" gap-2 d-flex flex-wrap">
            {this.props?.data?.map((el, i) => {
              const extension =
                el?.answer
                  ?.substr(el?.answer.lastIndexOf(".") + 1)
                  ?.toLowerCase() || "";

              const url = process.env.REACT_APP_PSRP_URL + "/" + el.answer;

              if (el?.answerType === "file") {
                return (
                  <div key={i}>
                    <AttachmentItem
                      extension={extension}
                      name={el.questionText}
                      url={url}
                      showDownload
                      showLarge
                      handleShowModal={() =>
                        this.handleShowModal({ url, extension })
                      }
                      showFullscreen
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentModal")}
          show={this.state.showAttachmentModal}
        >
          <AttachmentPreview {...this.state.attachmentData} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Attachments);
