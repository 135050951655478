import React from "react";
import { connect } from "react-redux";
import { Input, Select, Switch } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { menus } from "../../constants/accessRolesList";
import availableOptions from "../../constants/availableOptions";
import { getStorage } from "../../utils/storage";
import Permissions from "./Permissions";
import icons from "../../constants/icons";
import apiError from "../../utils/apiError";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class NewAccessRole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      error: {},
      isSubmitting: false,
      operationMenus: {},
      menus: menus(props.defaultLanguage),
      available: availableOptions[0],
      ippisRoleCodes: "",
      accountType: "",
      outsideOrganization: false,
    };
  }

  componentDidMount() {
    if (this.props._id) {
      let { menus, selectedMenus = [], operationMenus = {} } = this.state;

      for (let menu of menus) {
        if (this.props?.permissions?.menus?.includes(menu.value)) {
          selectedMenus.push(menu);
        }
        operationMenus = this.props?.permissions?.operationMenus;
      }

      this.setState({
        _id: this.props._id,
        name: this.props.name,
        ippisRoleCodes: this.props.ippisRoleCodes,
        outsideOrganization: this.props.outsideOrganization,
        available: availableOptions.find(
          (el) => el.value === this.props.available + ""
        ),
        selectedMenus,
        operationMenus,
      });
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  handleCheckOperations(menu, operation) {
    let { operationMenus } = this.state;

    if (!operationMenus[operation]) {
      operationMenus[operation] = [];
    }

    if (operationMenus[operation].includes(menu)) {
      operationMenus[operation].splice(
        operationMenus[operation].indexOf(menu),
        1
      );
    } else {
      operationMenus[operation].push(menu);
    }

    this.setState({ operationMenus });
  }

  validateForm() {
    let { name, error, selectedMenus, ippisRoleCodes } = this.state;

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].access_role_name_required;
    }

    if (!ippisRoleCodes || ippisRoleCodes === "") {
      error.ippisRoleCodes =
        language[this.props.defaultLanguage].ippis_role_code_required;
    }

    if (!selectedMenus) {
      error.selectedMenus =
        language[this.props.defaultLanguage].access_role_menus_required;
    }
    console.log(error);
    this.setState({ error });
  }

  onCheck() {
    this.setState({
      outsideOrganization: !this.state.outsideOrganization,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { add_access_role_success, update_access_role_success } =
      language[this.props.defaultLanguage];

    const {
      selectedMenus,
      operationMenus,
      name,
      _id,
      available,
      ippisRoleCodes,
      outsideOrganization,
    } = this.state;

    const user = await getStorage();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let menus = selectedMenus.map((el) => {
        return el.value;
      });

      let operationPaths = {};

      selectedMenus.forEach((el) => {
        Object.keys(el.paths).forEach((key) => {
          if (!el.paths[key]) {
            el.paths[key] = [];
          }

          el.paths[key].forEach((path) => {
            if (!operationPaths[key]) {
              operationPaths[key] = [];
            }
            if (!operationPaths[key].includes(path)) {
              operationPaths[key].push(path);
            }
          });
        });
      });

      let ippisRoleCodesArray = ippisRoleCodes;

      if (typeof ippisRoleCodes === "string") {
        ippisRoleCodesArray = ippisRoleCodes.split(",");
      }

      let requestBody = {
        name: name.toUpperCase(),
        ippisRoleCodes: ippisRoleCodesArray,
        permissions: {
          menus,
          operationMenus,
          operationPaths,
        },
        institution: user.institution,
        available: available.value,
        outsideOrganization,
      };

      let url = `${API_URL}/accessRole`,
        method = "POST";

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + user.token,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? update_access_role_success
              : add_access_role_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          const {
            error_update_access_role,
            error_add_access_role,
            access_role_exist,
          } = language[this.props.defaultLanguage];

          this.setState({
            isSubmitting: false,
          });

          apiError(error, {
            requestMethod: method,
            addErrorMessage: error_add_access_role,
            updateErrorMessage: error_update_access_role,
            dataExistErrorMessage: access_role_exist,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <Input
            leftIcon={icons.role}
            placeholder={
              language[this.props.defaultLanguage].access_role_name_placeholder
            }
            label={language[this.props.defaultLanguage].access_role}
            required
            value={this.state.name}
            onChange={(e) => this.onChangeText("name", e)}
            error={this.state.error.name}
          />
          <Input
            leftIcon={icons.user}
            placeholder={
              language[this.props.defaultLanguage].ippis_role_code_placeholder
            }
            label={language[this.props.defaultLanguage].ippis_role_code}
            required
            value={this.state.ippisRoleCodes}
            onChange={(e) => this.onChangeText("ippisRoleCodes", e)}
            error={this.state.error.ippisRoleCodes}
          />
          <Select
            leftIcon={icons.menu}
            options={this.state.menus}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].menus}
            required
            value={this.state.selectedMenus}
            onChange={(e) => this.onChangeText("selectedMenus", e)}
            error={this.state.error.selectedMenus}
            isMulti
          />
          <div
            className="row"
            style={{
              backgroundColor: "#f9f9f9",
              padding: "1rem 0",
              maxHeight: "40vh",
              overflowY: "auto",
            }}
          >
            {this.state?.selectedMenus?.map((menu, m) => {
              return (
                <>
                  <div className="col-md-6" key={m}>
                    <div className="card" style={{ marginBottom: "1rem" }}>
                      <Permissions
                        title={menu.label}
                        key={m}
                        operationMenus={this.state.operationMenus}
                        operations={menu.operations}
                        menuItem={menu.value}
                        handleCheckOperations={this.handleCheckOperations.bind(
                          this
                        )}
                      />
                    </div>
                  </div>
                  {m % 2 === 0 && (
                    <div className="clearfix visible-xs visible-sm"></div>
                  )}
                </>
              );
            })}
          </div>
          <div className="col-md-12">
            <Switch
              checked={this.state.outsideOrganization}
              onCheck={this.onCheck.bind(this)}
              title={
                language[this.props.defaultLanguage].outside_organization_title
              }
              description={
                language[this.props.defaultLanguage]
                  .outside_organization_description
              }
            />
          </div>
          <Select
            leftIcon={icons.status}
            options={availableOptions}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].available}
            required
            value={this.state.available}
            onChange={(e) => this.onChangeText("available", e)}
            error={this.state.error.available}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewAccessRole);
