import React from "react";
import { withRouter } from "../../../utils/withRouter";
import { SingleRequest } from "../../../components/SingleRequest";
import RequestLayout from "../../../Layout/RequestLayout";

class ViewSingleRequestScreen extends React.Component {
  render() {
    return (
      <RequestLayout>
        <SingleRequest requestId={this.props?.routeParams?.requestId} />
      </RequestLayout>
    );
  }
}

export default withRouter(ViewSingleRequestScreen);
