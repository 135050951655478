import React from "react";
import ServiceItem from "./ServiceItem";
import "./styles.css";
import { Link } from "react-router-dom";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";

class Services extends React.Component {
  state = {
    start_i: 0,
    end_i: 4,
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowMoreServices(category, length) {
    if (!this.state[category]) {
      this.setState({
        [category]: {
          limit: 8,
        },
      });
    } else if (length > this.state[category].limit) {
      this.setState({
        [category]: {
          limit: this.state[category].limit + 4,
        },
      });
    }
  }

  handleShowLessServices(category, length) {
    if (this.state[category].limit <= 4) {
      this.setState({
        [category]: {
          limit: 4,
        },
      });
    } else if (length <= this.state[category].limit) {
      this.setState({
        [category]: {
          limit: this.state[category].limit - 4,
        },
      });
    }
  }

  render() {
    return (
      <div className="row">
        {Object.keys(this.props.data || {}).map((el, i) => {
          return (
            <div className="col-sm-12 col-md-4" key={i}>
              <h3 className="service-item-title">{el}</h3>
              {Object.keys(this.props.data[el])
                .slice(0, this.state[el]?.limit || 4)
                .map((service, s) => {
                  return (
                    <ServiceItem
                      user={this.state.user}
                      key={s}
                      {...this.props.data[el][service]}
                    />
                  );
                })}
              {Object.keys(this.props.data[el]).length > 4 && (
                <div className="more-results">
                  {(!this.state[el]?.limit ||
                    this.state[el]?.limit <=
                      Object.keys(this.props.data[el]).length) && (
                    <Link
                      to="#"
                      onClick={() =>
                        this.handleShowMoreServices(
                          el,
                          Object.keys(this.props.data[el]).length
                        )
                      }
                    >
                      More
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {icons.chevronDown}
                      </span>
                    </Link>
                  )}

                  {Object.keys(this.props.data[el]).length <=
                    this.state[el]?.limit && (
                    <Link
                      to="#"
                      onClick={() =>
                        this.handleShowLessServices(
                          el,
                          Object.keys(this.props.data[el]).length
                        )
                      }
                    >
                      Less
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {icons.chevronUp}
                      </span>
                    </Link>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Services;
