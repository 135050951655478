import { Sectors } from "rwanda";

export default (options) => {
  try {
    if (!options || !options instanceof Array || options?.length === 0) return;
    return Sectors(...options)?.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
  } catch (error) {
    console.log("Error while getting sectors", error);
  }
};
