import { Districts } from "rwanda";

export default (province) => {
  if (!province) return;
  return Districts(province).map((el) => {
    return {
      label: el,
      value: el,
    };
  });
};
