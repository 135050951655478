import { combineReducers } from "redux";
import Language from "./Language";
import LandingCategoryTab from "./LandingCategoryTab";
import Services from "./Services";
import Notifications from "./Notifications";
import Filters from "./Filters";
import Request from "./Request";

const appReducer = combineReducers({
  Language,
  LandingCategoryTab,
  Services,
  Notifications,
  Filters,
  Request,
});

const rootReducer = (state, action) => {
  if (action.type === "ON_USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
