import React from "react";
import { ConfirmationModal } from "../Modal";
import language from "../../language";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import { connect } from "react-redux";
import { getStorage } from "../../utils/storage";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

class Reject extends React.Component {
  state = {
    title: "",
    description: "",
    success: false,
    isSubmitting: false,
    reason: "",
  };

  componentDidMount() {
    this.setState({
      title: "Reject Request",
      description: "Are you sure you want to reject this request?",
      success: false,
    });
  }

  onChangeText(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  onApprove = async () => {
    const { requestId, request, comment, approver, file } = this.props;
    const user = await getStorage();

    const findApprover =
      (approver.account && approver.account === user.id) ||
      (!approver.account &&
        approver.accessRole &&
        approver.accessRole._id === user.accessRole._id);

    let status = "rejected";
    let subApprovalStatus = request.subApprovalStatus || undefined;
    let countApproved = 0;
    const formData = new FormData();

    if (!requestId || requestId === "")
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_find_request
      );

    if (!findApprover)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].not_allowed_approve_request
      );

    this.setState({ isSubmitting: true });

    const updatedApprovals = request.subApprovals.map((el, i) => {
      if (
        (el.account && el.account._id === user.id) ||
        (!el.account &&
          el.accessRole &&
          el.accessRole?._id === user.accessRole._id)
      ) {
        if (!el.account && el.level === approver.level) {
          el.account = user.id;
        } else {
          el.account = el?.account?._id || el.account;
        }

        if (el.accessRole) {
          el.accessRole = el?.accessRole?._id;
        }

        el.action = "Rejection";
        el.actionAt = moment().format("YYYY-MM-DD hh:mm:ss");

        if (comment && comment !== "") {
          el["actionComment"] = comment;
        }

        if (file && file !== "") {
          let { questionnaireAction = [] } = el;

          questionnaireAction.push({
            questionText: file.name,
            answerType: "file",
            duplicateId: uuidv4(),
          });

          formData.append(
            `subApprovals[${i}].questionnaireAction[${
              questionnaireAction.length - 1
            }].answer`,
            file
          );
        }
      }

      if (el.actionAt && el.actionAt !== "") {
        //check if is the last approval
        countApproved += 1;
      }
      return el;
    });

    if (countApproved === request.subApprovals.length) {
      subApprovalStatus = "rejected";
    }

    formData.append("id", requestId);
    formData.append("status", status);
    formData.append("subApprovalStatus", subApprovalStatus);
    formData.append("subApprovals", JSON.stringify(updatedApprovals));

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_PSRP_BASE_API}/request`,
      data: formData,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
          success: true,
          title: "Rejected",
          description: "Request Rejected successfully",
        });

        this.props.getData();
        this.props.resetFile();
      })
      .catch((error) => {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].unable_to_approve_request
        );
        this.setState({
          isSubmitting: false,
        });
      });
  };

  render() {
    return (
      <ConfirmationModal
        icon={this.state.success ? icons.done : icons.waiting}
        success={this.state.success}
        title={this.state.title}
        description={this.state.description}
        positiveButtonText="YES"
        negativeButtonText="NO"
        onPressNegative={this.props.handleCloseModal}
        onPressPositive={this.onApprove.bind(this)}
        isPositiveSubmitting={this.state.isSubmitting}
        handleCloseModal={this.props.handleCloseModal}
        showOptions
        onChangeText={this.onChangeText.bind(this)}
        comment_label={language[this.props.defaultLanguage].comment}
        comment_placeholder={
          language[this.props.defaultLanguage].approved_reason_placeholder
        }
        reason={this.state.reason}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Reject);
