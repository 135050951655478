// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-shadow {
  border: 0px solid var(--text-color-10);
  box-shadow: var(--card-shadow);
}

.card {
  border: 0;
  box-shadow: var(--card-shadow);
}

.card .card-header {
  background-color: var(--light-color);
  border-bottom: 0;
}

.card-footer {
  background-color: var(--primary-color-5);
  border-top: 0;
}

.card-shadow .card-header {
  padding: 1rem;
  /* background-color: var(--primary-color-5); */
  border-bottom: 0;
  border-bottom: 1px solid var(--text-color-10);
}

.panel-default {
  background-color: var(--primary-color-5);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.card-action-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-action-btn .icon-container {
  opacity: 0.5;
}

.card-action-btn .icon-container:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/card.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8CAA8C;EAC9C,gBAAgB;EAChB,6CAA6C;AAC/C;;AAEA;EACE,wCAAwC;EACxC,mCAAmC;EACnC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".card-shadow {\r\n  border: 0px solid var(--text-color-10);\r\n  box-shadow: var(--card-shadow);\r\n}\r\n\r\n.card {\r\n  border: 0;\r\n  box-shadow: var(--card-shadow);\r\n}\r\n\r\n.card .card-header {\r\n  background-color: var(--light-color);\r\n  border-bottom: 0;\r\n}\r\n\r\n.card-footer {\r\n  background-color: var(--primary-color-5);\r\n  border-top: 0;\r\n}\r\n\r\n.card-shadow .card-header {\r\n  padding: 1rem;\r\n  /* background-color: var(--primary-color-5); */\r\n  border-bottom: 0;\r\n  border-bottom: 1px solid var(--text-color-10);\r\n}\r\n\r\n.panel-default {\r\n  background-color: var(--primary-color-5);\r\n  border-radius: var(--border-radius);\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.card-action-btn {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n\r\n.card-action-btn .icon-container {\r\n  opacity: 0.5;\r\n}\r\n\r\n.card-action-btn .icon-container:hover {\r\n  opacity: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
