import { connect } from "react-redux";
import "./styles.css";
import language from "../../language";
import icons from "../../constants/icons";
import moment from "moment";
import React from "react";
import notificationQuery from "../../utils/queries/notificationQuery";
import { getStorage } from "../../utils/storage";
import socket from "../../utils/socket/connection";
import { UserSMProfile } from "../User";

class ReviewersLogs extends React.Component {
  state = {
    isLoadingReviewedStatus: false,
    notification: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);

    socket.on("notification", async () => {
      this.getData(true);
    });
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, data: requestInfo } = this.props;

      this.setState({ isLoading });

      let paramsData = {
        lng: defaultLanguage,
        ["body._id"]: requestInfo._id,
      };

      const data = await notificationQuery(this, paramsData);

      this.setState({
        notification: data,
        isLoadingReviewedStatus: false,
      });
    } catch (error) {
      this.setState({
        isLoadingReviewedStatus: false,
      });
    }
  };

  handleActiveStep({ type, reviewer }) {
    let isActive = false;

    if (
      type === "approve" &&
      reviewer.actionAt !== "" &&
      (reviewer.action === "Approval" || reviewer.action === "Approved")
    ) {
      isActive = true;
    }

    if (
      type === "reject" &&
      reviewer.actionAt !== "" &&
      (reviewer.action === "Rejection" || reviewer.action === "Rejected")
    ) {
      isActive = true;
    }

    return isActive;
  }

  renderProfile(info) {
    const steps = [
      // {
      //   name: language[this.props.defaultLanguage].reviewed,
      //   icon: icons.eye_on,
      //   date: moment().format("lll"),
      //   type: "review",
      // },
      {
        name: language[this.props.defaultLanguage].approved,
        icon: icons.success,
        date: moment().format("lll"),
        type: "approve",
      },
      {
        name: language[this.props.defaultLanguage].rejected,
        icon: icons.fail,
        date: moment().format("lll"),
        type: "reject",
      },
    ];
    return (
      <div>
        <UserSMProfile {...info} />
        <div className="steps-container">
          {steps.map((elStep, stepIndex) => {
            const isActive = this.handleActiveStep({
              type: elStep.type,
              reviewer: info,
            });

            // if (elStep.status === "active") {
            return (
              <div
                className={`step-item-container ${isActive ? "active" : ""}`}
                key={stepIndex}
              >
                <div className="icon-container">
                  <span className={process.env.REACT_APP_ICONS_TYPE}>
                    {elStep.icon}
                  </span>
                </div>
                <div className="step-item-name">
                  <p
                    className={`text-bold ${
                      elStep.status === "active" && "text-primary"
                    }`}
                  >
                    {elStep.name}
                  </p>
                  {isActive && (
                    <span>{moment(info.actionAt).format("lll")}</span>
                  )}
                </div>
              </div>
            );
            // }
          })}
        </div>

        {info.index + 1 !== this.props.data.length && <hr />}
      </div>
    );
  }

  render() {
    return (
      <div className="reviewers-container">
        {this?.props?.data?.map((reviewer, i) => {
          if (reviewer.account) {
            return (
              <div key={i}>
                {this.renderProfile({
                  index: i,
                  ...reviewer,
                  firstName: reviewer.account?.username,
                  department: reviewer.account?.department,
                  position: reviewer.account?.position,
                  institution: reviewer.account?.institution,
                })}
              </div>
            );
          } else if (reviewer.accessRole) {
            return (
              <div key={i}>
                {this.renderProfile({
                  index: i,
                  firstName: reviewer.accessRole?.name,
                })}
              </div>
            );
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ReviewersLogs);
