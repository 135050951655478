import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import departmentQuery from "./departmentQuery";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

const fetchData = async (
  lng = "english",
  { page, limit, type, department, positionName }
) => {
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Job Positions",
      operation: "read",
    });

    if (!canRead) return;

    let params = {
      page,
      limit,
      department,
      name: positionName,
      institution: user.institution,
    };

    const options = {
      method: "GET",
      url: `${API_URL}/position`,
      params,
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    };

    console.log(options);

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_positions);
  }
};

export default fetchData;

export const deletePosition = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/position`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_position);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_position);
  }
};

export const addPosition = async (lng, { position, department }) => {
  try {
    let url = `${API_URL}/position`,
      user = await getStorage();

    const departments = await departmentQuery(lng, {
      name: department,
    });

    if (!departments || departments?.length === 0) {
      return;
    }

    let requestBody = {
      name: position,
      department: departments[0]._id,
      institution: user.institution,
    };

    const options = {
      method: "POST",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: requestBody,
    };

    return await axios(options);
  } catch (error) {
    toastMessage("error", language[lng].unable_add_position);
  }
};
