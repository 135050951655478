import React, { Component } from "react";
import { getStorage } from "../../utils/storage";
import tokenDecoder from "../../utils/tokenDecoder";
import { Modal } from "../Modal";
import { connect } from "react-redux";
import { Login } from "../Login";
import AdminLogin from "../AdminLogin/Login";

class TokenExpirationChecker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginModal: false,
      user: {},
    };
  }

  // Simulated API call to check token expiration
  checkTokenExpiration = async () => {
    const { token, ippisToken } = await getStorage();
    const ippisApiToken = ippisToken ? tokenDecoder(ippisToken) : null;
    const psrpToken = tokenDecoder(token);

    if (!psrpToken) return false;

    if (ippisApiToken) {
      const ippisExpirationTime = ippisApiToken?.exp
        ? ippisApiToken.exp * 1000
        : 0; //into milliseconds

      return Date.now() < ippisExpirationTime;
    }

    const psrpExpirationTime = psrpToken.exp * 1000; //into milliseconds

    const isPSRPTokenValid = psrpToken.exp && Date.now() < psrpExpirationTime;

    return isPSRPTokenValid;
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    // Check token expiration when component mounts
    const isTokenValid = await this.checkTokenExpiration();
    if (!isTokenValid && !this.state.showLoginModal) {
      this.handleShowModal("showLoginModal");
    }
    // Set up a timer to check token expiration periodically
    this.tokenCheckInterval = setInterval(async () => {
      const isTokenValid = await this.checkTokenExpiration();
      if (isTokenValid) {
        this.handleCloseModal("showLoginModal");
      }
    }, 10000); // Check every 10 seconds, adjust as needed
  };

  componentWillUnmount = async () => {
    // Clear the interval when the component unmounts
    clearInterval(await this.tokenCheckInterval);

    // this.setState({ showLoginModal: false });
  };

  handleShowModal = (modal) => {
    this.setState({ [modal]: true });
  };

  handleCloseModal = (modal) => {
    this.setState({ [modal]: false });
  };

  render() {
    return (
      <div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLoginModal")}
          show={this.state.showLoginModal}
          title="Your Session Expired, Login"
        >
          {this.state.role === "user" || this.props.role === "user" ? (
            <Login
              redirectURL="refresh"
              styles={{ boxShadow: "none" }}
              formContainerSize={12}
            />
          ) : (
            <AdminLogin
              redirectURL="refresh"
              styles={{ boxShadow: "none" }}
              formContainerSize={12}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(TokenExpirationChecker);
