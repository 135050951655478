import { onAddNotification } from "../../../actions/Notification";
import {
  onCountRequestPerType,
  onSetRequestCount,
} from "../../../actions/Request";
import fetchData from "../../queries/notificationQuery";
import requestQuery from "../../queries/requestQuery";
import { getStorage } from "../../storage";
import socket from "../connection";

export const handleNotification = async (that) => {
  const user = await getStorage();

  // query notifications on app loads
  if (user.token) {
    await fetchData(that, { lng: that.props.defaultLanguage });
  }

  socket.on("notification", (data) => {
    const { approver, subApprover, body, _id, viewedBy } = data;

    if (
      (user.id === body?.user ||
        approver?.includes(user.id) ||
        subApprover?.includes(user.id)) &&
      body.status === "pending"
    ) {
      that.props.dispatch(
        onAddNotification({
          bodyID: body._id || _id,
          notificationID: _id,
          viewedBy,
        })
      );
    }
  });
};

export const fetchCountsForRequestSidebar = async (that, status) => {
  const user = await getStorage();
  const { filters = {} } = that.props;
  const { startDate, endDate } = filters;

  let startCreatedAt, endCreatedAt;

  if (!startCreatedAt) {
    startCreatedAt = new Date(startDate).getTime();
  }

  if (!endCreatedAt) {
    endCreatedAt = new Date(endDate).getTime();
  }

  if (user.role !== "user" && user.token && user.token !== "") {
    try {
      const { defaultLanguage } = that.props;

      const { count, data } = await requestQuery(defaultLanguage, {
        status,
        startCreatedAt,
        endCreatedAt,
      });

      const showCount = count === "many" ? "10+" : count;

      that.props.dispatch(onSetRequestCount({ [status]: showCount }));

      status?.toLowerCase() === "pending" &&
        countPendingRequestsPerType(that, { data, count, type: "individual" });

      status?.toLowerCase() === "pending" &&
        countPendingRequestsPerType(that, { data, count, type: "institution" });
    } catch (error) {
      console.log("Count request for sidebar failed: " + error);
    }
  }
};

const countPendingRequestsPerType = (that, { data, count }) => {
  let total = {};

  data.forEach((el) => {
    if (!total[el.category.type]) {
      total[el.category.type] = 0;
    }

    if (el.status.toLowerCase() === "pending") {
      total[el.category.type] += 1;
    }
  });

  that.props.dispatch(onCountRequestPerType(total));
};
