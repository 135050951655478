import { connect } from "react-redux";
import language from "../../../language";
import "./styles.css";

const LeftContent = (props) => {
  return (
    <div className="col-md-8 intro-text-container">
      <h1>
        {props.serviceTypeName ? (
          props.serviceTypeName
        ) : (
          <>
            {language[props.defaultLanguage].intro_title_01 + " "}
            <br /> {language[props.defaultLanguage].intro_title_02}
          </>
        )}
      </h1>
      <p>{language[props.defaultLanguage].intro_description}</p>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(LeftContent);
