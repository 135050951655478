import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import requestQuery from "../../utils/queries/requestQuery";
import handleSearch from "../../utils/handleSearch";

let copyData = [];

class QuestionnaireAnswer extends React.Component {
  state = {
    isLoading: false,
    data: {},
    page: 1,
    limit: 1,
    error: {},
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, requestId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(defaultLanguage, {
        page,
        limit,
        id: requestId,
      });

      let resData = {};

      if (data.length > 0 && data[0].questionnaireAnswer) {
        for (let el of data[0].questionnaireAnswer) {
          if (el.answerType !== "file") {
            if (!resData[el.duplicateId]) {
              resData[el.duplicateId] = [];
            }
            resData[el.duplicateId].push(el);
          }
        }
      }

      this.setState({
        data: resData,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].question,
        key: "questionText",
      },
      {
        title: language[this.props.defaultLanguage].answer,
        key: "answer",
      },
    ];

    return headers;
  }

  render() {
    return (
      <>
        {Object.keys(this.state.data).map((key) => {
          return (
            <>
              <Table
                data={this.state.data[key] || []}
                search_text={this.state.search_text}
                isLoading={this.state.isLoading}
                handlePagination={this.handlePagination.bind(this)}
                headers={this.returnTableHeaders()}
              />
            </>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(QuestionnaireAnswer);
