import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

const fetchData = async (lng = "english", params) => {
  try {
    const {
      services,
      page,
      limit,
      type,
      category,
      user,
      id,
      status,
      requestType,
      startCreatedAt,
      endCreatedAt,
      search,
    } = params;

    const out_of = language[lng].out_of;
    const userLoggedIn = await getStorage();
    const { token, institution, role, accessRole } = userLoggedIn;

    const servicesIds = services?.map((service) => service.value);

    let requestBody = {
      page: page || 1,
      limit: limit || 10,
      category,
      user,
      id,
      status,
      // institution,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
      service: servicesIds,
      search,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
      delete requestBody.institution;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/request`,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      data = formatSelectData(data, "description", "_id");
    }

    let requestsFormat = data.map((el) => {
      let totalApproved = 0;
      let admins = [...el?.approvals, ...el.subApprovals];

      el.totalApprover = admins?.length || 0;

      (admins || []).forEach((el) => {
        if (el.actionAt && el.actionAt !== "") {
          totalApproved += 1;
        }
      });

      el.numberOfApprovals = `${totalApproved} ${out_of} ${el.totalApprover}`;

      return {
        ...el,
        fromInstitution:
          el.fromInstitution instanceof Array
            ? el.fromInstitution[0]
            : el.fromInstitution,
      };
    });

    console.log("**** service info", requestsFormat);

    return { data: requestsFormat, count };
  } catch (error) {
    console.log("****", error);
    toastMessage("error", language[lng].unable_fetch_requests);
  }
};

export default fetchData;
