import React from "react";
import { UserGuideAdmin } from "../../components/UserGuide";

class AdminUserGuideScreen extends React.Component {
  render() {
    return <UserGuideAdmin />;
  }
}

export default AdminUserGuideScreen;
