import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import validatePassword from "../../utils/validatePassword";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;
class ChangePassword extends React.Component {
  state = {
    confirmPassword: "",
    password: "",
    error: {},
    otpIsSent: false,
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  validateRequestOtpForm() {
    let { error, password, confirmPassword } = this.state;
    let passwordValidation = validatePassword(
      this.props.defaultLanguage,
      password
    );
    let confirmPasswordValidation = validatePassword(
      this.props.defaultLanguage,
      confirmPassword
    );

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    } else if (passwordValidation) {
      error.password = passwordValidation;
    } else if (password !== confirmPassword) {
      error.confirmPassword =
        language[this.props.defaultLanguage].password_must_match;
    }

    if (confirmPassword === "") {
      error.confirmPassword =
        language[this.props.defaultLanguage].confirm_password_required;
    } else if (confirmPasswordValidation) {
      error.password = confirmPasswordValidation;
    }

    this.setState({ error });
  }

  sendOTPCode = async (e) => {
    e.preventDefault();
    await this.validateRequestOtpForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { user } = this.state;
      const { email, role } = user;
      let route = "/authentication/account";

      if (role === "admin") {
        route = "/authentication/admin";
      }

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_PSRP_BASE_API}${route}`,
        data: {
          email,
          isPasscode: true,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].email_has_been_sent_to +
              " " +
              email +
              " " +
              language[this.props.defaultLanguage].successfully
          );

          this.setState({ otpIsSent: true });
        })
        .catch((error) => {
          this.setState({ isSubmitting: false, otpIsSent: false });

          toastMessage(
            "error",
            this.state.email +
              ", " +
              language[this.props.defaultLanguage].account_nt_exist
          );
        });
    }
  };

  validateResetForm() {
    let { error, password, confirmPassword, otpCode } = this.state;

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    } else if (!validatePassword(password)) {
      error.password = language[this.props.defaultLanguage].invalid_password;
    } else if (password !== confirmPassword) {
      error.confirmPassword =
        language[this.props.defaultLanguage].password_must_match;
    }

    if (confirmPassword === "") {
      error.confirmPassword =
        language[this.props.defaultLanguage].confirm_password_required;
    } else if (!validatePassword(password)) {
      error.confirmPassword =
        language[this.props.defaultLanguage].invalid_password;
    }

    if (otpCode === "") {
      error.confirmPassword =
        language[this.props.defaultLanguage].otp_code_required;
    }

    this.setState({ error });
  }

  validatePasscode = async () => {
    const { otpCode, user } = this.state;
    const { email, role } = user;
    let route = "/authentication/account";

    if (role === "admin") {
      route = "/authentication/admin";
    }

    try {
      const options = {
        method: "POST",
        url: `${API_URL}${route}`,
        data: {
          email,
          passcode: otpCode,
        },
      };

      const data = await axios(options);

      return data.data;
    } catch (error) {
      return false;
    }
  };

  resetPassword = async (e) => {
    e.preventDefault();

    await this.validateResetForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { password, otpCode, user } = this.state;
      const { email } = user;

      const validatePasscode = await this.validatePasscode();

      if (!validatePasscode && !validatePasscode?.token) {
        this.setState({ isSubmitting: false });

        return toastMessage(
          "error",
          language[this.props.defaultLanguage].invalid_otp_code
        );
      }

      const options = {
        method: "PUT",
        url: `${API_URL}/account`,
        data: {
          password,
          passcode: otpCode,
          email,
        },
        headers: {
          Authorization: "Bearer " + validatePasscode.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          this.onSuccess(data.data);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].unable_reset_password
          );
        });
    }
  };

  render() {
    console.log(this.state.error);
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Input
              leftIcon={icons.password}
              placeholder={
                language[this.props.defaultLanguage].new_password_placeholder
              }
              label={language[this.props.defaultLanguage].new_password}
              required
              value={this.state.password}
              type="password"
              onChange={(e) => this.onChangeText("password", e)}
              error={this.state.error.password}
            />
            <Input
              leftIcon={icons.password}
              placeholder={
                language[this.props.defaultLanguage].new_password_placeholder
              }
              label={language[this.props.defaultLanguage].confirm_password}
              required
              value={this.state.confirmPassword}
              onChange={(e) => this.onChangeText("confirmPassword", e)}
              helperText={`${
                language[this.props.defaultLanguage]
                  .otp_code_will_be_sent_confirm_reset
              } (${this.state.user.email})`}
              error={this.state.error.confirmPassword}
            />
            {this.state.otpIsSent ? (
              <>
                <Input
                  leftIcon={icons.key}
                  placeholder={
                    language[this.props.defaultLanguage].enter_otp_code
                  }
                  label={language[this.props.defaultLanguage].otp_code}
                  required
                  value={this.state.otpCode}
                  onChange={(e) => this.onChangeText("otpCode", e)}
                  error={this.state.error.otpCode}
                />
                <Button
                  text={language[this.props.defaultLanguage].reset_password}
                  onPress={this.resetPassword.bind(this)}
                  isSubmitting={this.state.isSubmitting}
                />
              </>
            ) : (
              <Button
                text={
                  language[this.props.defaultLanguage].request_reset_password
                }
                onPress={this.sendOTPCode.bind(this)}
                isSubmitting={this.state.isSubmitting}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ChangePassword);
