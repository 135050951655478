import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import requestQuery from "../../utils/queries/requestQuery";
import handleSearch from "../../utils/handleSearch";
import icons from "../../constants/icons";

let copyData = [];

class Documents extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 1,
    search_text: "",
    selected_data: {},
    error: {},
    csvData: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, requestId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(defaultLanguage, {
        page,
        limit,
        id: requestId,
      });

      let resData = [];

      if (data.length > 0 && data[0].questionnaireAnswer) {
        for (let el of data[0].questionnaireAnswer) {
          if (el.answerType === "file") {
            resData.push(el);
          }
        }
      }

      this.setState({
        data: resData,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = resData?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].document,
        key: "questionText",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.download,
            title: "Download",
            onPress: (item) => {
              window.open(
                process.env.REACT_APP_PSRP_URL + "/" + item.answer,
                "_blank"
              );
            },
          },
        ],
      },
    ];

    return headers;
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data || []}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Documents);
