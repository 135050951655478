import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import serviceQuery from "../../utils/queries/serviceQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import { Loading } from "../Loader";
import { Empty } from "../Empty";
import { Input } from "../Input";
import "./styles.css";
import icons from "../../constants/icons";
import isHR from "../../utils/isHR";
import CustomPagination from "../Pagination/CustomPagination";

let copyData = [];

class Services extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
      });

      const newData = this.handleFilterServices(data);

      this.setState({
        data: newData,
        totalPageCount: count,
        isLoading: false,
        selectedCategory: {},
      });

      copyData = newData?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleFilterServices = (data) => {
    const { user } = this.state;
    let result = [];

    data.forEach((item) => {
      if (item.type === "individual") {
        result.push(item);
      } else if (item.type === "institution") {
        if (isHR(user)) {
          result.push(item);
        }
      }
    });

    return result;
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].service,
        key: "name",
      },
    ];

    return headers;
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    return (
      <div className="service-list-container">
        {this.state.isLoading ? (
          <div className="loader-container">
            <Loading />
          </div>
        ) : (
          <>
            <div
              className="card-body"
              style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            >
              <Input
                placeholder="Search service..."
                value={this.state.search_text}
                onChange={(e) =>
                  handleSearch(this, {
                    copyData,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="service-item-content">
              {this.state.data.length > 0 ? (
                this.state.data.map((el, i) => {
                  return (
                    <div
                      className="service-list-item"
                      key={i}
                      onClick={() =>
                        (window.location.href =
                          "/public-servant/request/" + el._id)
                      }
                    >
                      <div className="icon-container">
                        <span
                          className={`${process.env.REACT_APP_ICONS_TYPE} text-primary`}
                          style={{ marginRight: "0.5rem" }}
                        >
                          {icons.service}
                        </span>
                      </div>
                      <div className="name">
                        <div>
                          <span className="service-name">{el.name}</span>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="badge badge-default">
                          <span className="text-capitalize">{el.type}</span>
                        </div>
                        <span
                          className={process.env.REACT_APP_ICONS_TYPE}
                          style={{ opacity: 0.2 }}
                        >
                          {icons.chevronRight}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty title="No result found" />
              )}
            </div>
            <div className="service-item-content-footer">
              <CustomPagination
                data={this.state.data}
                page={this.state.page}
                totalPageCount={this.state.totalPageCount}
                limit={this.state.limit}
                handlePagination={this.handlePagination.bind(this)}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Services);
