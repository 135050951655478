import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import loginSlidesContent from "../../constants/loginSlidesContent";
import icons from "../../constants/icons";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class ResetPassword extends React.Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    isSubmitting: false,
    error: {},
    otpCode: "",
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    let { error, newPassword, confirmPassword, otpCode } = this.state;

    if (otpCode === "") {
      error.otpCode = language[this.props.defaultLanguage].otp_code_required;
    }

    if (newPassword === "") {
      error.newPassword =
        language[this.props.defaultLanguage].new_password_required;
    }

    if (confirmPassword === "") {
      error.confirmPassword =
        language[this.props.defaultLanguage].confirm_password_required;
    } else if (confirmPassword !== newPassword) {
      error.confirmPassword =
        language[this.props.defaultLanguage].different_password_required;
    }

    this.setState({ error });
  };

  validatePasscode = async () => {
    const { newPassword, otpCode } = this.state;
    const { email } = this.props;
    try {
      const options = {
        method: "POST",
        url: `${API_URL}/authentication/admin`,
        data: {
          email,
          passcode: otpCode,
        },
      };

      const data = await axios(options);

      return data.data;
    } catch (error) {
      return false;
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { newPassword, otpCode } = this.state;
      const { email } = this.props;

      const validatePasscode = await this.validatePasscode();

      if (!validatePasscode && !validatePasscode?.token) {
        this.setState({ isSubmitting: false });

        return toastMessage(
          "error",
          language[this.props.defaultLanguage].invalid_otp_code
        );
      }

      const options = {
        method: "PUT",
        url: `${API_URL}/account`,
        data: {
          password: newPassword,
          passcode: otpCode,
          email,
        },
        headers: {
          Authorization: "Bearer " + validatePasscode.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          this.onSuccess(data.data);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].unable_reset_password
          );
        });
    }
  };

  onSuccess = async () => {
    toastMessage(
      "success",
      language[this.props.defaultLanguage].reset_password_success
    );
    window.location.href = "/login";
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides data={loginSlidesContent} viewSize={1} />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {language[this.props.defaultLanguage].reset_password_title}
                </h3>
              </div>
              <form>
                <Input
                  label={
                    language[this.props.defaultLanguage].otp_code_six_digits
                  }
                  placeholder={
                    language[this.props.defaultLanguage].enter_otp_code
                  }
                  required
                  leftIcon={icons.passcode}
                  value={this.state.otpCode}
                  error={this.state.error.otpCode}
                  onChange={(e) => this.onChangeText("otpCode", e)}
                  helperText={
                    language[this.props.defaultLanguage]
                      .enter_otp_code_received_to_email +
                    " " +
                    this.props.email
                  }
                  type="number"
                />

                <Input
                  label={language[this.props.defaultLanguage].new_password}
                  placeholder={
                    language[this.props.defaultLanguage]
                      .new_password_placeholder
                  }
                  type="password"
                  required
                  leftIcon={icons.password}
                  value={this.state.newPassword}
                  error={this.state.error.newPassword}
                  onChange={(e) => this.onChangeText("newPassword", e)}
                />
                <Input
                  label={language[this.props.defaultLanguage].new_password}
                  placeholder={
                    language[this.props.defaultLanguage]
                      .confirm_password_placeholder
                  }
                  type="password"
                  required
                  leftIcon={icons.password}
                  value={this.state.confirmPassword}
                  error={this.state.error.confirmPassword}
                  onChange={(e) => this.onChangeText("confirmPassword", e)}
                />
                <Button
                  text={language[this.props.defaultLanguage].reset_password}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <span>
                    <Link to="/forgot-password">
                      {language[this.props.defaultLanguage].back_text}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ResetPassword);
