import { Input } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";

const IntegrationForm = (props) => {
  console.log("Integration error ********", props.error);

  let separator = "";
  if (props.index !== undefined) {
    separator = "_" + props.index;
  }

  return (
    <div>
      <div className="separator-container">
        <h3>Integration Settings</h3>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Input
            label={language[props.defaultLanguage].api_url}
            placeholder={language[props.defaultLanguage].api_url_placeholder}
            value={props.apiUrl}
            onChange={(e) => props.onChangeInput("apiUrl", e)}
            required
            error={props.error["apiUrl" + separator]}
          />
        </div>
        <div className="col-md-4">
          <Input
            label={language[props.defaultLanguage].dropdown_field_name}
            placeholder={
              language[props.defaultLanguage].dropdown_field_name_placeholder
            }
            value={props.dropdownFieldName}
            onChange={(e) => props.onChangeInput("dropdownFieldName", e)}
            required
            error={props.error["dropdownFieldName" + separator]}
          />
        </div>
        <div className="col-md-4">
          <Input
            label={language[props.defaultLanguage].dropdown_field_value}
            placeholder={
              language[props.defaultLanguage].dropdown_field_value_placeholder
            }
            value={props.dropdownFieldValue}
            onChange={(e) => props.onChangeInput("dropdownFieldValue", e)}
            required
            error={props.error["dropdownFieldValue" + separator]}
          />
        </div>
        <div className="col-md-4">
          <Input
            label={language[props.defaultLanguage].api_method}
            placeholder={language[props.defaultLanguage].api_method_placeholder}
            value={props.apiMethod}
            onChange={(e) => props.onChangeInput("apiMethod", e)}
            required
            error={props.error["apiMethod" + separator]}
          />
        </div>
        <div className="col-md-4">
          <Input
            label={language[props.defaultLanguage].api_params}
            placeholder={language[props.defaultLanguage].api_params_placeholder}
            value={props.apiParams}
            onChange={(e) => props.onChangeInput("apiParams", e)}
            required
            error={props.error["apiParams" + separator]}
            helperText={language[props.defaultLanguage].api_params_description}
            textarea
          />
        </div>
        <div className="col-md-4">
          <Input
            label={language[props.defaultLanguage].response_key_name}
            placeholder={
              language[props.defaultLanguage].response_key_name_placeholder
            }
            value={props.responseKey}
            onChange={(e) => props.onChangeInput("responseKey", e)}
            required
            error={props.error["responseKey" + separator]}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(IntegrationForm);
