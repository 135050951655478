import DashboardScreen from "../screens/AdminDashboard/DashboardScreen";
import HomeScreen from "../screens/AdminDashboard/HomeScreen";
import NotificationScreen from "../screens/common/NotificationScreen";
import RequestScreen from "../screens/AdminDashboard/Requests/RequestScreen";
import ServiceScreen from "../screens/AdminDashboard/Services/ServiceScreen";
import AlertScreen from "../screens/AdminDashboard/AlertScreen";
import DepartmentScreen from "../screens/AdminDashboard/Department/DepartmentScreen";
import AccountScreen from "../screens/AdminDashboard/AccountScreen";
import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import language from "../language";
import icons from "./icons";

const data = (lng = "english") => {
  try {
    const userLoggedIn = JSON.parse(
      sessionStorage.getItem(process.env.REACT_APP_NAME) || {}
    );

    const { accessRole = {}, role } = userLoggedIn;
    const { permissions = {} } = accessRole;
    const { menus = [] } = permissions;

    let sidebarList = [
      {
        parentPath: "/dashboard",
        path: "/home",
        name: language[lng].home,
        icon: icons.home,
        component: <HomeScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/dashboard",
        name: language[lng].dashboard,
        icon: icons.dashboard,
        component: <DashboardScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/requests",
        name: language[lng].requests,
        icon: icons.request,
        component: <RequestScreen />,
        menus: [
          {
            path: "/pending",
            name: language[lng].pending,
            component: <RequestScreen status="pending" />,
            showNotification: true,
            type: "pending",
          },
          {
            path: "/approved",
            name: language[lng].approved,
            component: <RequestScreen status="approved" />,
            showNotification: true,
            type: "approved",
          },
          {
            path: "/rejected",
            name: language[lng].rejected,
            component: <RequestScreen status="rejected" />,
            showNotification: true,
            type: "rejected",
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/services",
        name: language[lng].services,
        icon: icons.service,
        component: <ServiceScreen />,
        menus: [
          {
            path: "/individuals",
            name: language[lng].individuals,
            component: <ServiceScreen category="individual" />,
          },
          {
            path: "/institutions",
            name: language[lng].institutions,
            component: <ServiceScreen category="institution" />,
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/notifications",
        name: language[lng].notifications,
        icon: icons.notification,
        component: <NotificationScreen />,
        showNotification: true,
        notificationStorageName: "notifications",
      },
      {
        parentPath: "/dashboard",
        path: "/alerts",
        name: language[lng].alerts,
        icon: icons.alert,
        component: <AlertScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/departments",
        name: language[lng].departments,
        icon: icons.department,
        component: <DepartmentScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/accounts",
        name: language[lng].accounts,
        icon: icons.users,
        component: <AccountScreen />,
      },
    ].filter((el) => {
      if (role === "superAdmin") return el;
      if (menus.includes(el.name)) return el;
    });

    return [
      ...sidebarList,
      {
        parentPath: "/dashboard",
        path: "/settings",
        name: language[lng].settings,
        icon: icons.setting,
        component: <SettingScreen />,
      },
    ];
  } catch (error) {
    window.location.href = "/";
  }
};

export default data;
