import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { DeleteModal, Modal } from "../../Modal";
import serviceQuery from "../../../utils/queries/serviceQuery";
import toastMessage from "../../../utils/toastMessage";
import axios from "axios";
import { getStorage } from "../../../utils/storage";
import handleSearch from "../../../utils/handleSearch";
import NewSubApprover from "./NewSubApprover";
import { defineRole } from "../../../utils/handleAccessRoles";
import sortLevels from "../../../utils/sortLevels";

let copyData = [];

class SubApprover extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
    subApprover: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
    this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      let { subApprover = [] } = data[0] || [];

      subApprover.forEach((el) => {
        if (el.account?._id) {
          el["name"] = el.account.firstName + " " + el.account.lastName;

          el["institution"] = el.account.institution;
        }

        if (el.accessRole?._id) {
          el["name"] = el.accessRole.name;
        }

        if (!el["institution"]) {
          el["institution"] = el.accessRole.institution;
        }

        if (el.account?._id && el.accessRole?._id) {
          el[
            "name"
          ] = `${el.account.firstName} ${el.account.lastName} (${el.accessRole.name})`;
        }
      });

      this.setState({
        data: data.length > 0 ? data[0] : {},
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.length > 0 ? data[0].subApprover?.slice(0) || [] : [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handleSearch(e) {
    this.setState({ search_text: e.target.value });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].level,
        key: "level",
      },
      {
        title:
          language[this.props.defaultLanguage].individual_max_response_time,
        key: "individualMaxResponseTime",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { selectedData, data } = this.state;
      let { subApprover } = data;
      let user = await getStorage();

      subApprover.splice(selectedData.dataIndex, 1);

      this.setState({ data: this.state.data });

      const options = {
        method: "PUT",
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: {
          id: data._id,
          subApprover,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_approver_success
      );

      this.setState({ isDeleting: false });

      this.handleCloseModal("showDeleteModal");
      this.getData();
    } catch (error) {
      this.setState({
        isDeleting: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_delete_approver
      );
    }
  };

  render() {
    const { add_sub_approver, delete_approver, sub_approver, delete_, edit } =
      language[this.props.defaultLanguage];

    const sortedApprovers = sortLevels(
      this.state?.data?.subApprover || [],
      "level",
      "institution"
    );
    return (
      <>
        <Table
          data={sortedApprovers || []}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
              stateFieldName: "subApprover",
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add_sub_approver}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            sub_approver
          )}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "update",
              }),
              name: edit,
              onPress: (item, dataIndex) =>
                this.handleShowModal("showModal", edit, { ...item, dataIndex }),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "delete",
              }),
              name: delete_,
              onPress: (item, dataIndex) =>
                this.handleShowModal("showDeleteModal", delete_approver, {
                  ...item,
                  dataIndex,
                }),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewSubApprover
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            serviceData={this.state.data}
            {...this.state.selectedData}
            edit={this.state.selectedData._id}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={
              this.state.selectedData?.account?.username ||
              this.state.selectedData?.accessRole?.name
            }
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SubApprover);
