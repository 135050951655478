import React from "react";
import { Navbar } from "../../components/Navbar";
import { Introduction } from "../../components/Landing/Introduction";
import { LandingPageLayout } from "../../Layout";
import { Services } from "../../components/Services";
import { Footer } from "../../components/Footer";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import language from "../../language";
class ServicePerCategoryLandingScreen extends React.Component {
  render() {
    return (
      <>
        <Navbar {...this.props} />
        <div className="main-landing-container">
          <LandingPageLayout className="intro-container">
            <Introduction
              serviceTypeName={
                this.props.routeParams.service +
                " " +
                language[this.props.defaultLanguage].services
              }
            />
          </LandingPageLayout>
          <LandingPageLayout className="services-container">
            <Services
              data={
                this.props.routeParams.service === "individual"
                  ? this.props.services["individual"]
                  : this.props.services["institution"]
              }
            />
          </LandingPageLayout>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { landingCategoryActiveTab } = state.LandingCategoryTab;
  const { services } = state.Services;
  const { defaultLanguage } = state.Language;
  return {
    landingCategoryActiveTab,
    services,
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(
  withRouter(ServicePerCategoryLandingScreen)
);
