import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import serviceCategoryQuery from "../../../utils/queries/serviceCategoryQuery";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import apiError from "../../../utils/apiError";

class NewService extends React.Component {
  state = {
    error: {},
    languages: Object.keys(language),
    serviceLanguages: [this.props.defaultLanguage],
    maxResponseTime: "",
    categories: [],
    institution: "",
    name: "",
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    if (this.props.institution === undefined || this.props.institution === "") {
      this.setState({ institution: process.env.REACT_APP_INSTITUTION });
    }

    await this.getCategories(true);
  };

  getCategories = async (isFetchingServiceCategories) => {
    try {
      const { routeCategory } = this.props;

      this.setState({
        isFetchingServiceCategories,
      });

      const data = await serviceCategoryQuery(this.props.defaultLanguage, {
        type: "dropdown",
        categoryType: routeCategory,
      });

      this.setDefaultCategory(data);

      this.setState({
        categories: data,
        isFetchingServiceCategories: false,
      });
    } catch (error) {
      this.setState({ isFetchingServiceCategories: false });
    }
  };

  setDefaultCategory(categories) {
    const { category } = this.props;
    if (category) {
      const selectedCategory = categories.find((el) => {
        console.log({ el, category: category._id });
        return el.value === category?._id;
      });

      console.log({ selectedCategory, category });

      if (selectedCategory?._id) {
        this.setState({
          category: {
            label: selectedCategory.name,
            value: selectedCategory._id,
            type: selectedCategory.type,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  handleAddTranslation() {
    const { defaultLanguage } = this.props;
    const { languages, serviceLanguages } = this.state;

    for (let el of languages) {
      if (el !== defaultLanguage && !serviceLanguages.includes(el)) {
        serviceLanguages.push(el);
      }
    }

    this.setState({ serviceLanguages });
  }

  handleRemoveTranslation(index) {
    let { serviceLanguages } = this.state;

    serviceLanguages.splice(index, 1);

    this.setState({ serviceLanguages });
  }

  renderServiceName = (lng = this.props.defaultLanguage, index) => {
    const { serviceLanguages, error } = this.state;

    return (
      <Input
        leftIcon={icons.service}
        key={index}
        placeholder={language[lng].service_name_placeholder}
        label={language[lng].service_name + "(" + lng + ")"}
        required
        value={this.state["serviceName_" + lng]}
        onChange={(e) => this.onChangeText("serviceName_" + lng, e)}
        error={error["serviceName_" + lng]}
        // helperLink={
        //   serviceLanguages.length === 1
        //     ? language[lng].add_translation
        //     : language[lng].remove_translation
        // }
        // onPressHelperLink={
        //   serviceLanguages.length === 1
        //     ? this.handleAddTranslation.bind(this)
        //     : this.handleRemoveTranslation.bind(this, index)
        // }
      />
    );
  };

  validateForm() {
    let { category, maxResponseTime, error, institution, name } = this.state;

    if (!category) {
      error.category = language[this.props.defaultLanguage].category_required;
    }

    if (maxResponseTime === "") {
      error.maxResponseTime =
        language[this.props.defaultLanguage].maxDays_required;
    } else if (maxResponseTime <= 0) {
      error.maxResponseTime = "Average response time must be greater than zero";
    }

    if (institution === "") {
      error.institution =
        language[this.props.defaultLanguage].institution_required;
    }

    if (name === "") {
      error.name = language[this.props.defaultLanguage].service_name_required;
    }

    // for (let lng of serviceLanguages) {
    //   if (!this.state["serviceName_" + lng]) {
    //     error["serviceName_" + lng] = language[lng].service_name_required;
    //   } else if (this.state["serviceName_" + lng] === "") {
    //     error["serviceName_" + lng] = language[lng].service_name_required;
    //   }
    // }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const { category, maxResponseTime, institution, name, _id } = this.state;
      const user = await getStorage();
      const {
        add_service_success,
        update_service_success,
        update_service_error,
        add_service_error,
      } = language[this.props.defaultLanguage];

      let method = "POST";
      let requiredBody = {
        category: category.value,
        institution,
        name,
        maxResponseTime,
        type: category.type,
        institution: user.institution,
      };

      if (_id) {
        method = "PUT";

        requiredBody.id = _id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: requiredBody,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? update_service_success : add_service_success
            ]
          );

          if (method === "POST")
            window.location.href = `/dashboard/services/single-view/${data?.data?._id}`;

          if (this.props.handleCloseModal) {
            this.props.handleCloseModal();
          }

          if (this.props.getData) {
            this.props.getData();
          }
        })
        .catch((error) => {
          const { update_service_error, add_service_error, service_exist } =
            language[this.props.defaultLanguage];

          this.setState({
            isSubmitting: false,
          });

          apiError(error, {
            requestMethod: method,
            addErrorMessage: add_service_error,
            updateErrorMessage: update_service_success,
            dataExistErrorMessage: service_exist,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <Input
            leftIcon={icons.institution}
            placeholder={
              language[this.props.defaultLanguage].institution_placeholder
            }
            label={language[this.props.defaultLanguage].institution}
            required
            value={this.state.institution}
            onChange={(e) => this.onChangeText("institution", e)}
            error={this.state.error.institution}
          />
          <Select
            leftIcon={icons.category}
            options={this.state.categories}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].category}
            required
            value={this.state.category}
            onChange={(e) => this.onChangeText("category", e)}
            error={this.state.error.category}
            isLoading={this.state.isFetchingServiceCategories}
          />
          {this.state?.category?.type && (
            <Input
              leftIcon={icons.type}
              label={language[this.props.defaultLanguage].type}
              required
              value={this.state.category.type}
              onChange={(e) => this.onChangeText("type", e)}
              error={this.state.error.type}
              disabled
            />
          )}
          {/* {this.state.serviceLanguages.map((el, i) => {
            return this.renderServiceName(el, i);
          })} */}
          <Input
            leftIcon={icons.description}
            placeholder={
              language[this.props.defaultLanguage].service_name_placeholder
            }
            label={language[this.props.defaultLanguage].service_name}
            required
            value={this.state.name}
            onChange={(e) => this.onChangeText("name", e)}
            error={this.state.error.name}
          />
          <Input
            leftIcon={icons.time}
            placeholder={
              language[this.props.defaultLanguage].max_number_days_placeholder
            }
            label={language[this.props.defaultLanguage].avg_response_time}
            required
            value={this.state.maxResponseTime}
            onChange={(e) => this.onChangeText("maxResponseTime", e)}
            error={this.state.error.maxResponseTime}
            type="number"
            min={1}
          />
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewService);
