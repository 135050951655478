import React from "react";
import "./styles.css";
import { Logo } from "../Logo";
import { Button, DropdownButton } from "../Button";
import MobileNavbar from "./MobileNavbar";
import language from "../../language";
import { connect } from "react-redux";
import { ListItem } from "../List";
import { setDefaultLanguage } from "../../actions/Language";
import { getStorage } from "../../utils/storage";
import userPhotoPlaceholder from "../../assets/userPhotoPlaceholder.png";
import {
  adminAuthMenus,
  commonAuthMenus,
  nonAuthMenus,
  userAuthMenus,
} from "../../constants/profileMenus";
import icons from "../../constants/icons";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";
import { AccountSwitch } from "../AccountSwitch";

class Navbar extends React.Component {
  constructor() {
    super();

    this.mobileNavbarRef = React.createRef();

    this.state = {
      showMoreMenus: false,
      user: {},
    };
  }
  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    document.addEventListener("mousedown", this.handleClickOutside.bind(this));

    window.addEventListener("scroll", this.onScroll.bind(this), {
      passive: true,
    });
  };

  onScroll() {
    const scrollY = window.pageYOffset;

    const navbar_container = document.getElementById("navbar-container");

    if (navbar_container.classList) {
      let nav_class_list = navbar_container.classList;

      if (scrollY > 0) {
        if (navbar_container) {
          nav_class_list.add("navbar-fixed-top");
        }
      } else {
        nav_class_list.remove("navbar-fixed-top");
      }
    }
  }

  handleShowMoreMenus = (e) => {
    e.preventDefault();

    const { type } = this.props;

    if (type === "dashboard") {
      return this.handleToggleSidebar();
    }
    this.setState({ showMoreMenus: !this.state.showMoreMenus });
  };

  handleToggleSidebar() {
    const container = document.getElementById("sidebar-container");

    if (container.classList) {
      let nav_class_list = container.classList;

      if (container && nav_class_list.contains("hide")) {
        nav_class_list.add("show");
        nav_class_list.remove("hide");
      } else if (container && nav_class_list.contains("show")) {
        nav_class_list.add("hide");
        nav_class_list.remove("show");
      }
    }
  }

  handleClickOutside(event) {
    if (
      this.mobileNavbarRef.current &&
      !this.mobileNavbarRef.current.contains(event.target) &&
      this.state.showMoreMenus
    ) {
      this.setState({ showMoreMenus: false });
    }
  }

  render() {
    return (
      <>
        <nav className="landing-navbar-container" id="navbar-container">
          <div
            className={`${
              this.props.className || "container"
            } menu-landing-container `}
          >
            <div className="left-container">
              <Logo
                className="light"
                handleMenuIconClicked={this.handleShowMoreMenus.bind(this)}
                type={this.props.type}
                toggleMenus={this.state.showMoreMenus}
              />
            </div>
            <div className="right-container">
              <div className="hidden-xs hidden-sm d-flex">
                {nonAuthMenus(this.props.defaultLanguage)?.map((menu, i) => {
                  if (
                    this.state.user.token &&
                    menu.access.includes("protected")
                  ) {
                    return (
                      <Button
                        type="link"
                        to={menu.route}
                        key={i}
                        text={menu.name}
                        styles={{ minWidth: 100, minHeight: 35 }}
                        className="btn-transparent light"
                      />
                    );
                  } else if (
                    !this.state.user.token &&
                    menu.access.includes("public")
                  ) {
                    return (
                      <Button
                        type="link"
                        to={menu.route}
                        key={i}
                        text={menu.name}
                        styles={{ minWidth: 100, minHeight: 35 }}
                        className="btn-transparent light"
                      />
                    );
                  }
                  return <></>;
                })}
                <DropdownButton
                  text={this.props.defaultLanguage}
                  className="btn-transparent light"
                  leftImage={language[this.props.defaultLanguage].image}
                >
                  {Object.keys(language).map((key, i) => {
                    return (
                      <ListItem
                        key={i}
                        name={key}
                        onPress={() =>
                          this.props.dispatch(setDefaultLanguage(key))
                        }
                        image={language[key].image}
                      />
                    );
                  })}
                </DropdownButton>
              </div>
              {this.state.user.token ? (
                <>
                  <div
                    className="navbar-notification-container"
                    onClick={() =>
                      (window.location.href =
                        this.state.user.role === "user"
                          ? "/public-servant/notifications"
                          : "/dashboard/notifications")
                    }
                  >
                    <Button
                      className="btn-transparent light"
                      icon={icons.notification}
                    />
                    <NotificationBadgeCount
                      count={this.props.notifications.length}
                      borderClassName="primary-border"
                      styles={{
                        position: "absolute",
                      }}
                    />
                  </div>
                  <div
                    className="navbar-notification-container"
                    onClick={() =>
                      window.open(
                        `/user-guide/${
                          this.state.user.role !== "user"
                            ? "admin"
                            : "applicant"
                        }`,
                        "_blank"
                      )
                    }
                  >
                    <Button
                      className="btn-transparent light"
                      icon={icons.help}
                      title="User Guide Information"
                    />
                  </div>
                  <DropdownButton
                    text={this.state.user.displayName}
                    className="btn-transparent menu-container-no-shadow light"
                    leftImage={userPhotoPlaceholder}
                    classNameButtonText="hidden-xs"
                  >
                    {this.state.user.role === "user"
                      ? userAuthMenus(this.props.defaultLanguage).map(
                          (el, i) => {
                            return (
                              <>
                                <ListItem
                                  key={i}
                                  name={el.name}
                                  onPress={el.onPress}
                                  icon={el.icon}
                                  iconClassName="sm"
                                />
                              </>
                            );
                          }
                        )
                      : adminAuthMenus(this.props.defaultLanguage).map(
                          (el, i) => {
                            return (
                              <>
                                <ListItem
                                  key={i}
                                  name={el.name}
                                  onPress={el.onPress}
                                  icon={el.icon}
                                  iconClassName="sm"
                                />
                              </>
                            );
                          }
                        )}

                    <AccountSwitch
                      className="card-body"
                      title={
                        language[this.props.defaultLanguage].from_institution
                      }
                    />
                    {commonAuthMenus(this.props.defaultLanguage).map(
                      (el, i) => {
                        return (
                          <ListItem
                            key={i}
                            name={el.name}
                            onPress={el.onPress}
                            icon={el.icon}
                            iconClassName="sm"
                          />
                        );
                      }
                    )}
                  </DropdownButton>
                </>
              ) : (
                <>
                  <div className="hidden-xs hidden-sm">
                    <Button
                      text={language[this.props.defaultLanguage].admin}
                      styles={{ minWidth: 100, minHeight: 35 }}
                      className="btn-transparent btn-bordered light"
                      type="link"
                      to="/admin-login"
                    />
                  </div>
                  <div className="visibility-xs visibility-sm">
                    <DropdownButton
                      text={language[this.props.defaultLanguage].account}
                      className="btn-transparent light"
                      icon={icons.user}
                    >
                      {[
                        {
                          title:
                            language[this.props.defaultLanguage].user_login,
                          route: "/login",
                          icon: icons.user,
                        },
                        {
                          title:
                            language[this.props.defaultLanguage].admin_login,
                          route: "/admin-login",
                          icon: icons.admin_user,
                        },
                      ].map((el, i) => {
                        return (
                          <ListItem
                            key={i}
                            icon={el.icon}
                            name={el.title}
                            onPress={() => (window.location.href = el.route)}
                          />
                        );
                      })}
                    </DropdownButton>
                  </div>
                </>
              )}
            </div>
          </div>
          <MobileNavbar
            ref={this.mobileNavbarRef}
            className={this.state.showMoreMenus ? "show" : "hide"}
            {...this.props}
            user={this.state.user}
          />
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { notifications } = state.Notifications;
  return {
    defaultLanguage,
    notifications,
  };
};

export default connect(mapStateToProps)(Navbar);
