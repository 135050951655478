import React from "react";
import { DropdownButton } from "../Button";

import icons from "../../constants/icons";
import { connect } from "react-redux";
import language from "../../language";
import ListServices from "./ListServices";
import { Tabs } from "../Tabs";

class ServiceFilter extends React.Component {
  render() {
    return (
      <DropdownButton
        leftIcon={icons.service}
        text={language[this.props.defaultLanguage].services}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.services}
        clickOutside
      >
        <Tabs
          options={[
            {
              title: language[this.props.defaultLanguage].individual,
              data: <ListServices serviceType="individual" />,
            },
            {
              title: language[this.props.defaultLanguage].institution,
              data: <ListServices serviceType="institution" />,
            },
          ]}
        />
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ServiceFilter);
