import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { Modal, DeleteModal } from "../../Modal";
import NewService from "./NewService";
import { CSVLink } from "react-csv";
import serviceQuery, {
  deleteService,
} from "../../../utils/queries/serviceQuery";
import exportPDF from "../../../utils/exportPDF";
import handleSearch from "../../../utils/handleSearch";
import icons from "../../../constants/icons";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import { onResetFilters } from "../../../actions/Filters";

let copyData = [];
class Services extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.routeCategory !== this.props.routeCategory) {
      //reset filters
      this.props.dispatch(onResetFilters());

      this.getData(true);
    }

    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, filters = {}, routeCategory } = this.props;
      const { categories } = filters;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        serviceType: routeCategory,
        categories,
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
        selectedCategory: {},
      });

      copyData = data?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].service,
        key: "name",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/services/single-view/${item?._id}`),
      },
      {
        title: language[this.props.defaultLanguage].category,
        key: "category.name",
      },
      {
        title: language[this.props.defaultLanguage].type,
        key: "type",
      },
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },
      {
        title: "Created At",
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: "Action",
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteService(
        this.props.defaultLanguage,
        this.state.selected_data._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit, selectedCategory } = this.state;
      const { defaultLanguage, routeCategory } = this.props;

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        serviceType: routeCategory,
        category: selectedCategory?._id || undefined,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit, selectedCategory } = this.state;
      const { defaultLanguage, routeCategory } = this.props;

      const headers = this.returnTableHeaders();

      const { data } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        serviceType: routeCategory,
        category: selectedCategory?._id || undefined,
      });

      this.setState({
        isLoading: false,
      });

      exportPDF("Services", headers, data);
    } catch (error) {
      console.log("****** error export pdf", error);
    }
  };

  handleSelectCategory(item) {
    this.setState(
      {
        selectedCategory: item,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    const { add_service, edit_service, delete_service, export_ } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: "Services",
            operation: "create",
          })}
          addButtonText={add_service}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          rowPress={(item) =>
            (window.location.href = `/dashboard/services/single-view/${item?._id}`)
          }
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            "Service"
          )}
          filters={[
            {
              title: language[this.props.defaultLanguage].category,
              button_type: "dropdown",
              icon: icons.category,
              filterName: "categories",
              filterType: this.props.routeCategory,
            },
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "update",
              }),
              name: "Edit",
              onPress: (item) =>
                this.handleShowModal("showModal", edit_service, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "delete",
              }),
              name: "Delete",
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_service
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewService
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            routeCategory={this.props.routeCategory}
            {...this.state.selected_data}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_value}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.name}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Services" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Services);
