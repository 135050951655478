import React from "react";
import "./styles.css";
import icons from "../../constants/icons";
import moment from "moment";
import { fetchCounts } from "../../utils/queries/analyticsQuery";
import { ParagraphOne, TitleLoader } from "../Loader";
import { connect } from "react-redux";

const thirtyDaysRange = {
  endCreatedAt: moment().startOf("day").subtract(30, "days").valueOf(),
  startCreatedAt: moment(moment().subtract(30, "days").valueOf())
    .subtract(30, "d")
    .valueOf(),
  status: "pending",
};

const ninetyDaysRange = {
  endCreatedAt: moment().startOf("day").subtract(90, "days").valueOf(),
  startCreatedAt: moment(moment().subtract(90, "days").valueOf())
    .subtract(90, "d")
    .valueOf(),
  status: "pending",
};

class CountContainer extends React.Component {
  state = {
    data: [],
    isLoading: false,
    ntyDaysCount: 0,
    tyDaysCount: 0,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData();
    }
  }

  getData() {
    const { filters = {} } = this.props;
    const { startDate, endDate, services } = filters;

    // above 30days
    this.getCounts(true, {
      fieldName: "tyDaysCount",
      ...thirtyDaysRange,
      "body.status": "pending",
      services,
    });

    // 90days
    this.getCounts(true, {
      fieldName: "ntyDaysCount",
      ...ninetyDaysRange,
      "body.status": "pending",
      services,
    });
  }

  getCounts = async (isLoading, params) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchCounts(defaultLanguage, params);

      this.setState({
        data: data,
        [params.fieldName]: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const counts = [
      {
        title: "Pending 30 Days+",
        count: this.state.tyDaysCount,
        isLoading: this.state.isLoading,
        ...thirtyDaysRange,
      },
      {
        title: "Pending 90 Days+",
        count: this.state.ntyDaysCount,
        isLoading: this.state.isLoading,
        ...ninetyDaysRange,
      },
    ];
    return (
      <div className="home-analytics-count-container">
        {counts.map((el, i) => {
          return (
            <div
              className="card"
              key={i}
              onClick={() =>
                !this.state.isLoading
                  ? (window.location.href = `/dashboard/requests/${el.status}?startCreatedAt=${el.startCreatedAt}&endCreatedAt=${el.endCreatedAt}`)
                  : null
              }
            >
              <div className="card-body">
                <div className="count-item">
                  {this.state.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <TitleLoader />
                      <ParagraphOne />
                    </div>
                  ) : (
                    <>
                      <div className="count">
                        <h1 className="error-text">{el.count}</h1>
                      </div>
                      <div className="count-title-container">
                        <span className="count_title text-bold">
                          {el.title}
                        </span>
                      </div>
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {icons.arrowRightAlt}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(CountContainer);
