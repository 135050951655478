import Row from "./Row";

const TableData = ({ data, headers, rowPress, actions, draggable }) => {
  return (
    <>
      {data.map((item, i) => {
        return (
          <Row
            key={i}
            keyRowIndex={i}
            item={item}
            draggable={draggable}
            headers={headers}
            rowPress={rowPress}
            actions={actions}
          />
        );
      })}
    </>
  );
};

export default TableData;
