import { connect } from "react-redux";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import userSidebarMenus from "../constants/userSidebarMenus";
import "./layout.css";
import { TokenExpirationChecker } from "../components/Token";

const HomePublicServantDashboardLayout = (props) => {
  return (
    <>
      <Navbar className="container-fluid" type="dashboard" />
      <TokenExpirationChecker role="user" />
      <div className="home-dashboard-layout-container ">
        <Sidebar {...props} menus={userSidebarMenus(props.defaultLanguage)} />
        <div className="dashboard-layout">{props.children}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HomePublicServantDashboardLayout);
