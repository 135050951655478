import React from "react";
import { DashboardHeader } from "../Header";
import { connect } from "react-redux";
import GuideContent from "./GuideContent";
import { withRouter } from "../../utils/withRouter";
import { Loading } from "../Loader";
import { Empty } from "../Empty";
import icons from "../../constants/icons";

const renderEmpty = () => {
  return (
    <Empty
      icon={icons.help}
      title="No User guide found"
      description={`Something went wrong, check if you have logged in successfully and try to refresh the page`}
    />
  );
};

const UserGuideData = (props) => {
  return (
    <div className="user-guide-content-container">
      {props.data && (
        <DashboardHeader
          title={props.headerTitle}
          description={props.headerDescription}
        />
      )}

      {props.isLoading ? (
        <Loading className="loader-fullscreen" />
      ) : !props.data ? (
        renderEmpty()
      ) : (
        props.data &&
        props.data[props.defaultLanguage || "english"]?.map((value, index) => {
          return <GuideContent key={index} {...value} />;
        })
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(UserGuideData));
