import React from "react";
import { connect } from "react-redux";
import { Requests } from "../AdminRequests";
import { Tabs } from "../Tabs";
import moment from "moment";
import language from "../../language";
import { getStorage } from "../../utils/storage";

class RequestContainer extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    const ListOfTabs = [
      {
        title: language[this.props.defaultLanguage].recent_individual_requests,
        count: this.props.countPendingRequestsPerType["individual"],
        data: (
          <Requests
            fromDate={moment().subtract("d", 30).format("YYYY-MM-DD")}
            toDate={moment().add("d", 1).format("YYYY-MM-DD")}
            type="individual"
            status="pending"
            noHeight
          />
        ),
      },
      {
        title: language[this.props.defaultLanguage].recent_institution_requests,
        count: this.props.countPendingRequestsPerType["institution"],
        data: (
          <Requests
            fromDate={moment().subtract("d", 30).format("YYYY-MM-DD")}
            toDate={moment().add("d", 1).format("YYYY-MM-DD")}
            type="institution"
            status="pending"
            noHeight
          />
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div style={{ marginTop: "1rem" }}>
          <div className="card-body">
            {this.state.user.accountType === "account-subApprover" ? (
              <Requests
                fromDate={moment().subtract("d", 30).format("YYYY-MM-DD")}
                toDate={moment().add("d", 1).format("YYYY-MM-DD")}
                // type="institution"
                status="pending"
                noHeight
              />
            ) : (
              <Tabs options={ListOfTabs} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { countPendingRequestsPerType } = state.Request;
  return {
    defaultLanguage,
    countPendingRequestsPerType,
  };
};

export default connect(mapStateToProps)(RequestContainer);
