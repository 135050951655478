// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-guide-attachment {
  /* height: 50vh; */
  border-radius: 16px;
  margin-top: 2rem;
}

.user-guide-attachment img {
  width: 100%;
  border-radius: 16px;
}

.user-guide-content-container {
  height: 95vh;
  overflow-y: auto;
}

.user-guide-attachment:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* .user-guide-table-content-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: red;
  overflow-y: auto;
} */
`, "",{"version":3,"sources":["webpack://./src/app/components/UserGuide/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;;;;;;;;GAQG","sourcesContent":[".user-guide-attachment {\r\n  /* height: 50vh; */\r\n  border-radius: 16px;\r\n  margin-top: 2rem;\r\n}\r\n\r\n.user-guide-attachment img {\r\n  width: 100%;\r\n  border-radius: 16px;\r\n}\r\n\r\n.user-guide-content-container {\r\n  height: 95vh;\r\n  overflow-y: auto;\r\n}\r\n\r\n.user-guide-attachment:hover {\r\n  opacity: 0.8;\r\n  cursor: pointer;\r\n}\r\n\r\n/* .user-guide-table-content-container {\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  left: 0;\r\n  background-color: red;\r\n  overflow-y: auto;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
