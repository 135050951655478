import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Attachments } from "../Attachment";
import { Modal } from "../Modal";
import sortLevels from "../../utils/sortLevels";

class ApproversResponse extends React.Component {
  state = {
    isLoading: false,
    data: [],
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.getData();
    }
  }

  getData() {
    const data = [];
    this.props.data.forEach((el) => {
      // if (!el.action || el.action === "") return;

      if (el.account) {
        el.institution = el.account.institution;
      } else if (el.accessRole) {
        el.institution = `(${el.accessRole.name})`;
      }

      data.push({
        ...el,
        actionName:
          el.action === "Approval"
            ? "Approved"
            : el.action === "Rejections"
            ? "Rejected"
            : "-",
        totalAttachments:
          el.questionnaireAction?.length > 0
            ? `${el.questionnaireAction?.length} Attachment(s)`
            : "",
      });
    });

    this.setState({ data: sortLevels(data, "level", "institution") });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].level,
        key: "level",
      },
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },
      {
        title: language[this.props.defaultLanguage].comment,
        key: "actionComment",
      },
      {
        title: language[this.props.defaultLanguage].attachments,
        key: "totalAttachments",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showAttachmentsModal",
            language[this.props.defaultLanguage].document_attached,
            item
          ),
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "actionAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isLoading={this.props.isLoading}
          headers={this.returnTableHeaders()}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentsModal")}
          show={this.state.showAttachmentsModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div className="card-body">
            <Attachments
              data={this.state.selectedData?.questionnaireAction || []}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ApproversResponse);
