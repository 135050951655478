import React from "react";

import { ChangePassword } from "../../../components/ChangePassword";

class ChangePasswordScreen extends React.Component {
  render() {
    return (
      <>
        <ChangePassword />
      </>
    );
  }
}

export default ChangePasswordScreen;
