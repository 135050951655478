import React from "react";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import { connect } from "react-redux";
import { getStorage } from "../../../utils/storage";
import { Requests } from "../../../components/UserRequests";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";

class MyRequestsDashboard extends React.Component {
  state = {
    isLoading: true,
    data: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    return (
      <>
        <DashboardHeader
          title={language[this.props.defaultLanguage].my_requests}
        />
        <div className="card card-dashboard-content">
          <Tabs
            options={[
              {
                title: language[this.props.defaultLanguage].all,
                data: (
                  <div className="card-body">
                    <Requests
                      availableFilters={["date_range"]}
                      exportEnabled
                      showAdd
                    />
                  </div>
                ),
              },
              {
                title: language[this.props.defaultLanguage].pending,
                data: (
                  <div className="card-body">
                    <Requests
                      status="pending"
                      availableFilters={["date_range"]}
                      exportEnabled
                      showAdd
                    />
                  </div>
                ),
              },
              {
                title: language[this.props.defaultLanguage].request_changes,
                data: (
                  <div className="card-body">
                    <Requests
                      status="requestedChanges"
                      availableFilters={["date_range"]}
                      exportEnabled
                    />
                  </div>
                ),
              },
              {
                title: language[this.props.defaultLanguage].approved,
                data: (
                  <div className="card-body">
                    <Requests
                      status="approved"
                      availableFilters={["date_range"]}
                      exportEnabled
                    />
                  </div>
                ),
              },
              {
                title: language[this.props.defaultLanguage].rejected,
                data: (
                  <div className="card-body">
                    <Requests
                      status="rejected"
                      availableFilters={["date_range"]}
                      exportEnabled
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(MyRequestsDashboard));
