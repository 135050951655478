import React from "react";
import { Button } from "../Button";
import { Input } from "../Input";
import { connect } from "react-redux";
import language from "../../language";

const ConfirmationModal = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="confirmation-modal-container">
          <div className="confirmation-modal-header">
            <div
              className={`confirmation-modal-icon-container ${
                props.success ? "success" : ""
              } ${props.failed ? "failed" : ""}`}
            >
              <span className="material-symbols-outlined">{props.icon}</span>
            </div>
          </div>
          <center className="confirmation-modal-content">
            <h2>{props.title}</h2>
            <p>{props.description}</p>
          </center>
        </div>
      </div>
      <div className="modal-footer">
        {props.success ? (
          <>
            <Button
              text="Close"
              className="btn-default border"
              onPress={props.handleCloseModal}
            />
          </>
        ) : (
          <>
            <Button
              text={props.negativeButtonText}
              className="btn-default border"
              onPress={props.onPressNegative}
            />

            <Button
              text={props.positiveButtonText}
              className="btn-primary"
              onPress={props.onPressPositive}
              isSubmitting={props.isPositiveSubmitting}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ConfirmationModal);
