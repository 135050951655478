import React from "react";
import { Navbar } from "../../components/Navbar";
import { Introduction } from "../../components/Landing/Introduction";
import Features from "../../components/Landing/Features/Features";
import { Services } from "../../components/Services";
import { Footer } from "../../components/Footer";
import { CategoryTab } from "../../components/Landing/CategoryTab";
import { connect } from "react-redux";
import { LandingPageLayout } from "../../Layout";

class LandingScreen extends React.Component {
  componentDidMount() {
    const { copyServices } = this.props;
    if (copyServices.length === 0) return (window.location.href = "/");
  }

  render() {
    return (
      <>
        <Navbar {...this.props} />
        <div className="main-landing-container">
          <LandingPageLayout className="intro-container">
            <Introduction />
          </LandingPageLayout>
          <LandingPageLayout className="features-container hidden-sm hidden-xs">
            <Features />
          </LandingPageLayout>
          <div id="landing-list-services">
            <CategoryTab />
            <LandingPageLayout className="services-container">
              <Services
                data={
                  this.props.landingCategoryActiveTab === 0
                    ? this.props.services["individual"]
                    : this.props.services["institution"]
                }
              />
            </LandingPageLayout>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { landingCategoryActiveTab } = state.LandingCategoryTab;
  const { services, copyServices } = state.Services;
  return {
    landingCategoryActiveTab,
    services,
    copyServices,
  };
};

export default connect(mapStateToProps)(LandingScreen);
