import React from "react";
import { connect } from "react-redux";
import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import PerCategory from "./PerCategory";
import { Modal } from "../Modal";
import language from "../../language";
import icons from "../../constants/icons";
import Requests from "./Requests";
class Analytics extends React.Component {
  state = {
    chart: "",
  };

  handleShowModal({ chart, modalTitle }) {
    this.setState({
      chart,
      showModal: true,
      modalTitle,
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  renderDailyChart(icon) {
    return (
      <Daily
        onFullScreenPressed={() =>
          icon === "fullscreen"
            ? this.handleShowModal({
                chart: "daily",
                title:
                  language[this.props.defaultLanguage].daily_pending_requests,
              })
            : this.handleCloseModal()
        }
        resizeIcon={icon}
      />
    );
  }

  renderWeeklyChart(icon) {
    return (
      <Weekly
        onFullScreenPressed={() =>
          icon === "fullscreen"
            ? this.handleShowModal({
                chart: "weekly",
                title: language[this.props.defaultLanguage].day_of_week,
              })
            : this.handleCloseModal()
        }
        resizeIcon={icon}
      />
    );
  }

  renderMonthlyChart(icon) {
    return (
      <Monthly
        onFullScreenPressed={() =>
          icon === "fullscreen"
            ? this.handleShowModal({
                chart: "monthly",
                title: language[this.props.defaultLanguage].monthly_requests,
              })
            : this.handleCloseModal()
        }
        resizeIcon={icon}
      />
    );
  }

  renderPerCategory(icon) {
    return (
      <PerCategory
        onFullScreenPressed={() =>
          icon === "fullscreen"
            ? this.handleShowModal({
                chart: "category",
                title:
                  language[this.props.defaultLanguage].requests_per_category,
              })
            : this.handleCloseModal()
        }
        resizeIcon={icon}
      />
    );
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            {this.renderDailyChart(icons.fullscreen)}
          </div>
          <div className="col-md-6">
            {this.renderWeeklyChart(icons.fullscreen)}
          </div>
          <div className="col-md-6">
            {this.renderMonthlyChart(icons.fullscreen)}
          </div>
          <div className="col-md-6">
            {this.renderPerCategory(icons.fullscreen)}
          </div>
          <div className="col-md-12">
            <Requests />
          </div>
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="xl"
        >
          {this.state.chart === "daily" && this.renderDailyChart(icons.close)}
          {this.state.chart === "weekly" && this.renderWeeklyChart(icons.close)}
          {this.state.chart === "monthly" &&
            this.renderMonthlyChart(icons.close)}
          {this.state.chart === "category" &&
            this.renderPerCategory(icons.close)}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Analytics);
