// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container {
  display: flex;
  min-height: 60px;
  gap: 1.5rem;
}

.user-left-container {
  display: flex;
  gap: 1.5rem;
  flex: 1 1;
  flex-wrap: wrap;
}

.user-container .user-img-container {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-img-container img {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-name-container {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/User/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAO;EACP,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,SAAO;AACT","sourcesContent":[".user-container {\n  display: flex;\n  min-height: 60px;\n  gap: 1.5rem;\n}\n\n.user-left-container {\n  display: flex;\n  gap: 1.5rem;\n  flex: 1;\n  flex-wrap: wrap;\n}\n\n.user-container .user-img-container {\n  width: 50px;\n  height: 50px;\n  border-radius: var(--border-radius-half);\n}\n\n.user-container .user-img-container img {\n  width: 50px;\n  height: 50px;\n  border-radius: var(--border-radius-half);\n}\n\n.user-container .user-name-container {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
