import { Search } from "../Search";
import "./styles.css";
import LeftContent from "./LeftContent";
import ImageContent from "./ImageContent";

const Introduction = (props) => {
  return (
    <>
      <div className="row hidden-xs hidden-sm">
        <LeftContent serviceTypeName={props.serviceTypeName} />
        <ImageContent />
      </div>
      <div className="row">
        <Search />
      </div>
    </>
  );
};

export default Introduction;
