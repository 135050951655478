// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-class {
  padding: 1rem;
  border: 1px solid var(--primary-color-10);
}
.editor-class {
  background-color: var(--text-color-5);
  padding: 1rem;
  min-height: 50vh;
}
.toolbar-class {
  border: 1px solid var(--primary-color-5);
}

.preview p {
  color: var(--text-color);
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/AdminServices/OutputLetter/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yCAAyC;AAC3C;AACA;EACE,qCAAqC;EACrC,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;AACrB","sourcesContent":[".wrapper-class {\n  padding: 1rem;\n  border: 1px solid var(--primary-color-10);\n}\n.editor-class {\n  background-color: var(--text-color-5);\n  padding: 1rem;\n  min-height: 50vh;\n}\n.toolbar-class {\n  border: 1px solid var(--primary-color-5);\n}\n\n.preview p {\n  color: var(--text-color);\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
