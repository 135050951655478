import { LANDING_CATEGORY_TAB } from "../actions/LandingCategoryTab";

const KEY_NAME = "psrp-services";

let services = localStorage?.getItem(KEY_NAME)
  ? JSON.parse(localStorage?.getItem(KEY_NAME) || {})
  : {};

const initialState = {
  landingCategoryActiveTab:
    Object.keys(services["individual"] || {}).length > 0
      ? 0
      : Object.keys(services["institution"] || {}).length > 0
      ? 1
      : undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANDING_CATEGORY_TAB:
      return {
        ...state,
        landingCategoryActiveTab: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
