import { connect } from "react-redux";
import "./styles.css";
import User from "./User";
import language from "../../../language";
import { Input } from "../../Input";
import icons from "../../../constants/icons";
import moment from "moment";
import React from "react";
import notificationQuery from "../../../utils/queries/notificationQuery";
import { getStorage } from "../../../utils/storage";
import socket from "../../../utils/socket/connection";
import { Button } from "../../Button";

class Reviewers extends React.Component {
  state = {
    isLoadingReviewedStatus: false,
    notification: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);

    socket.on("notification", async () => {
      this.getData(true);
    });
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, data: requestInfo } = this.props;

      this.setState({ isLoading });

      let paramsData = {
        lng: defaultLanguage,
        ["body._id"]: requestInfo._id,
      };

      const data = await notificationQuery(this, paramsData);

      this.setState({
        notification: data,
        isLoadingReviewedStatus: false,
      });
    } catch (error) {
      this.setState({
        isLoadingReviewedStatus: false,
      });
    }
  };

  handleActiveStep({ type, reviewer }) {
    let isActive = false;

    if (
      type === "approve" &&
      reviewer.actionAt !== "" &&
      reviewer.action === "Approval"
    ) {
      isActive = true;
    }

    if (
      type === "reject" &&
      reviewer.actionAt !== "" &&
      reviewer.action === "Rejection"
    ) {
      isActive = true;
    }

    return isActive;
  }

  render() {
    const steps = [
      {
        name: language[this.props.defaultLanguage].reviewed,
        icon: icons.eye_on,
        date: moment().format("lll"),
        type: "review",
      },
      {
        name: language[this.props.defaultLanguage].approved,
        icon: icons.success,
        date: moment().format("lll"),
        type: "approve",
      },
      {
        name: language[this.props.defaultLanguage].rejected,
        icon: icons.fail,
        date: moment().format("lll"),
        type: "reject",
      },
    ];
    return (
      <div className="reviewers-container">
        {this?.props?.reviewers?.map((reviewer, i) => {
          let position =
            reviewer.account?.position.length > 0
              ? reviewer.account?.position[0].name
              : "";
          let department =
            reviewer?.account?.department?.length > 0
              ? reviewer?.account?.department[0].name
              : "";

          return (
            <div key={i}>
              <User
                name={reviewer.account?.username}
                userTitle={department + " - " + position}
                institution={reviewer.account?.institution}
              />
              <div className="steps-container">
                {steps.map((elStep, stepIndex) => {
                  const isActive = this.handleActiveStep({
                    type: elStep.type,
                    reviewer,
                  });

                  return (
                    <div
                      className={`step-item-container ${
                        isActive ? "active" : ""
                      }`}
                      key={stepIndex}
                    >
                      <div className="icon-container">
                        <span className={process.env.REACT_APP_ICONS_TYPE}>
                          {elStep.icon}
                        </span>
                      </div>
                      <div className="step-item-name">
                        <p
                          className={`text-bold ${
                            elStep.status === "active" && "text-primary"
                          }`}
                        >
                          {elStep.name}
                        </p>
                        {isActive && (
                          <span>
                            {moment(reviewer.approvedAt).format("lll")}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {i + 1 !== this.props.reviewers.length && <hr />}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Reviewers);
