import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import departmentQuery from "../../utils/queries/departmentQuery";
import exportPDF from "../../utils/exportPDF";

class TableView extends React.Component {
  state = {
    csvData: [],
  };

  componentDidMount = async () => {};

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].id,
        key: "_id",
      },
      {
        title: language[this.props.defaultLanguage].count,
        key: "count",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      const { data } = this.props;

      this.setState({
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    const headers = this.returnTableHeaders();

    exportPDF(this.props.chartName || "Chart-data", headers, this.props.data);
  };

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    return (
      <div className="card-body">
        <Table
          data={this.props.data}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={this.props.chartName + "_" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TableView);
