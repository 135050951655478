import englishImage from "../assets/english.png";
import frenchImage from "../assets/french.png";
import kinyarwandaImage from "../assets/kinyarwanda.png";

const country = process.env.REACT_COUNTRY || "Rwanda";
const population = "Rwandaise";

const data = {
  english: {
    image: englishImage,
    login_text: "Log In",
    intro_title_01: "Welcome to Public Servant",
    intro_title_02: "Request Portal",
    intro_description:
      "Official gateway for public servants to submit service requests for processing and approval. Simplifying requests filing and tracking process.",
    search_service_text: "Search for service",
    signup: "Sign Up",
    individuals: "Individuals",
    institutions: "Institutions",
    home: "Home",
    request_service_title: "Request for a service",
    support_24_title: "24 / 7 Support",
    get_instant_notification_title: "Get Instant Notification",
    more: "More",
    less: "Less",
    receive_otp_description:
      "You will receive an OTP code to your email address",
    phone_number_or_email: "Phone number or Email Address",
    enter_phone_number_or_email: "Enter Phone number or Email address",
    dont_have_account: "Don't have an account?",
    register: "Register",
    enter_otp_received_on: "Enter OTP code received to ",
    verify_account: "Verify Account",
    phone_number_email_required: "Phone number or email address is required",
    invalid_phone_number_email:
      "Invalid input. Please enter a valid email address or phone number.",
    resend: "Resend",
    otp_code_six_digits: "OTP Code (6 Digits)",
    missing_phone_email:
      "Please provide a your email address or phone number, will sent you otp code for authentication",
    otp_code_required: "OTP code is required",
    otp_code_invalid: "Invalid OTP code",
    unable_to_verify_code:
      "Unable to verify your account please check your code and make sure you have used corrected OTP code, or resend to get new OTP code",
    identification_document_type_required:
      "Identification document type required",
    first_name_required: "First name is required",
    identification_number_required: "Identification number is required",
    all_fields_required: "All fields",
    required: "required",
    individual_services: "Individual Services",
    institution_services: "Institution Services",
    identification_document_type: "Identification Document Type",
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    name_placeholder: "Enter name",
    first_name_placeholder: "Enter first name",
    passport: "Passport",
    passport_placeholder: "Enter passport",
    national_id: `${country} National ID`,
    national_id_placeholder: "Enter national id",
    already_have_account: "Already have account?",
    select: "Select",
    passport_number: "Passport Number",
    passport_number_placeholder: "Enter your passport number",
    national_id_required: "Enter national required",
    invalid_national_id:
      "Invalid " +
      country +
      " national id, must be " +
      process.env.REACT_APP_NATIONAL_ID_LENGTH +
      "  digits",
    invalid_passport_number: "Invalid passport number",
    passport_number_required: "Passport number required",
    national_id_is_valid_and_is_fetching_info:
      "Fetching National Identity information, please wait...",
    account_created: "Account created successfully",
    settings: "Settings",
    dashboard: "Dashboard",
    my_profile: "My Profile",
    requests: "Requests",
    pending: "Pending",
    processing: "Processing",
    approved: "Approved",
    rejected: "Rejected",
    logout: "Logout",
    services: "Services",
    notifications: "Notifications",
    alerts: "Alerts",
    accounts: "Accounts",
    change_password: "Change Password",
    setting_description: "Manage public service request portal settings",
    new_password_placeholder: "Enter new password",
    new_password: "New Password",
    confirm_password_placeholder: "Enter password again",
    confirm_password: "Retype Password",
    change_password_success: "Password changed successfully",
    otp_code: "OTP Code",
    otp_code_will_be_sent_confirm_reset:
      "An OTP code will be sent to you for confirming the reset of your password.",
    reset_password: "Reset Password",
    send_otp: "Send OTP",
    enter_otp_code: "Enter OTP Code",
    departments: "Departments",
    roles_permission: "Roles & Permissions",
    departments_title: "Departments",
    departments_description: "Manage departments and positions",
    institution_name_placeholder: "Enter institution name",
    institution_name: "Institution name",
    add_institution: "Add Institution",
    edit_institution: "Edit Institution",
    delete_institution: "Delete Institution",
    export_: "Export",
    submit: "Submit",
    institution_name_required: "Institution name required",
    add_institution_success: "Institution  added successfully",
    department: "Department",
    add_department: "Add Department",
    delete_department: "Delete Department",
    edit_department: "Edit Department",
    department_name: "Department Name",
    department_name_placeholder: "Enter department name",
    department_name_required: "Department name required",
    add_department_success: "Department  added successfully",
    update_department_success: "Department updated successfully",
    error_add_department_success: "Unable to add department, please try again",
    error_update_department_success:
      "Unable to update department, please try again",
    error_delete_department_success:
      "Unable to delete department, please try again",
    role: "Role",
    add_role: "Add Role",
    role_name: "Role Name",
    role_name_placeholder: "Enter role name",
    role_name_required: "Role name required",
    add_role_success: "Role  added successfully",
    permission: "Permission",
    add_permission: "Add Permission",
    edit: "Edit",
    delete_: "Delete",
    cancel: "Cancel",
    institution: "Institution",
    unable_fetch_institutions: "Unable to fetch institutions",
    unable_fetch_departments: "Unable to fetch departments",
    services_title: "Services",
    services_description:
      "Manage services that are to be requested by public services",
    add_service: "Add a service",
    individual: "Individual",
    edit_service: "Edit Service",
    delete_service: "Delete Service",
    service_name: "Service Name",
    service_name_placeholder: "Enter service name",
    service_name_required: "Service name required",
    category: "Category",
    categories: "Categories",
    filter_by_category: "Filter by category",
    add_translation: "Add Translation",
    max_number_days: "Maximum Response Time",
    remove_translation: "Remove Translation",
    maxDays_required: "Max. Number of days required",
    category_required: "Category required",
    required_documents: "Required Documents",
    data_t_fill: "Questionnaire",
    translations: "Translations",
    signatures: "Signatures",
    approval_levels: "Approvals",
    output_letter: "Output Letter",
    publish: "Publish",
    avg_response_time: "Avg. Response time",
    avg_response_time_abbr: "Avg.RT",
    add_document: "Add document",
    edit_document: "Edit document",
    delete_document: "Delete document",
    document_name_required: "Document name required",
    document_name: "Document Name",
    file_extension: "File Extension",
    save: "Save",
    required_document: "Required document",
    size_limit_required: "Size limit required",
    file_extension_required: "File extension required",
    data_title: "Data Title",
    input_type: "Input Type",
    input_requirement: "Input Requirement",
    created_at: "Created At",
    actions: "Actions",
    add_data: "Add Data",
    delete: "Delete Data",
    edit_data: "Edit Data",
    data_title_required: "Data title required",
    input_type_required: "Input type required",
    input_requirement_required: "Input requirement required",
    data_title_placeholder: "Enter Data Title",
    mandatory: "Mandatory",
    optional: "Optional",
    text: "Text",
    checkbox: "Checkbox",
    translation: "Translation",
    default_translation: "Default text",
    language: "Language",
    size_limit: "Size limit",
    email_address: "Email Address",
    enter_email: "Enter Email Address",
    password: "Password",
    enter_password: "Enter Password",
    email_required: "Email address required",
    password_required: "Password required",
    wrong_email_password: "Wrong email address or password",
    invalid_email: "Invalid email address",
    success_delete_department: "Department Deleted successfully",
    job_positions: "Job Positions",
    position_name: "Position Name",
    add_position_success: "Position added successfully",
    error_add_position: "Unable to add position",
    update_position_success: "Position updated successfully",
    error_update_position: "Unable to update position",
    error_delete_position: "Unable to update position",
    unable_fetch_positions: "Unable to fetch positions",
    add_job_position: "Add Position",
    delete_job_position: "Delete Position",
    edit_job_position: "Edit Position",
    job_position: "Job position",
    job_position_name: "Job Position Name",
    available: "Available",
    job_position_name_placeholder: "Enter Job Position Name",
    job_position_name_required: "Job Position Name Required",
    forgot_password: "Forgot Password?",
    login_title: "Login",
    new_password_required: "New password required",
    different_password_required: "Password must match",
    confirm_password_required: "Retype new password",
    unable_reset_password:
      "Unable to reset password, make sure otp sent is valid and try again",
    reset_password_title: "Reset Password",
    verify: "Verify",
    account_nt_exist: "Account does not exist",
    employee_id: "Employee ID",
    employee_id_placeholder: "Enter Employment ID",
    invalid_employee_id: "Invalid Employment ID",
    employee_required: "Employment ID is required",
    employee_is_valid_and_is_fetching_info:
      "Fetching info for employee, please wait...",
    resend_otp: "Resend OTP Code",
    back_text: "Back",
    accounts_title: "Accounts",
    accounts_description: "Manage users accounts",
    ippisNumber: "Employee ID",
    access_role: "Access Role",
    unable_fetch_accounts: "Unable to fetch accounts",
    add_account: "Add Account",
    edit_account: "Edit Account",
    delete_account: "Delete Account",
    account: "Account",
    names: "Names",
    name: "Name",
    names_placeholder: "Enter Names",
    error_add_account:
      "Unable to add account, make sure you have a valid first name and employee ID",
    error_update_account: "Unable to update account",
    add_account_success: "Account added successfully",
    update_account_success: "Account updated successfully",
    ippis_number: "Employee ID",
    ippis_number_placeholder: "Enter Employee ID",
    ippis_number_required: "Employee ID is required",
    account_info: "Account Information",
    access_roles: "Access Roles",
    unable_fetch_access_role: "Unable to fetch access roles",
    success_delete_access_role: "Access Roles deleted successfully",
    error_delete_access_role: "Unable to delete access roles",
    add_access_role: "Add access role",
    edit_access_role: "Edit Access Role",
    delete_access_role: "Delete Access Role",
    access_role_name_placeholder: "Enter Access Role Name",
    operations: "Operations",
    menus: "Menus",
    access_role_name_required: "Access Role Name Required",
    access_role_menus_required: "Please select Accessed menus",
    error_add_access_role: "Unable to add Access Role",
    error_update_access_role: "Unable to update Access Role",
    add_access_role_success: "Access Role added successfully",
    update_access_role_success: "Access role updated successfully",
    view_: "View",
    account_name_required: "Account Name Required",
    username_required: "Username Required",
    username_placeholder: "Enter user name",
    username: "User Name",
    department_required: "Department Required",
    job_position_required: "Job position Required",
    access_role_required: "Access Role Required",
    email_placeholder: "Enter Email address",
    status: "Status",
    password_placeholder: "Enter Password",
    positions: "Positions",
    request_title: "Requests",
    individual_requests: "Individual Requests",
    institution_requests: "Institution Requests",
    request_description: "Manage public servant requests",
    unable_fetch_requests: "Unable Fetch  Requests",
    service_categories: "Service Categories",
    description: "Description",
    type: "Type",
    add_service_category: "Add Service Category",
    edit_service_category: "Edit Service Category",
    delete_service_category: "Delete Service Category",
    service_category: "Service Category",
    service_category_name: "Service Category Name",
    service_category_name_placeholder: "Enter Service Category Name",
    service_category_description: "Service Category Description",
    service_category_description_placeholder:
      "Enter Service Category Description",
    error_add_service_category: "Unable to add service category",
    error_update_service_category: "Unable to update service category",
    add_service_category_success: "Service Category Added Successfully",
    update_service_category_success: "Service Category Updated Successfully",
    service_category_type_required: "Service Category Type Required",
    service_category_name_required: "Service Category Name Required",
    service_Category_type: "Service Category Type",
    success_delete_service: "Service deleted successfully",
    unable_fetch_services: "Unable to fetch services",
    error_delete_service: "Unable to delete service",
    two_factor_authentication_description:
      "You will be able to login with OTP(One Time Password) next time you log in",
    two_factor_authentication_label: "2FA Authentication",
    roles_and_permissions: "Roles & permissions",
    two_factor_tab_name: "2FA Authentication",
    required_document_name: "Document Name",
    accepted_file_extension: "Accepted File Extension",
    size_limit_measurement: "Size Limit Measurement",
    size_limit_placeholder: "Enter a size limit",
    service_is_missing: "Service is missing",
    add_required_document_success: "Required document added successfully",
    update_required_document_success: "Required document updated successfully",
    error_add_required_document: "Unable to add required document",
    error_update_required_document: "Unable to update required document",
    text_input: "Text input",
    dropdown: "Dropdown",
    signature_owner: "Signature Owner",
    owner_account_required: "Owner Account is required",
    add_signature_success: "Signature added successfully",
    update_signature_success: "Signature updated successfully",
    error_add_signature: "Unable to add signature",
    owner_name: "Owner Name",
    upload_signature: "Upload Signature",
    add_signature: "Add Signature",
    edit_signature: "Edit Signature",
    delete_signature: "Delete Signature",
    signature: "Signature",
    level_name: "Level Name",
    level_order: "Level Order",
    level_name_placeholder: "Enter Level Name",
    level_order_placeholder: "Enter Level Order",
    unable_fetch_service_category: "Unable to fetch service category",
    institution_required: "Institution name is Required",
    add_service_error: "Unable to add service",
    update_service_error: "Unable to update service",
    add_service_success: "Service added successfully",
    update_service_success: "Service updated successfully",
    success_delete_service_category: "Service category deleted successfully",
    error_delete_service_category: "Unable to delete service category",
    institution_placeholder: "Enter institution name",
    service_description_required: "Service description required",
    service_description: "Service description",
    service_description_placeholder: "Enter service description",
    service: "Service",
    document_name_placeholder: "Enter document name",
    delete_required_document_success: "Required document deleted successfully",
    error_delete_required_document: "Unable to delete service document",
    question: "Question",
    answer_type: "Answer Type",
    question_placeholder: "Enter question text",
    answer_requirement: "Answer requirement",
    question_required: "Question is required",
    multiple_choice: "Multiple Choice",
    add_choice: "Add Choice",
    choice_name_placeholder: "Enter choice name",
    add_questionnaire: "Add Questionnaire",
    edit_questionnaire: "Edit Questionnaire",
    delete_questionnaire: "Delete Questionnaire",
    questionnaire: "Questionnaire",
    delete_questionnaire_success: "Questionnaire deleted successfully",
    error_delete_questionnaire: "Unable to delete Questionnaire",
    delete_level_success: "Level Added successfully",
    error_delete_level: "Level Deleted successfully",
    add_level: "Add Level",
    edit_level: "Edit Level",
    delete_level: "Delete Level",
    approval_level: "Approval Level",
    add_approver_success: "Approver submitted successfully",
    error_add_approver: "Unable to submit approver",
    view_template: "View Template",
    approver: "Approver",
    add_approver: "Add Approver",
    delete_approver: "Delete Approver",
    edit_approver: "Edit Approver",
    delete_approver_success: "Approver deleted successfully",
    error_delete_approver: "Unable to delete Approver",
    position_not_find: "position does not found",
    default_placeholder_input: "Enter ",
    upload_documents_below: "Upload documents below",
    success_add_request: "Request has been submitted successfully",
    error_add_request: "Unable to submit your request, please try again later",
    pending_30_days: "Pending 30 Days+",
    pending_90_days: "Pending 90 Days+",
    alerts_title: "Alerts",
    alerts_description: "Manage stuck or abandoned public requests",
    error_user_sign_up: "Unable to register your account, please try again",
    employee_id_not_find: "Employee ID not found",
    date_requested: "Date Requested",
    request_information: "Request Information",
    comments: "Comments",
    current_department: "Current Department",
    assignee: "Assignee",
    notifications_title: "Notifications",
    retry: "Retry",
    error_retrieving_user_info: "Unable to retrieve user info",
    invalid_email_required: "Invalid email",
    request_name: "Request Name",
    document_attached: "Documents Attached",
    request_number: "Request Number",
    avg_wait_time: "Avg Wait Time",
    approved_at: "Approved At",
    error_fetch_approvers: "Unable to fetch Approvers",
    add_approval_level: "Add Approval Level",
    level: "Level",
    level_placeholder: "Enter Approval Level",
    approver_required: "Approver account is required",
    level_required: "Level is required",
    add_approval_level_success: "Approval Level added successfully",
    update_approval_level_success: "Approval Level updated successfully",
    delete_approval_level_success: "Approval Level deleted successfully",
    update_approval_level_error: "Unable to update Approval Level",
    add_approval_level_error: "Unable to add Approval Level",
    delete_approval_level_error: "Unable to delete Approval Level",
    edit_approval_level: "Edit Approval Level",
    delete_approval_level: "Delete Approval Level",
    approver_exist: "Approver already exists",
    answer: "Answer",
    document: "Document",
    file_type: "File Type",
    error_delete_account: "Unable to delete account",
    success_delete_account: "Account deleted successfully",
    department_not_exist: "Department does not exist",
    job_position_placeholder: "Enter job position",
    last_name_placeholder: "Enter last name",
    username_required: "User name is required",
    approved: "Approved",
    rejected: "Rejected",
    reason: "Reason",
    unable_find_request: "Unable too find the request, please try again",
    not_allowed_approve_request: "You are not allowed to approve this request",
    unable_to_approve_request:
      "Unable to approve this request, please try again",
    not_allowed_reject_request: "You are not allowed to approve this request",
    unable_to_reject_request: "Unable to reject this request",
    my_requests: "My Requests",
    unable_fetch_notifications: "Unable to fetch notifications",
    unable_fetch_alerts: "Unable to fetch alerts",
    error_update_notifications: "Unable to update notification",
    notification: "Notification Body",
    date_time: "Date & Time",
    unread_notification: "Unread Notifications",
    read_notification: "Read Notifications",
    user_login: "User Login",
    admin_login: "Admin Login",
    admin: "Admin",
    phone_number: "Phone Number",
    recent_institution_requests: "Recent Institution Requests",
    recent_individual_requests: "Recent Individual Requests",
    number: "Number",
    date_time: "Date & Time",
    multiple_line_text: "Multiple Line Text Input",
    field_name: "Field",
    field_name_placeholder: "Enter field name",
    multi_answer: "Multiple Answer",
    value_type: "Value Type",
    add_object: "Add Object",
    object_title: "Object Title",
    object_title_placeholder: "Enter object title",
    main_search_placeholder: "Search Employee ID, Request Number...",
    filter_by_department: "Filter by Department",
    total_counts: "Total Requests",
    total_processed_7: "Processed with 7 days",
    total_processed_15: "Processed above 15 days",
    total_processed_30: "Processed above 30 days",
    unable_fetch_analytics: "Unable to fetch analytics",
    view_application: "View this application",
    daily_pending_requests: "Daily Pending Requests",
    no_data: "No Data",
    total_daily_pending_requests: "Total Daily Pending Requests",
    total_monthly_requests: "Total Monthly Pending Requests",
    monthly_requests: "Monthly Pending Requests",
    weekly_requests: "Weekly Pending Requests",
    filter_by_service: "Filter by service",
    date_range: "Date Range",
    filter_by_date: "Filter by date",
    total_weekly_requests: "Total Weekly Pending Requests",
    individual_max_response_time_required:
      "Individual Max Response Time is Required",
    individual_max_response_time: "Individual Max Response Time - Day(s)",
    individual_max_response_time_placeholder:
      "Enter individual Max Response Time",
    sub_approvers: "Sub Approvers",
    sub_approver: "Sub Approver",
    approvers: "Approvers",
    outside_organization_title: "Outside Organization",
    outside_organization_description:
      "If this account is not part of your organization's internal network.",
    active_accounts: "Active Accounts",
    inactive_accounts: "Inactive Accounts",
    account_already_exist: "Accounts already exist",
    level_already_taken: "Level already taken",
    sub_approvals: "Sub Approvals",
    alerts: "Alerts",
    outside_organization: "Outside Organization",
    out_of: "out of",
    day_of_week: "Day of Week Pending Requests",
    total_day_of_week_requests: "Total Day Of Week Requests",
    requests_per_category: "Requests Per Category",
    total_requests_per_category: "Total Requests Per Category",
    total_pending_7: "Pending above 7 days",
    total_pending_15: "Pending above 15 days",
    total_pending_30: "Pending above 30 days",
    approved_at: "Approved at",
    approved: "Approved",
    requester: "Requester",
    filter__by_institution: "Filter by institution",
    reset_filters: "Reset filters",
    clear: "Clear",
    or_upload_signature_image: "Or Upload Signature",
    upload_img: "Upload Image",
    otp_code_placeholder: "Enter OTP Code",
    send_otp_success: "OTP code has been sent to",
    unable_to_add_signature: "Unable to add signature",
    something_went_wrong: "Something went wrong",
    add_signature_success: "Signature has been added successfully",
    copy: "Copy",
    copied: "Copied",
    digital_key: "Digital Key",
    digital_key_placeholder: "Key here",
    upload_digital_signature: "Upload Digital Signature",
    invalid_signature: "Your signature is invalid",
    upload_your_digital_signature: "Upload your digital signature",
    upload_your_digital_signature_description:
      "You are going to sign with your digital signature, make sure you have provided a valid signature",
    validate: "Validate",
    signature_required: "Signature Required",
    upload_signature: "Upload Signature",
    sign: "Sign",
    invalid_signature: "Invalid Signature",
    document_signed_title: "",
    document_signed_description: "",
    sign_and_approved: "Sign and Approve",
    please_fill_the_form_below: "Fill the form Below",
    letter_title: "Letter Title",
    letter_title_placeholder: "Enter a letter title",
    yes: "Yes",
    no: "No",
    not_allowed_sign_request: "You are not allowed to sign this request",
    reason: "Reason",
    comment: "Comment",
    rejected_reason_placeholder: "Enter a reason here",
    approved_reason_placeholder: "Leave a comment",
    choose_services: "Choose Service",
    remainingDays: "Remaining",
    individual_max_response_time_high_remaining_day:
      "Individual max response time high than the remaining days",
    add_sub_approver: "Add Sub-Approver",
    days: "day(s)",
    individual_max_response_time_must_less_than:
      "Must be less than or equal to",
    today: "Today",
    this_week: "This Week",
    this_month: "This Month",
    outside_organization_accounts: "Outside Organization Accounts",
    sub_approval_status: "Sub Approval Status",
    no_form_here: "No form found",
    questionnaire_answers: "Questionnaire Answers",
    attachments: "Attachments",
    download: "Download",
    reviewed: "Reviewed",
    write_message: "Write message...",
    approvals: "Approvals",
    comment_required: "Comment is required",
    comment_send_sccuess: "Comment has been sent successfully",
    unable_send_comment: "Unable to send comment, try again",
    max_number_days_placeholder: "Enter a number of day(s)",
    approve: "Approve",
    reject: "Reject",
    initiator: "Initiator",
    comments_and_approvals: "Comments and Approvals",
    write_comment: "Write a comment...",
    sub_approvals_logs: "Sub Approvals Logs",
    approvals_logs: "Approvals Logs",
    date: "Date",
    time: "Time",
    email_has_been_sent_to: "Email has been sent to ",
    otp_code_required: "OTP code required",
    enter_otp_code_received_to_email: "Enter otp code received to",
    reset_password_success: "Reset password successfully",
    invalid_otp_code: "Invalid otp code",
    count: "Count",
    id: "ID",

    account_exist: "Account already exists",
    access_role_exist: "Access role already exists",
    service_exist: "Service already exists",
    service_category_exist: "Service category already exists",
    names: "Names",
    request_reset_password: "Request reset password",
    password_must_match: "Password must match",
    confirm_password_required: "Confirm password is required",
    invalid_password: "Invalid password",
    department_exists: "Department already exists",
    job_position_exists: "Job position already exists",
    password_must: "Password must contain",
    password_min_error: "Minimum 8 characters",
    password_contains_uppercase_letter: "At least one uppercase letter",
    password_contains__lowercase_letter: "At least one lowercase letter",
    password_contains_number: "At lease one number.",
    successfully: "successfully",
    letter_subject: "Letter subject",
    letter_subject_placeholder: "Enter a letter subject",
    letter_body: "Letter body",
    letter_body_placeholder: "Enter a letter body",
    attach_file: "Attach file",
    attachment_title: "Attachment title",
    file: "File",
    title: "Title",
    position: "Position",
    ippis_units: "IPPIS-Units",
    ippis_levels: "IPPIS-Levels",
    ippis_scales: "IPPIS-Scales",
    ippis_source_funds: "IPPIS Source of Funds",
    ippis_payroll_groups: "IPPIS Payroll Group",
    ippis_cost_centers: "IPPIS Cost Centers",
    choice_option_type_placeholder: "Type",
    choice_item: "Choice Item",
    choice_item_placeholder: "Enter Item name",
    choice_item_name: "Choice Item Name",
    unable_to_fetch_ippis_units:
      "Retrieving IPPIS units has encountered an issue.",
    unable_to_fetch_ippis_levels:
      "Retrieving IPPIS levels has encountered an issue.",
    unable_to_fetch_ippis_scales:
      "Retrieving IPPIS scales has encountered an issue.",
    unable_to_fetch_ippis_cost_center:
      "Retrieving IPPIS cost centers has encountered an issue.",
    unable_to_fetch_ippis_payroll_group:
      "Retrieving IPPIS payroll group has encountered an issue.",
    unable_to_fetch_ippis_source_fund:
      "Retrieving IPPIS  source fund has encountered an issue.",
    unable_to_fetch_ippis_info:
      "Retrieving IPPIS  source fund information has encountered an issue, contact your administrator",
    integration: "Integration",
    api_url: "API URL",
    dropdown_field_name: "Dropdown Field Name",
    dropdown_field_value: "Dropdown Field Value",
    dropdown_field_name_placeholder: "Object Key",
    dropdown_field_value_placeholder: "Object Value",
    api_url_placeholder: "Enter API full URL",
    item_name_required: "Item name is required",
    api_url_required: "API URL is required",
    api_url_invalid: "API URL is invalid, must start with http or https",
    field_name_required: "Field name is required",
    field_value_required: "Field value is required",
    response_key_name_placeholder: "Enter response key name",
    response_key_name: "Response Key Name",
    api_method_placeholder: "Enter API method",
    api_method: "API Method",
    api_method_required: "API Method is required",
    api_params_placeholder: "Enter Api Parameters",
    api_params: "Api Params",
    api_params_description: "Multiple API parameters are separated by commas",
    file_required: "File is required",
    file_title_required: "File title is required",
    file_title_placeholder: "Enter File title",
    close: "Close",
    unable_to_upload_file: "Unable to upload file",
    unable_fetch_user_guide:
      "Unable to fetch user guide, Please make sure you have logged in successfully and refresh the page",
    document_required: "Document is required",
    document_exceed_limit:
      "File upload failed. The file size exceeds the allowed limit of 3MB",
    limit_files: "File upload failed. Only 2 files are allowed per upload",
    exceed_file_size_limit: "File size exceeds the allowed limit of 3MB",
    account_role: "Account Role",
    update_questionnaire_success: "Updated questionnaire successfully",
    added_questionnaire_success: "Added questionnaire successfully",
    error_update_questionnaire: "Unable to update questionnaire",
    error_add_questionnaire: "Unable to add questionnaire",
    first_name: "First Name",
    last_name: "Last Name",
    go_back: "Go Back",
    load_more: "Load More",
    post_name: "Post Name",
    number_of_posts: "Number of Posts",
    webhook: "Webhooks",
    service_webhook: "Service Webhook URLs",
    webhook_body: "Webhook Body (Array)",
    url_required: "URL is required",
    url: "URL",
    action: "Action",
    webhook_url_placeholder: "Enter webhook URL",
    webhook_action_placeholder: "Enter webhook action",
    webhook_url_required: "URL is required",
    webhook_added_success: "Webhook added successfully",
    add: "Add",
    remove: "Remove",
    field_is_required: "This field is required",
    ippis_role_code: "IPPIS Role Code",
    ippis_role_code_placeholder: "Enter IPPIS Role Code",
    ippis_role_code_required: "IPPIS Role Code is required",
    account_type_required: "Account type is required",
    account_type: "Account Type",
    account_type_placeholder: "Enter an account type",
    account_type_required: "Account type is required",
    from_institution: "From Institution",
    api_configuration: "API Configuration",
    not_allowed_to_apply: "You are not allowed to apply this service",
    consult_with_admin:
      "Consult with your Human Resources Management / your system administrator",
    ippis_auth: "IPPIS Authentication",
    provide_ippis_account:
      "Please ensure that you have the correct IPPIS access credentials and that you utilize the same account for authentication",
    ippis_login: "IPPIS Login",
    request: "Request",
    approver_required:
      "An approver is required, please choose account or access role of approver",
    sub_approver_already_exist: "Sub approver is already exist",
    approver_already_exist: "Approver is already exist",
    actions: "Actions",
    more: "More",
    request_changes: "Request Changes",
    not_allowed_update_request: "Not allowed Update Request",
    unable_to_update_request: "Unable to Update Request",
    request_Changes_placeholder: "Enter requested changes",
    request_sent_success: "Request sent successfully",
    cant_send_empty_request:
      "Request changes should requires a message to be sent, please write a message",
    all: "All",
    edit_request: "Edit Request",
    account_institution: "Account Institution",
    access_role_institution: "Access Role Institution",
    my_requests: "My Requests",
    required: "Required",
    is_required: "is required",
    organization_accounts: "Organization Accounts",
  },
  // french: {
  //   image: frenchImage,
  //   login_text: "Se connecter",
  //   intro_title_01: "Bienvenue sur Public Servant",
  //   intro_title_02: "Requête Portail",
  //   intro_description:
  //     "Lorem ipsum dolor sit amet consectetur. Morbi enim lacus pharetra vitae libero. Integer sed aliquam sit sit varius facilisis.",
  //   search_service_text: "Rechercher un service",
  //   signup: "S'inscrire",
  //   individuals: "Personne",
  //   institutions: "Institution",
  //   home: "Page d'accueil",
  //   request_service_title: "Requête de service",
  //   support_24_title: "24 / 7 Support",
  //   get_instant_notification_title: "Obtenir Notification instantanée",
  //   more: "Plus",
  //   less: "Moins",
  //   receive_otp_description:
  //     "Vous recevrez un code OTP sur votre adresse e-mail",
  //   phone_number_or_email: "Numéro de téléphone ou adresse e-mail",
  //   enter_phone_number_or_email:
  //     "Entrez le numéro de téléphone ou l'adresse e-mail",
  //   dont_have_account: "Vous n'avez pas de compte ?",
  //   register: "Registre",
  //   enter_otp_received_on: "Entrez le code OTP reçu le",
  //   verify_account: "Vérifier le compte",
  //   phone_number_email_required:
  //     "Un numéro de téléphone ou une adresse e-mail est requis",
  //   invalid_phone_number_email:
  //     "Entrée invalide. Veuillez saisir une adresse e-mail ou un numéro de téléphone valide.",
  //   resend: "Renvoyer",
  //   otp_code_six_digits: "Code OTP (6 chiffres)",
  //   missing_phone_email:
  //     "Veuillez fournir votre adresse e-mail ou votre numéro de téléphone, nous vous enverrons un code OTP pour l'authentification",
  //   unable_to_verify_code:
  //     "Impossible de vérifier votre compte, veuillez vérifier votre code et assurez-vous que vous avez utilisé le code OTP corrigé, ou renvoyez-le pour obtenir un nouveau code OTP.",
  //   otp_code_required: "Code OTP requis",
  //   otp_code_invalid: "Code OTP invalide",
  //   identification_document_type_required:
  //     "Type de document d'identification requis",
  //   first_name_required: "Prénom requis",
  //   identification_number_required: "Numéro d'identification requis",
  //   all_fields_required: "Tous les champs",
  //   required: "obligatoire",
  //   individual_services: "Services Individuels",
  //   institution_services: "Services Institutionnels",
  //   identification_document_type: "Type de document d'identification",
  //   firstName: "Prénom",
  //   first_name_placeholder: "Entrez votre prénom",
  //   passport: "Passeport",
  //   passport_placeholder: "Entrez le passeport",
  //   national_id: "Identité nationale du " + country,
  //   national_id_placeholder: "Entrez votre carte d'identité nationale",
  //   already_have_account: "Vous avez déjà un compte?",
  //   select: "Sélectionner",
  //   passport_number_placeholder: "Entrez votre numéro de passeport",
  //   invalid_national_id: `Carte d'identité ${population} invalide, doit comporter ${process.env.REACT_NATIONAL_ID_LENGTH} chiffres`,
  //   invalid_passport_number: `Numero de passeport invalide, doit comporter`,
  //   passport_number_required: "Numéro de passeport requis",
  //   national_id_is_valid_and_is_fetching_info:
  //     "Récupération des informations d'identité nationale, veuillez patienter...",
  //   account_created: "Compte créé avec succès",
  //   settings: "Paramètres",
  //   dashboard: "Tableau de bord",
  //   my_profile: "Mon profil",
  //   requests: "Requêtes",
  //   pending: "En attente",
  //   processing: "En traitement",
  //   approved: "Approuvée",
  //   rejected: "Refusée",
  //   logout: "Déconnexion",
  //   services: "Services",
  //   notifications: "Notifications",
  //   alerts: "Alerte",
  //   accounts: "Comptes",
  //   back: "Retour",
  //   next: "Suivant",
  //   previous: "Précédent",
  //   submit: "Soumettre",
  //   cancel: "Annuler",
  //   change_password: "Changer le mot de passe",
  //   setting_description:
  //     "Gérer les paramètres du portail de demandes de service public",
  //   new_password_placeholder: "Entrez un nouveau mot de passe",
  //   new_password: "Nouveau mot de passe",
  //   confirm_password_placeholder: "Entrez mot de passe",
  //   confirm_password: "Confirmer à nouveau le mot de passe",
  //   change_password_success: "Mot de passe changé avec succès",
  //   reset_password: "réinitialiser le mot de passe",
  //   send_otp: "Envoyer OTP",
  //   enter_otp_code: "Entrez le code OTP",
  //   departments: "Départements",
  //   roles_permission: "Rôles et Permissions",
  //   institutions_title: "Institutions",
  //   institutions_description: "Gérer les institutions, départements et rôles",
  //   institution_name_placeholder: "Entrez le nom de l'institution",
  //   institution_name: "Nom de l'institution",
  //   add_institution: "Ajouter l'institution",
  //   edit_institution: "Modifier l'institution",
  //   delete_institution: "Supprimer l'institution",
  //   export_: "Exporter",
  //   institution_name_required: "Nom de l'institution requis",
  //   add_institution_success: "Institution ajoutée avec succès",
  //   department_name: "Nom du département",
  //   department_name_placeholder: "Entrez le nom du département",
  //   department_name_required: "Nom du département requis",
  //   add_department: "Ajouter le département",
  //   delete_department: "Supprimer le département",
  //   edit_department: "Modifier le département",
  //   unable_fetch_institutions: "Impossible de récupérer les institutions",
  //   unable_fetch_departments: "Impossible de récupérer les départements",
  //   services_title: "Service",
  //   services_description:
  //     "Gérer les services qui doivent être demandés par les services publics",
  //   add_service: "Ajouter un service",
  //   edit_service: "Modifier un service",
  //   delete_service: "Supprimer un service",
  //   service_name: "Nom du service",
  //   service_name_placeholder: "Entrez le nom du service",
  //   service_name_required: "Nom du service requis",
  //   add_service_success: "Service ajouté avec succès",
  //   edit_service_success: "Service modifié avec succès",
  //   delete_service_success: "Service supprimé avec succès",
  //   category: "Catégorie",
  //   add_translation: "Ajouter une traduction",
  //   max_number_days: "Max. Nombre de jours",
  //   remove_translation: "Supprimer la traduction",
  //   maxDays_required: "Max. Nombre de jours requis",
  //   category_required: "Catégorie requise",
  //   data_t_fill: "Données à remplir",
  //   translations: "Traductions",
  //   signatures: "Signature",
  //   approval_levels: "Niveaux d'approbation",
  //   output_letter: "Lettre de sortie",
  //   publish: "Publier",
  //   avg_response_time: "Moyenne Temps de réponse",
  //   avg_response_time_placeholder: "Entrez le temps moyen de réponse",
  //   avg_response_time_required: "Moyenne Temps de réponse requis",
  //   add_document: "Ajouter un document",
  //   edit_document: "Modifier le document",
  //   delete_document: "Supprimer le document",
  //   document_name: "Nom du document",
  //   file_extension: "Extension de fichier",
  //   save: "Sauvegarder",
  //   required_document: "Document requis",
  //   size_limit_required: "Limite de taille requise",
  //   file_extension_required: "Extension de fichier requise",
  //   document_name_required: "Nom du document requis",
  //   data_title: "Titre des données    ",
  //   input_type: "Type d'entrée",
  //   input_requirement: "Exigence de saisie    ",
  //   created_at: "Créé à    ",
  //   actions: "Actions",
  //   add_data: "Ajouter des données    ",
  //   delete: "Suprimmer les données",
  //   edit_data: "Modifier les données",
  //   data_title_required: "Titre des données requis",
  //   input_type_required: "Type d'entrée requis",
  //   input_requirement_required: "Exigence de saisie requise",
  //   mandatory: "Obligatoire",
  //   optional: "Facultatif",
  //   data_title_placeholder: "Entrez le titre des données",
  //   text: "Texte",
  //   checkbox: "Case à cocher    ",
  //   translation: "Traduction",
  //   default_translation: "Texte par défaut    ",
  //   language: "Langue",
  //   required_documents: "Documents requis",
  //   email_address: "Adresse E-mail",
  //   password: "Mot de passe",
  //   enter_password: "Entrer le mot de passe",
  //   email_required: "Adresse e-mail requis",
  //   password_required: "Mot de passe requis",
  //   wrong_email_password: "Mauvaise adresse e-mail ou mot de passe, réessayez",
  //   invalid_email: "Adresse e-mail invalide",
  //   update_department_success: "Département mis à jour avec succès",
  //   error_add_department_success:
  //     "Impossible d'ajouter un département, veuillez réessayer",
  //   error_update_department_success:
  //     "Impossible de mettre à jour le département, veuillez réessayer",
  //   error_delete_department_success:
  //     "Impossible de supprimer le département, veuillez réessayer",
  //   success_delete_department: "Département supprimé avec succès",
  //   job_positions: "Postes",
  //   add_position_success: "Poste ajouté avec succès",
  //   error_add_position: "Impossible d'ajouter un poste",
  //   update_position_success: "Poste mis à jour avec succès",
  //   error_update_position: "Impossible de mettre à jour la position",
  //   error_delete_position: "Impossible de supprimer le poste",
  //   unable_fetch_positions: "Impossible de récupérer les positions",
  //   add_job_position: "Ajouter un poste",
  //   delete_job_position: "Supprimer le poste",
  //   edit_job_position: "Modifier le poste",
  //   job_position: "Poste",
  //   job_position_name: "Nom du poste",
  //   available: "Disponible",
  //   job_position_name_placeholder: "Entrez le nom du poste",
  //   job_position_name_required: "Le nom du poste est requis",
  //   forgot_password: "Mot de passe oublié",
  // },
  // kinyarwanda: {
  //   image: kinyarwandaImage,
  //   login_text: "Injira",
  //   intro_title_01: "Murakaza neza kuri portal",
  //   intro_title_02: "yabakozi ba Leta",
  //   intro_description:
  //     "Lorem ipsum dolor sit amet consectetur. Morbi enim lacus pharetra vitae libero. Integer sed aliquam sit sit varius facilisis.",
  //   search_service_text: "Shakisha serivisi",
  //   signup: "Iyandikishe",
  //   individuals: "Umuntu ku giti cye",
  //   institutions: "Ikigo",
  //   home: "Ahabanza",
  //   request_service_title: "Gusaba serivisi",
  //   support_24_title: "Tutanga ubufasha 24 / 7",
  //   get_instant_notification_title: "Tukumenyeshwa Byihuse",
  //   more: "Reba Byose",
  //   less: "Reba Bicye",
  //   receive_otp_description:
  //     "Uracyira code (OTP) igufasha kwinjira kuri number ya terefone cg imeli yawe",
  //   phone_number_or_email: "Nimero ya terefone cg imeli",
  //   enter_phone_number_or_email: "Injiza nimero ya terefone cg imeli",
  //   dont_have_account: "Nta konte ufite?",
  //   register: "Iyandikishe",
  //   enter_otp_received_on: "Injiza kode ya OTP yakiriwe kuri",
  //   verify_account: "Kugenzura Konti",
  //   phone_number_email_required:
  //     "Numero ya terefone cyangwa aderesi imeri irakenewe",
  //   invalid_phone_number_email:
  //     "Ibyo wacyizemo sibyo. injizamo imeli cg terelephone biribyo",
  //   resend: "Uhereza Kode",
  //   otp_code_six_digits: "OTP (imibare 6)",
  //   missing_phone_email:
  //     "Ohereza imeli cg number ya telephone kugirango wakire kode yo kwinjira",
  //   unable_to_verify_code: "Nibishobotse ",
  //   otp_code_required: "OTP kugirango wakire",
  //   otp_code_invalid: "OTP kugirango wakire",
  //   identification_document_type_required: "Hitamo ubwoko bwikikuranga",
  //   first_name_required: "Izina rirakenewe",
  //   identification_number_required: "",
  //   all_fields_required: "Uzuza ibisabwa aho ubona ",
  //   required: "harakenewe",
  //   individual_services: "Servise z'umuntu kugiti cye",
  //   institution_services: "Servise z'ibigo",
  //   identification_document_type: "Hitamo ubwoko bwikikuranga",
  //   firstName: "Izina",
  //   first_name_placeholder: "Injiza izina ryawe",
  //   passport: "Pasiporo",
  //   passport_placeholder: "Injizamo numbero ya pasiporo yawe",
  //   national_id: "Indangamuntu yu " + country,
  //   national_id_placeholder: "Injizamo numero y'indangamuntu",
  //   already_have_account: "Ufite konti?",
  //   select: "Hitamo",
  //   passport_number_placeholder: "Injizamo numbero ya pasiporo",
  //   invalid_national_id: `Indangamuntu ntabwo ariyo, shyiramo imibare ${process.env.REACT_NATIONAL_ID_LENGTH}`,
  //   invalid_passport_number: `Pasiporo yawe ntabwo ariyo`,
  //   passport_number_required: "Injizamo pasiporo yawe",
  //   national_id_is_valid_and_is_fetching_info:
  //     "Indangamuntu yawe niyo, ihangane gate tubone amakuru yawe",
  //   account_created: "Konti yawe yafunguwe",
  //   settings: "Igenamiterere",
  //   dashboard: "Isesengura",
  //   my_profile: "Umwirondoro wanjye",
  //   requests: "Ibyasabwe",
  //   pending: "Ibitegereje",
  //   processing: "Ibirigukorwa",
  //   approved: "Ibyemejwe",
  //   rejected: "Ibyanzwe",
  //   logout: "Funga",
  //   services: "Serivisi",
  //   notifications: "Kumenyesha",
  //   alerts: "Imenyesha",
  //   accounts: "Konti",
  //   back: "Inyuma",
  //   next: "Imbere",
  //   previous: "Mbere",
  //   submit: "Ohereza",
  //   cancel: "Guhagarika",
  //   change_password: "Hindura ijambo ryibanga",
  //   setting_description: "Genzura ibokorwa byingenzi kuri urubuga",
  //   new_password_placeholder: "Injizamo ijambo ryibanga rishya",
  //   new_password: "Ijambo ryibanga rishya",
  //   confirm_password_placeholder: "Ongera wandike ijambo banga",
  //   confirm_password: "Emeza ijambo banga",
  //   change_password_success: "Guhindura ijambo banga byagenze neza",
  //   departments: "Amashami",
  //   roles_permission: "Inshingano & Uruhushya",
  //   institutions_title: "Ibigo",
  //   institutions_description: "Gucunga ibigo, amashami ninshingano",
  //   institution_name_placeholder: "Injiza izina",
  //   institution_name: "Izina",
  //   add_institution: "Ongeraho Ikigo",
  //   edit_institution: "Hindura amakuru",
  //   delete_institution: "Siba Ikigo",
  //   export_: "Bika",
  //   institution_name_required: "Injiza izina ryikigo",
  //   add_institution_success: "Kongeramo ikigo byagenze neza",
  //   department_name: "Izina ryishami",
  //   department_name_placeholder: "Injizamo izina ryishami",
  //   department_name_required: "Izina ryishami rirakenewe",
  //   add_department: "Ongeramo ishami",
  //   delete_department: "Siba ishami",
  //   edit_department: "Hindura ishami",
  //   unable_fetch_institutions: "",
  //   unable_fetch_departments: "",
  //   services_title: "Serivise",
  //   services_description: "Gucunga serivisi zigomba gusabwa na bakozi ba leta",
  //   add_service: "Ongeramo serivise",
  //   individual: "Kugiti cye",
  //   edit_service: "Hindura serivise",
  //   delete_service: "Siba serivise",
  //   service_name: "Izina rya serivise",
  //   service_name_placeholder: "Injizamo izina rya serivise",
  //   service_name_required: "Izina rya serivise rirakenewe",
  //   category: "Icyiciro",
  //   add_translation: "Hindura ururimi",
  //   max_number_days: "Ingano yigihe cyagentwe",
  //   remove_translation: "Siba ururimi",
  //   maxDays_required: "Igihe ntarengwa cyirakenewe",
  //   data_t_fill: "Data zo kuzuzwa",
  //   translations: "Ururimi",
  //   signatures: "Sinyatire",
  //   approval_levels: "Ingengabihe yo kwemeza",
  //   output_letter: "Urwandiko rutangwa",
  //   publish: "Ohereza",
  //   avg_response_time: "Igihe giteganyijwe cyogusubizwa",

  //   add_document: "Ongeraho inyandiko",
  //   edit_document: "Hindura Inyandiko",
  //   delete_document: "Siba Inyandiko",
  //   document_name_required: "Inyandiko irakenewe",
  //   document_name: "Izina ryinyandiko",
  //   file_extension: "Ibwoko bwinyandiko",
  //   save: "Bika",
  //   required_document: "Inyandiko zikenewe",
  //   size_limit_required: "Ingano zinyandiko",
  //   file_extension_required: "Ubwo bwinyandiko burakenewe",
  //   data_title: "Izina ryamakuru",
  //   input_type: "Abwoko bwamakuru",
  //   input_requirement: "Izina ryamakuru arakenewe",
  //   created_at: "Igihe byakorewe",
  //   actions: "Ibikorwa",
  //   add_data: "Ongera amakuru",
  //   delete: "Siba amakuru",
  //   edit_data: "Hindura amakuru",
  //   data_title_required: "Izina ryamakuru rirakenewe",
  //   input_type_required: "Ubwoko bwamakuru",
  //   input_requirement_required: "Amakuru arakenewe",
  //   data_title_placeholder: "Injiza izina ryamakuru",
  //   mandatory: "Arakenewe",
  //   optional: "Ntakenewe",
  //   text: "Inyandiko",
  //   checkbox: "Checkbox",
  //   translation: "Hindura amakuru",
  //   default_translation: "Amakuru",
  //   language: "Ururrimi",
  //   required_documents: "ibisabwa kunyandiko",
  //   email_address: "Emeri",
  //   enter_email: "Injizamo emeri",
  //   password: "Ijambo ryibanga",
  //   enter_password: "Injizamo ijambo ryibanga",
  //   email_required: "Emeri yawe irakenewe",
  //   password_required: "Ijambo ryibanga rirakenewe",
  //   wrong_email_password:
  //     "Emiri cg ijambo ryibanga ntabow aribyo, ongera ugerageze",
  //   update_department_success: "Byagenze neza guhindura ishami",
  //   error_add_department: "Ntibishotse kongeramo ishami, nyamuneka gerageza",
  //   error_update_department: "tibishotse guhindura ishami, nyamuneka gerageza",
  //   error_delete_department: "tibishotse gusiba ishami, nyamuneka gerageza",
  //   success_delete_department: "Ishami ryasize neza",
  //   job_positions: "Imyanya yakazi",
  //   add_position_success: "Kongeramo umwanya wakazi byagenze neza",
  //   error_add_position: "Ntibishobotse kongeramo umwanya wakazi",
  //   update_position_success: "Guhundura umwanya wakazi byagenze neza",
  //   error_update_position: "ntibishobotse guhindura umwanya wakazi",
  //   error_delete_position: "Ntibishobotse gusiba umwanya wakazi",
  //   unable_fetch_positions: "Ntibishobotse kukwereka imyanya yakazi",
  //   add_job_position: "Ongeramo Umwanya wakazi",
  //   delete_job_position: "Siba Umwanya wakazi",
  //   edit_job_position: "Hindura Umwanya wakazi",
  //   job_position: "Umwanya wakazi",
  //   job_position_name: "Izina",
  //   available: "Irakoreshwa",
  //   job_position_name_placeholder: "Injizamo izina ryakazi",
  //   job_position_name_required: "Injizamo izina ryakazi",
  //   forgot_password: "Nibagiwe Ijambo Ryibanga",
  // },
};

export default data;
