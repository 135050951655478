import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import language from "../../../language";
import { Button } from "../../Button";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import { Input } from "../../Input";
class Letter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: EditorState.createEmpty(),
      error: {},
      title: "",
      editorHtml: "",
    };
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target ? e.target.value : e, error });

    if (field === "editor") {
      let editorHtml = convertToHTML(e.getCurrentContent());
      this.setState({ editorHtml });
    }
  }

  createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  {/* <Input
                    label={language[this.props.defaultLanguage].letter_subject}
                    placeholder={
                      language[this.props.defaultLanguage]
                        .letter_subject_placeholder
                    }
                    textarea
                  /> */}
                  {/* <Input
                    label={language[this.props.defaultLanguage].letter_body}
                    placeholder={
                      language[this.props.defaultLanguage]
                        .letter_body_placeholder
                    }
                    textarea
                  /> */}
                </div>
              </div>
              <div>
                <label>
                  {language[this.props.defaultLanguage].letter_body}
                </label>
              </div>
              <Editor
                editorState={this.state.editor}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={(e) => this.onChangeText("editor", e)}
              />
              <div className="modal-footer">
                <Button text={language[this.props.defaultLanguage].submit} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  {this.state.editorHtml && (
                    <div
                      className="preview"
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.state.editorHtml
                      )}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Letter);
