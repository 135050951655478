import React from "react";
import Pagination from "./Pagination";

class CustomPagination extends React.Component {
  render() {
    const {
      totalPageCount,
      page,
      data = [],
      limit,
      handlePagination,
    } = this.props;
    const firstPage = 1;
    const lastPage = totalPageCount;

    const paginate = async (numPage) => {
      await handlePagination(numPage);
    };

    const nextPage = async () => {
      if (page === lastPage) return;
      await handlePagination(page + 1);
    };

    const prevPage = async () => {
      if (page === firstPage) return;
      await handlePagination(page - 1);
    };
    return (
      <>
        <Pagination
          totalPageCount={totalPageCount}
          limit={limit}
          paginate={paginate}
          length={data.length}
          nextPage={nextPage}
          prevPage={prevPage}
          firstPage={firstPage}
          lastPage={lastPage}
          page={page}
        />
      </>
    );
  }
}

export default CustomPagination;
