import React from "react";

import { ResetPassword } from "../../../components/ResetPassword";
import { withRouter } from "../../../utils/withRouter";
class ResetPasswordScreen extends React.Component {
  render() {
    const { searchParams } = this.props;

    const email = searchParams.get("email");
    return (
      <>
        <ResetPassword email={email} />
      </>
    );
  }
}

export default withRouter(ResetPasswordScreen);
