import loginVector from "../assets/Login vector.png";
import dashboardVector from "../assets/dashboard.png";

export default [
  {
    title: "Live Requests Processing",
    description:
      "Active management of public service requests with real-time categorization, progress tracking and timely responses.",
    image: loginVector,
  },
  {
    title: "Service Quality Monitoring",
    description:
      "Monitor and assess demand and quality of services provided. Ensuring consistency, excellence, and applicant satisfaction.",
    image: dashboardVector,
  },
];
