import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

const fetchData = async (lng = "english", params) => {
  try {
    const { page, limit, type, id, pageType, serviceType, categories } = params;

    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Services",
      operation: "read",
    });

    if (!canRead && !user.role === "user") return;

    const categoryIds = categories?.map((service) => service.value);

    let requestBody = {
      page: page,
      limit: limit,
      id,
      type: serviceType,
      // institution: user.institution,
      category: categoryIds,
    };

    let headers = {
      Authorization: `Bearer ${user.token}`,
    };

    if (pageType === "landing") {
      delete headers.Authorization;
      delete requestBody.institution;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/service`,
      params: requestBody,
      headers,
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_services);
  }
};

export default fetchData;

export const deleteService = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/service`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_service);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_service);
  }
};
