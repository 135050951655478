import React from "react";
import { ConfirmationModal } from "../Modal";
import language from "../../language";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import { connect } from "react-redux";
import { getStorage } from "../../utils/storage";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

class Approve extends React.Component {
  state = {
    title: "",
    description: "",
    success: false,
    isSubmitting: false,
    isSignatureValid: false,
    validatingSignatureSuccess: false,
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  componentDidMount() {
    this.setState({
      title: "Approve Request",
      description: "Are you sure you want to approve this request?",
      success: false,
    });
  }

  handleShowModal(modal, modalTitle) {
    this.setState({
      [modal]: true,
      modalTitle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  validateSignature(valid) {
    if (valid === "isValidating") {
      this.setState({
        isValidatingSignature: true,
      });

      return;
    }

    if (valid) {
      this.setState({
        validatingSignatureSuccess: true,
        isValidatingSignature: false,
        validatingSignatureFailed: false,
      });

      return;
    }

    toastMessage(
      "error",
      language[this.props.defaultLanguage].invalid_signature
    );

    this.setState({
      isValidatingSignature: false,
      validatingSignatureFailed: true,
      validatingSignatureSuccess: false,
    });

    return false;
  }

  onApprove = async () => {
    const { requestId, request, approver, comment, file } = this.props;
    const user = await getStorage();
    const findApprover =
      (approver.account && approver.account._id === user.id) ||
      (!approver.account &&
        approver.accessRole &&
        approver.accessRole._id === user.accessRole._id);

    let status = request.status;
    let countApproved = 0;
    const formData = new FormData();

    if (!requestId || requestId === "")
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_find_request
      );

    if (!findApprover)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].not_allowed_approve_request
      );

    this.setState({ isSubmitting: true });

    const levels = request.approvals.map((a) => a.level);
    const highestLevel = Math.max(...levels);

    const updatedApprovals = request.approvals.map((el, i) => {
      if (
        (el.account && el.account._id === user.id) ||
        (!el.account &&
          el.accessRole &&
          el.accessRole?._id === user.accessRole._id)
      ) {
        if (!el.account && el.level === approver.level) {
          el.account = user.id;
        } else {
          el.account = el?.account?._id || el.account;
        }

        if (el.accessRole) {
          el.accessRole = el?.accessRole?._id;
        }

        el.action = "Approved";
        el.actionAt = moment().format("YYYY-MM-DD hh:mm:ss");

        if (comment && comment !== "") {
          el["actionComment"] = comment;
        }

        if (file && file !== "") {
          let { questionnaireAction = [] } = el;

          questionnaireAction.push({
            questionText: file.name,
            answerType: "file",
            duplicateId: uuidv4(),
          });

          formData.append(
            `approvals[${i}].questionnaireAction[${
              questionnaireAction.length - 1
            }].answer`,
            file
          );
        }

        //with highest level should approve the whole request
        if (highestLevel === el.level) {
          status = "approved";
        }
      }

      if (el.action === "Approval") {
        countApproved += 1;
      }
      return el;
    });

    if (
      request.approvals.length !== 0 &&
      countApproved === request.approvals.length
    ) {
      status = "approved";
    }

    formData.append("id", requestId);
    formData.append("status", status);
    formData.append("approvals", JSON.stringify(updatedApprovals));

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_PSRP_BASE_API}/request`,
      data: formData,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
          success: true,
          title: "Confirmed",
          description: "Request approved successfully",
          isValidatingSignature: false,
          validatingSignatureFailed: false,
        });

        this.props.getData();
        this.props.resetFile();
      })
      .catch((error) => {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].unable_to_approve_request
        );
        this.setState({
          isSubmitting: false,
          isValidatingSignature: false,
          validatingSignatureFailed: false,
          validatingSignatureSuccess: false,
        });
      });
  };

  render() {
    return (
      <>
        <ConfirmationModal
          icon={this.state.success ? icons.done : icons.waiting}
          success={this.state.success}
          title={this.state.title}
          description={this.state.description}
          positiveButtonText={language[this.props.defaultLanguage].yes}
          negativeButtonText={language[this.props.defaultLanguage].no}
          onPressNegative={this.props.handleCloseModal}
          onPressPositive={() => this.onApprove()}
          isPositiveSubmitting={this.state.isSubmitting}
          handleCloseModal={this.props.handleCloseModal}
          signatureRequired
          isSignatureValid={this.state.isSignatureValid}
          validateSignature={this.validateSignature.bind(this)}
          isValidatingSignature={this.state.isValidatingSignature}
          validatingSignatureFailed={this.state.validatingSignatureFailed}
          validatingSignatureSuccess={this.state.validatingSignatureSuccess}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Approve);
