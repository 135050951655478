import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import { UserGuideLayout } from "../../Layout";
import AdminUserGuideScreen from "./AdminUserGuideScreen";
import ApplicantUserGuideScreen from "./ApplicantUserGuideScreen";

const PublicServantScreen = () => {
  return (
    <UserGuideLayout>
      <Routes>
        <Route path={"/"} element={<HomeScreen />} />
        <Route path={"/admin"} element={<AdminUserGuideScreen />} />
        <Route path={"/applicant"} element={<ApplicantUserGuideScreen />} />
      </Routes>
      <Outlet />
    </UserGuideLayout>
  );
};

export default PublicServantScreen;
