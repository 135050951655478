import axios from "axios";
import language from "../../language";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../storage";
import departmentQuery from "./departmentQuery";

/*

This function is responsible for query user information from
1. IPPIS (field name: ippisEmployeeId)
2. NATIONAL ID (field name: nationalId)

*/
const API_URL = process.env.REACT_APP_PSRP_BASE_API;

const fetchData = async (
  that,
  { params, fieldName = "ippisNumber", method = "GET" }
) => {
  const { error_retrieving_user_info, first_name_required, employee_required } =
    language[that.props.defaultLanguage];

  let { error, validFields } = that.state;

  if (!validFields) validFields = {};

  if (!error) error = {};

  if (!params.employeeId || params.employeeId === "") {
    error.ippisNumber = employee_required;

    console.log("I am here 1: " + params.employeeId);

    return that.setState({ error });
  }

  if (!params.firstName || params.firstName === "") {
    error.firstName = first_name_required;

    console.log("I am here 2: " + params.firstName);

    return that.setState({ error });
  }

  try {
    that.setState({ isRetrievingUserInfo: true });

    const options = {
      method,
      url: API_URL + "/ippis",
      params,
    };

    const data = await axios(options);

    delete validFields[fieldName];
    delete error[fieldName];

    that.setState({
      profileData: data?.data,
      isRetrievingUserInfo: false,
      validFields,
      error,
    });

    return data?.data;
  } catch (error) {
    toastMessage("error", error_retrieving_user_info);

    delete validFields[fieldName];

    error[fieldName] = error_retrieving_user_info;

    that.setState({ isRetrievingUserInfo: false });
  }
};

export default fetchData;
