import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Tabs } from "../../Tabs";
import { DashboardHeader } from "../../Header";
import Documents from "../RequiredDocuments/Documents";
import Questionnaire from "../Questionnaire/Questionnaire";
import Approver from "../Approver/Approver";
import Translations from "../Translations/Translations";
import serviceQuery from "../../../utils/queries/serviceQuery";
import NewService from "./NewService";
import { Modal } from "../../Modal";
import SubApprover from "../SubApprover/SubApprover";
import Letter from "../OutputLetter/Letter";

class SingleService extends React.Component {
  state = {
    isLoading: false,
    page: 1,
    limit: 1,
    data: {},
    selected_data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const data = await serviceQuery(defaultLanguage, {
        id: serviceId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {
      required_documents,
      translations,
      data_t_fill,
      signatures,
      output_letter,
      approvers,
      edit_service,
      sub_approvers,
    } = language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader
          isLoading={this.state.isLoading}
          {...this.state.data}
          isEdit
          onPressEdit={() =>
            this.handleShowModal("showModal", edit_service, this.state.data)
          }
        />

        <div className="card-body">
          <Tabs
            options={[
              {
                title: required_documents,
                data: <Documents serviceId={this.props.serviceId} />,
              },
              {
                title: data_t_fill,
                data: <Questionnaire serviceId={this.props.serviceId} />,
              },
              {
                title: sub_approvers,
                data: <SubApprover serviceId={this.props.serviceId} />,
              },
              {
                title: approvers,
                data: <Approver serviceId={this.props.serviceId} />,
              },
              {
                title: translations,
                data: <Translations serviceId={this.props.serviceId} />,
              },
              //
              // {
              //   title: output_letter,
              //   data: <Letter serviceId={this.props.serviceId} />,
              // },
            ]}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewService
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={() => this.getData(true)}
            {...this.state.selected_data}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleService);
