import React from "react";
import { Input, Select } from "../../../Input";
import { connect } from "react-redux";
import { Button } from "../../../Button";
import language from "../../../../language";
import icons from "../../../../constants/icons";
import {
  common_input_types,
  input_types,
} from "../../../../constants/document";
import IntegrationForm from "./IntegrationForm";

class MultipleChoice extends React.Component {
  state = {
    choices: [],
    error: {},
  };

  componentDidMount() {
    if (this.props.answerOptions) {
      this.setState({
        choices: this.props.answerOptions,
        error: this.props.error,
      });
    }
  }

  onChangeInput({ field, e, index, type }) {
    try {
      let { error, choices } = this.state;
      let value = e?.target ? e.target.value : e;

      // console.log("value changes ****", value);
      // console.log("on value changes read errors ****", error);

      this.handleClearFormOnChangeType({ index, value, type });

      delete error[field];
      delete error[type + "_" + index];

      choices[index][type] = value;

      this.setState({
        choices,
      });

      this.props.handleOptionsChanges({
        action: "typing",
        index,
        [type]: value,
        item: choices[index],
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleClearFormOnChangeType({ index, value, type }) {
    console.log("Reset Form start from here ******************************");
    let { choices } = this.state;
    const { option = {} } = choices[index];

    if (option.value !== value && type === "option") {
      Object.keys(choices[index]).forEach((key) => {
        if (key !== "option" && key !== "name") {
          choices[index][key] = "";
        }
      });
    }

    this.setState({ choices });
  }

  handleAddChoice = () => {
    let { choices } = this.state;
    let index = choices.length;

    const item = {
      field: "choice_" + index,
      name: "",
      option: input_types(this.props.defaultLanguage)[0],
    };

    choices.push(item);

    this.setState({ choices });

    this.props.handleOptionsChanges({ action: "add", index, item });
  };

  handleRemoveChoice(index) {
    let { choices } = this.state;

    choices.splice(index, 1);

    this.setState({ choices });

    this.props.handleOptionsChanges({ action: "remove", index });
  }

  renderInput(field, index) {
    return (
      <div className="card mb-3">
        <div className="card-body" style={{ backgroundColor: "#eee" }}>
          <div className="row">
            <div className="col-md-8">
              <Input
                label={language[this.props.defaultLanguage].choice_item_name}
                placeholder={
                  language[this.props.defaultLanguage].choice_item_placeholder
                }
                value={this.state?.choices[index]?.name}
                onChange={(e) =>
                  this.onChangeInput({ field, e, index, type: "name" })
                }
                required
                error={this.state.error["name_" + index]}
              />
            </div>
            <div className="col-md-4">
              <Select
                options={[
                  {
                    label: "Text",
                    value: "text",
                  },
                ]}
                label={
                  language[this.props.defaultLanguage]
                    .choice_option_type_placeholder
                }
                placeholder={language[this.props.defaultLanguage].select}
                value={this.state?.choices[index]?.option}
                onChange={(e) =>
                  this.onChangeInput({ field, e, index, type: "option" })
                }
                required
              />
            </div>
          </div>
          {this.state?.choices[index]?.option?.value === "integration" && (
            <div className="mt-3">
              <IntegrationForm
                choices={this.state?.choices}
                index={index}
                onChangeInput={(type, e) =>
                  this.onChangeInput({ field, e, index, type: type })
                }
                field={field}
                apiUrl={this.state?.choices[index]?.apiUrl}
                dropdownFieldName={
                  this.state?.choices[index]?.dropdownFieldName
                }
                dropdownFieldValue={
                  this.state?.choices[index]?.dropdownFieldValue
                }
                responseKey={this.state?.choices[index]?.responseKey}
                error={this.props.error}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button
            className="btn-danger btn-sm"
            text="Remove"
            icon={icons.remove}
            onPress={() => this.handleRemoveChoice(index)}
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div style={{ marginBottom: "2rem" }}>
        {this.state.choices.map((choice, i) => {
          return this.renderInput(choice, i);
        })}
        <Button
          text={language[this.props.defaultLanguage].add_choice}
          onPress={this.handleAddChoice.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MultipleChoice);
