import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { DeleteModal, Modal } from "../../Modal";
import NewQuestionnaire from "./NewQuestionnaire";
import serviceQuery from "../../../utils/queries/serviceQuery";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import axios from "axios";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import icons from "../../../constants/icons";
import Webhook from "../../Webhook/Webhook";

let copyData = [];

class Questionnaires extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
    this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      this.setState({
        data: data.length > 0 ? data[0] : {},
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data?.questionnaire?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    console.log({ modal, modalTitle, selected_data });
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].question,
        key: "questionText",
      },
      {
        title: language[this.props.defaultLanguage].answer_type,
        key: "answerType",
      },
      // {
      //   title: language[this.props.defaultLanguage].actions,
      //   key: "action",
      // },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.eye_on,
            title: language[this.props.defaultLanguage].edit,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showModal", item.question, {
                ...item,
                dataIndex,
              }),
          },
          {
            icon: icons.delete,
            className: "bordered",
            showNotificationCount: true,
            user: this.state.user.id,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showDeleteModal", item.question, {
                ...item,
                dataIndex,
              }),
          },
        ],
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { selected_data, data } = this.state;
      let { questionnaire } = data;
      let user = await getStorage();

      questionnaire.splice(selected_data.dataIndex, 1);

      this.setState({ data: this.state.data });

      const options = {
        method: "PUT",
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: {
          id: data._id,
          questionnaire,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_questionnaire_success
      );

      this.setState({ isDeleting: false });

      this.handleCloseModal("showDeleteModal");
      this.getData();
    } catch (error) {
      this.setState({
        isDeleting: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_delete_questionnaire
      );
    }
  };

  render() {
    const { add_questionnaire, edit_questionnaire, questionnaire } =
      language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state?.data?.questionnaire || []}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add_questionnaire}
          draggable
          rowPress={(item, dataIndex) =>
            this.handleShowModal("showModal", edit_questionnaire, {
              ...item,
              dataIndex,
            })
          }
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            questionnaire
          )}
          filters={[
            {
              title: language[this.props.defaultLanguage].webhook,
              icon: icons.link,
              onPress: () => this.handleShowModal("showWebhookFormModal"),
            },
          ]}
          // actions={[
          //   {
          //     canAccess: defineRole({
          //       roles: this.state.user.accessRole,
          //       menu: "Services",
          //       operation: "update",
          //     }),
          //     name: edit,
          //     onPress: (item, dataIndex) =>
          //       this.handleShowModal("showModal", edit_questionnaire, {
          //         ...item,
          //         dataIndex,
          //       }),
          //   },
          //   {
          //     canAccess: defineRole({
          //       roles: this.state.user.accessRole,
          //       menu: "Services",
          //       operation: "delete",
          //     }),
          //     name: delete_,
          //     onPress: (item, dataIndex) =>
          //       this.handleShowModal("showDeleteModal", delete_questionnaire, {
          //         ...item,
          //         dataIndex,
          //       }),
          //   },
          // ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <NewQuestionnaire
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            serviceData={this.state.data}
            {...this.state.selected_data}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showWebhookFormModal")}
          show={this.state.showWebhookFormModal}
          title={language[this.props.defaultLanguage].service_webhook}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <Webhook
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showWebhookFormModal"
            )}
            serviceId={this.props.serviceId}
            webhookUrls={this.state.data.webhookUrls}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.questionText}
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaires);
