import React from "react";
import "./styles.css";

const Switch = (props) => {
  return (
    <div className={`switch-container ${props.className}`}>
      <div
        onClick={props.onCheck}
        className={`switch-content ${props.checked ? "checked" : ""}`}
      >
        <div className="check" />
      </div>
      <div className="description">
        {props.label && <label>{props.label}</label>}
        {props.title && <h3>{props.title}</h3>}
        {props.description && <p>{props.description}</p>}
      </div>
    </div>
  );
};
export default Switch;
