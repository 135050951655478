import React from "react";
import { connect } from "react-redux";
import { Table } from "../Table";
import language from "../../language";

class ViewSingleDepartment extends React.Component {
  state = {
    isLoading: false,
    error: {},
    csvData: [],
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].department_name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].available,
        key: "available",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    return (
      <div className="card-body">
        <Table
          data={[this.props.data]}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ViewSingleDepartment);
