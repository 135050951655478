import { DashboardHeader } from "../../../Header";
import Reviewers from "../../widgets/Reviewers";
import language from "../../../../language";

export default (that) => {
  if (that.state?.data?.approvals?.length > 0) {
    const approvals = that.state.data?.approvals;
    const sortedArray = approvals.sort((a, b) => a.level - b.level);
    return (
      <>
        {/*approvals */}
        <div>
          {that.state.data?.subApprovals?.length > 0 && (
            <>
              <DashboardHeader
                title={`${language[that.props.defaultLanguage].approvals} (${
                  that.state.data?.approvals?.length
                })`}
                size="md"
              />
              <Reviewers data={that.state.data} reviewers={sortedArray} />
            </>
          )}
        </div>
      </>
    );
  }
};
