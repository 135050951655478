import "./styles.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="container">
        <div className="middle-container">
          <span>
            &copy; {" " + new Date().getFullYear() + " "}
            <b>MIFOTRA</b> | All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
