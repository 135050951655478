//This function handles roles/permission and access of the sidebar admin menus
//Super admin will access all
//Normal admin access will be determined by roles admin has granted you
export const defineRole = ({ roles = {}, menu, operation }) => {
  try {
    const userLoggedIn = JSON.parse(
      sessionStorage.getItem(process.env.REACT_APP_NAME) || {}
    );

    const { role = "" } = userLoggedIn;

    let isTrue = role === "superAdmin";

    if (roles && roles?._id) {
      const { permissions } = roles;
      const { menus, operationMenus } = permissions;

      if (menus?.includes(menu) && operationMenus[operation]?.includes(menu)) {
        isTrue = true;
      }
    }

    return isTrue;
  } catch (error) {
    return false;
  }
};
