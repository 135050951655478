import React from "react";

import { Select } from "../../Input";
import { fetchIntegratedApi } from "../../../utils/queries/integration/ippis";

import { connect } from "react-redux";
import isJsonString from "../../../utils/isJsonString";
class RemoteDropdown extends React.Component {
  state = {
    data: [],
  };

  componentDidMount = async () => {
    const {
      responseKey,
      apiUrl,
      dropdownFieldName,
      dropdownFieldValue,
      apiMethod,
      apiParams,
      ippisToken,
    } = this.props;

    try {
      let data = [];

      this.setState({ isFetching: true });

      data = await fetchIntegratedApi(this.props.defaultLanguage, {
        apiUrl,
        dropdownFieldName,
        dropdownFieldValue,
        responseKey,
        apiMethod,
        apiParams,
        ippisToken,
      });

      this.setState({
        data,
        isFetching: false,
      });
    } catch (error) {
      console.error("fetch dynamic form ", error);
      this.setState({
        isFetching: false,
      });
    }
  };
  render() {
    let value = this.props.value ? this.props.value.answer : "";

    if (
      this.props.value &&
      this.props.value.answer &&
      isJsonString(this.props.value.answer)
    ) {
      value = JSON.parse(this.props.value.answer);
    }
    return (
      <Select
        label={this.props.label}
        options={this.state.data}
        value={value}
        onChange={(e) =>
          this.props.onChange({
            questionText: this.props.questionText,
            fieldName: this.props.label,
            value: e,
            formKey: this.props.formKey,
            questionId: this.props.questionId,
            answerType: this.props.answerType,
          })
        }
        required={this.props.required}
        isLoading={this.state.isFetching}
        error={this.props.error}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RemoteDropdown);
