import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Modal, DeleteModal } from "../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import accountQuery, { deleteAccount } from "../../utils/queries/accountQuery";
import NewAccount from "./NewAccount";
import { Permissions } from "../AccessRole";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { menus } from "../../constants/accessRolesList";
import toastMessage from "../../utils/toastMessage";

let copyData = [];

class Accounts extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, active, outsideOrganization } = this.props;

      this.setState({ isLoading });

      const { data, count } = await accountQuery(defaultLanguage, {
        page,
        limit,
        active,
        outsideOrganization,
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].institution,
        key: "institution",
      },
      {
        title: language[this.props.defaultLanguage].ippisNumber,
        key: "ippisNumber",
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].email_address,
        key: "email",
        styles: {
          textTransform: "lowercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].department,
        key: "department.name",
      },
      {
        title: language[this.props.defaultLanguage].job_position,
        key: "position.name",
      },
      {
        title: language[this.props.defaultLanguage].access_role,
        key: "accessRole.name",
        type: "link",
        onPress: (item) =>
          this.handleShowModal("showRoleModal", "Access Role", item.accessRole),
        styles: {
          textTransform: "uppercase",
        },
      },
    ];

    if (this.props.outsideOrganization) {
      headers.push({
        title: language[this.props.defaultLanguage].outside_organization,
        key: "outsideOrganization",
      });
    }

    headers.push(
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      }
    );

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteAccount(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false, delete_value: "" });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage, isActive } = this.props;

      const { data } = await accountQuery(defaultLanguage, {
        page,
        limit,
        active: isActive,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage, isActive } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await accountQuery(defaultLanguage, {
      page,
      limit,
      active: isActive,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Accounts", headers, data);
  };

  render() {
    const {
      add_account,
      edit_account,
      delete_account,
      account,
      export_,
      edit,
      delete_,
    } = language[this.props.defaultLanguage];

    let selectedMenus = [];
    for (let menu of menus(this.props.defaultLanguage)) {
      if (this.state?.selectedData?.permissions?.menus?.includes(menu.value)) {
        selectedMenus.push(menu);
      }
    }
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: this.props.isActive ? "Active Accounts" : "Inactive Accounts",
            operation: "create",
          })}
          addButtonText={add_account}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            account
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: this.props.isActive
                  ? "Active Accounts"
                  : "Inactive Accounts",
                operation: "update",
              }),
              name: edit,
              onPress: (item) =>
                this.handleShowModal("showModal", edit_account, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: this.props.isActive
                  ? "Active Accounts"
                  : "Inactive Accounts",
                operation: "delete",
              }),
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_account
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <NewAccount
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showRoleModal")}
          show={this.state.showRoleModal}
          title={this.state?.selectedData?.name}
          showHeaderBottomBorder={false}
        >
          <div className="card-body">
            {selectedMenus?.map((menu, m) => {
              return (
                <div className="col-md-12" key={m}>
                  <Permissions
                    title={menu.value}
                    key={m}
                    operationMenus={
                      this.state?.selectedData?.permissions?.operationMenus
                    }
                    operations={menu.operations}
                    menuItem={menu.value}
                  />
                </div>
              );
            })}
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.username}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Accounts" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Accounts);
