import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import loginSlidesContent from "../../constants/loginSlidesContent";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class ChangePassword extends React.Component {
  state = {
    email: "",
    isSubmitting: false,
    error: {},
    otpSent: false,
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    let { error, email } = this.state;

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { email } = this.state;

      const options = {
        method: "POST",
        url: `${API_URL}/authentication/admin`,
        data: {
          email,
          isPasscode: true,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].email_has_been_sent_to +
              " " +
              this.state.email +
              " " +
              language[this.props.defaultLanguage].successfully
          );

          window.location.href = "/reset-password?email=" + email;
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            this.state.email +
              ", " +
              language[this.props.defaultLanguage].account_nt_exist
          );
        });
    }
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides data={loginSlidesContent} viewSize={1} />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {this.state.otpSent
                    ? language[this.props.defaultLanguage].verify_account
                    : language[this.props.defaultLanguage].change_password}
                </h3>
                {this.state.otpSent && (
                  <p>
                    {language[this.props.defaultLanguage].enter_otp_received_on}
                  </p>
                )}
              </div>
              <form>
                <Input
                  label={language[this.props.defaultLanguage].email_address}
                  placeholder={language[this.props.defaultLanguage].enter_email}
                  required
                  leftIcon="mail"
                  value={this.state.email}
                  error={this.state.error.email}
                  onChange={(e) => this.onChangeText("email", e)}
                  helperText={
                    language[this.props.defaultLanguage].receive_otp_description
                  }
                />
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <span>
                    <Link to="/login">
                      {language[this.props.defaultLanguage].login_text}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ChangePassword);
