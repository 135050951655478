export const serviceHasIntegration = (questionnaire) => {
  try {
    for (let question of questionnaire) {
      if (
        question.answerType === "integration" &&
        question?.singleAnswer?.ippisToken
      ) {
        return true;
      }
    }

    return false;
  } catch (error) {
    console.error("An error occurred while checking for integration:", error);
    return false;
  }
};
