import vectorHome from "../../../assets/vector-home.png";
import "./styles.css";

const ImageContent = () => {
  return (
    <div className="col-md-4 intro-img">
      <img src={vectorHome} alt="Home vector" />
    </div>
  );
};

export default ImageContent;
