import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Input } from "../Input";
import { Button } from "../Button";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";

const urlOptions = {
  url: "",
  action: "",
};
class Webhook extends React.Component {
  state = {
    url: "",
    body: "",
    error: {},
    webhookUrls: [],
  };

  componentDidMount() {
    if (this.props.webhookUrls?.length > 0) {
      this.props.webhookUrls.map((el, i) => {
        this.setState({
          ["url_" + i]: el.url,
          ["action_" + i]: el.action,
          ["apiConfiguration_" + i]: el.apiConfiguration,
        });
      });
      this.setState({ webhookUrls: this.props.webhookUrls });
    } else {
      this.setState({ webhookUrls: [urlOptions] });
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      error,
      [field]: e.target.value,
    });
  }

  validateForm() {
    let { error, webhookUrls } = this.state;

    webhookUrls.forEach((el, i) => {
      if (!this.state["url_" + i] || this.state["url_" + i] === "") {
        error["url_" + i] =
          language[this.props.defaultLanguage].field_is_required;
      }
    });

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, webhookUrls } = this.state;
    const { serviceId } = this.props;

    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "PUT",
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: {
          id: serviceId,
          webhookUrls: webhookUrls.map((el, i) => {
            return {
              url: this.state["url_" + i],
              action: this.state["action_" + i],
              apiConfiguration: this.state["apiConfiguration_" + i],
            };
          }),
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].webhook_added_success
          );
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  onAddWebhookUrl() {
    let { webhookUrls } = this.state;

    webhookUrls.push(urlOptions);

    this.setState({ webhookUrls });
  }

  onRemoveWebhookUrl(index) {
    let { webhookUrls } = this.state;

    webhookUrls.splice(index, 1);

    this.setState({ webhookUrls });
  }

  render() {
    console.log(this.state);
    return (
      <>
        <div className="card-body">
          {this.state.webhookUrls.map((el, i) => {
            return (
              <>
                <Input
                  label={language[this.props.defaultLanguage].url}
                  placeholder={
                    language[this.props.defaultLanguage].webhook_url_placeholder
                  }
                  value={this.state["url_" + i]}
                  error={this.state.error["url_" + i]}
                  onChange={(e) => this.onChangeText("url_" + i, e)}
                  required
                />
                <Input
                  label={language[this.props.defaultLanguage].action}
                  placeholder={
                    language[this.props.defaultLanguage]
                      .webhook_action_placeholder
                  }
                  value={this.state["action_" + i]}
                  error={this.state.error["action_" + i]}
                  onChange={(e) => this.onChangeText("action_" + i, e)}
                />
                <Input
                  label={language[this.props.defaultLanguage].api_configuration}
                  value={this.state["apiConfiguration_" + i]}
                  error={this.state.error["apiConfiguration_" + i]}
                  onChange={(e) =>
                    this.onChangeText("apiConfiguration_" + i, e)
                  }
                  textarea
                />
                <div className="d-flex gap-2">
                  {i !== 0 && (
                    <Button
                      className="btn-default"
                      text={language[this.props.defaultLanguage].remove}
                      onPress={() => this.onRemoveWebhookUrl(i)}
                    />
                  )}
                  {this.state.webhookUrls.length - 1 === i && (
                    <Button
                      text={language[this.props.defaultLanguage].add}
                      onPress={this.onAddWebhookUrl.bind(this)}
                    />
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Webhook);
