// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  border-top: 1px solid var(--primary-color-5);
}

.footer-container .container {
  display: flex;
  align-items: center;
  min-height: 45px;
  justify-content: center;
}

.footer-container .container span {
  font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".footer-container {\n  border-top: 1px solid var(--primary-color-5);\n}\n\n.footer-container .container {\n  display: flex;\n  align-items: center;\n  min-height: 45px;\n  justify-content: center;\n}\n\n.footer-container .container span {\n  font-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
