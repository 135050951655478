export const ON_LOADING_SERVICES = "ON_LOADING_SERVICES";
export const ON_FETCH_SERVICES = "ON_FETCH_SERVICES";
export const ON_SEARCH_SERVICES = "ON_SEARCH_SERVICES";
export const ON_COPY_SERVICES = "ON_COPY_SERVICES";

export const isFetchingServices = (isLoading) => {
  return {
    type: ON_LOADING_SERVICES,
    isLoading,
  };
};

export const fetchedServices = (services = []) => {
  let individualServices = {},
    institutionServices = {};

  services &&
    services?.forEach((el) => {
      let category = el?.category || {};

      if (Array.isArray(el?.category) && el?.category?.length > 0) {
        category = el?.category[0];
      }

      const categoryName = category?.name || "";
      const type = el?.type || "";

      const dataRequired = {
        _id: el._id,
        name: el.name,
      };

      if (type?.toLowerCase() === "individual") {
        if (!individualServices[categoryName]) {
          individualServices[categoryName] = [];
        }

        individualServices[categoryName].push(dataRequired);
      }

      if (type?.toLowerCase() === "institution") {
        if (!institutionServices[categoryName]) {
          institutionServices[categoryName] = [];
        }

        institutionServices[categoryName].push(dataRequired);
      }
    });

  return {
    type: ON_FETCH_SERVICES,
    payload: {
      individual: individualServices,
      institution: institutionServices,
    },
  };
};

export const handleSearchService = (searchInput) => {
  return {
    type: ON_SEARCH_SERVICES,
    searchInput,
  };
};

export const makeCopyOfService = (payload) => {
  return {
    type: ON_COPY_SERVICES,
    payload,
  };
};
