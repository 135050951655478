import React from "react";
import "./styles.css";

const Checkbox = (props) => {
  return (
    <div className={`checkbox-container`} style={props.styles}>
      <label>
        <input
          type="checkbox"
          onChange={props.onCheck}
          checked={props.checked}
          style={{ cursor: "pointer" }}
        />
        <span>{props.label}</span>
      </label>
    </div>
  );
};
export default Checkbox;
