import { connect } from "react-redux";
import { Navbar } from "../components/Navbar";
import "./layout.css";

const RequestLayout = (props) => {
  return (
    <>
      <div
        className="dashboard-layout"
        style={{ overflowY: "hidden", padding: 0 }}
      >
        <div className="row">{props.children}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestLayout);
