import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import departmentQuery from "../../utils/queries/departmentQuery";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import apiError from "../../utils/apiError";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

class NewJobPosition extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    departments: [],
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    await this.getDepartments(true);
  };

  getDepartments = async (isFetchingDepartment) => {
    try {
      this.setState({
        isFetchingDepartment,
      });

      const data = await departmentQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedDepartment(data);

      this.setState({
        departments: data,
        isFetchingDepartment: false,
      });
    } catch (error) {
      this.setState({ isFetchingDepartment: false });
    }
  };

  setSelectedDepartment(departments) {
    const { department } = this.props;
    if (department) {
      const selectedDepartment = departments.find(
        (el) => el._id === department._id
      );

      if (selectedDepartment._id) {
        this.setState({
          department: {
            label: selectedDepartment.name,
            value: selectedDepartment._id,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { name, error, department } = this.state;

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].job_position_name_required;
    }

    if (!department) {
      error.department =
        language[this.props.defaultLanguage].department_name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    const {
      add_position_success,
      error_add_position,
      error_update_position,
      update_position_success,
      job_position_exists,
    } = language[this.props.defaultLanguage];

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, department } = this.state;

      let user = await getStorage();
      let method = "POST";
      let requestBody = {
        name,
        department: department.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        url: `${API_URL}/position`,
        method,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + user.token,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT" ? update_position_success : add_position_success
          );

          this.props.getData();
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_position : error_add_position
          );

          apiError(error, {
            addErrorMessage: error_add_position,
            updateErrorMessage: error_update_position,
            dataExistErrorMessage: job_position_exists,
          });

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <Select
            leftIcon={icons.department}
            options={this.state.departments}
            label={language[this.props.defaultLanguage].department}
            required
            value={this.state.department}
            onChange={(e) => this.onChangeText("department", e)}
            error={this.state.error.department}
            isLoading={this.state.isFetchingDepartment}
          />
          <Input
            leftIcon={icons.position}
            placeholder={
              language[this.props.defaultLanguage].job_position_name_placeholder
            }
            label={language[this.props.defaultLanguage].job_position_name}
            required
            value={this.state.name}
            onChange={(e) => this.onChangeText("name", e)}
            error={this.state.error.name}
          />
        </div>

        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewJobPosition);
