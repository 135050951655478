const { REACT_APP_NAME } = process.env;

export const setStorage = async (data, storageName = REACT_APP_NAME) =>
  await sessionStorage.setItem(storageName, JSON.stringify(data));

export const getStorage = async (storageName = REACT_APP_NAME) => {
  const storage = sessionStorage.getItem(storageName);
  const user = await JSON.parse(storage);

  return user ? user : {};
};

export const clearStorage = async () => {
  const user = await sessionStorage.clear();

  return user ? user : {};
};
