// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-container {
  padding-bottom: 3rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.services-container .service-item-title {
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.services-container .service-item {
  display: flex;
  margin-bottom: 1.5rem;
  opacity: 1;
  transition: opacity 0.4s;
}

.services-container .service-item a {
  color: var(--text-color-60);
  flex: 1 1;
  text-decoration: none;
  transition: -webkit-text-decoration 0.4s;
  transition: text-decoration 0.4s;
  transition: text-decoration 0.4s, -webkit-text-decoration 0.4s;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.services-container .service-item a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.services-container .more-results {
  min-height: 45px;
  display: flex;
  align-items: center;
}

.services-container .more-results a {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.4s;
}

.services-container .more-results a:hover {
  opacity: 0.5;
}

.services-container .more-results a span {
  font-size: var(--font-size-);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Services/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,SAAO;EACP,qBAAqB;EACrB,wCAAgC;EAAhC,gCAAgC;EAAhC,8DAAgC;EAChC,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".services-container {\r\n  padding-bottom: 3rem;\r\n  padding-top: 1rem;\r\n  padding-left: 1rem;\r\n}\r\n\r\n.services-container .service-item-title {\r\n  min-height: 50px;\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.services-container .service-item {\r\n  display: flex;\r\n  margin-bottom: 1.5rem;\r\n  opacity: 1;\r\n  transition: opacity 0.4s;\r\n}\r\n\r\n.services-container .service-item a {\r\n  color: var(--text-color-60);\r\n  flex: 1;\r\n  text-decoration: none;\r\n  transition: text-decoration 0.4s;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 2;\r\n  -webkit-box-orient: vertical;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  text-transform: capitalize;\r\n}\r\n\r\n.services-container .service-item a:hover {\r\n  opacity: 0.8;\r\n  text-decoration: underline;\r\n}\r\n\r\n.services-container .more-results {\r\n  min-height: 45px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.services-container .more-results a {\r\n  display: flex;\r\n  align-items: center;\r\n  opacity: 1;\r\n  transition: opacity 0.4s;\r\n}\r\n\r\n.services-container .more-results a:hover {\r\n  opacity: 0.5;\r\n}\r\n\r\n.services-container .more-results a span {\r\n  font-size: var(--font-size-);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
