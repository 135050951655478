import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import Sidebar from "./Sidebar";
import UserGuideData from "./UserGuideData";
import { data } from "../../constants/user-guide";

class UserGuidePublic extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-8">
            <UserGuideData data={data} />
          </div>
          <div className="col-md-4" style={{ position: "relative" }}>
            <Sidebar data={data} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(UserGuidePublic));
