import React from "react";
import AuthFormLayout from "../../../Layout/AuthFormLayout";
import { Login } from "../../../components/AdminLogin";

class LoginScreen extends React.Component {
  render() {
    return (
      <AuthFormLayout>
        <Login showSideDescriptionSlides showFormTitle />
      </AuthFormLayout>
    );
  }
}

export default LoginScreen;
