import { DashboardHeader } from "../../../Header";
import Attachments from "../../widgets/Attachments";
import language from "../../../../language";

export default (that) => {
  if (that.state.attachments?.length > 0)
    return (
      <>
        <DashboardHeader
          title={`${language[that.props.defaultLanguage].attachments} (${
            that.state.attachments.length
          })`}
          size="md"
        />
        <div style={{ padding: "0 1rem" }}>
          <Attachments data={that.state?.attachments} />
        </div>
      </>
    );
};
