import language from "../language";

export default (lng = "english") => {
  return [
    {
      label: language[lng].individual,
      value: "individual",
    },
    {
      label: language[lng].institution,
      value: "institution",
    },
  ];
};
