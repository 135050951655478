// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-tabs {
  background-color: var(--primary-color-5);
}

.category-tabs .landing-section-content {
  min-height: 0px !important;
}

.category-tabs .tabs-container {
  min-height: 50px;
  align-items: center;
  display: flex;
  overflow-x: auto;
}

.category-tabs .tabs-container .category-tab {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.category-tabs .tabs-container .category-tab span {
  font-weight: var(--font-weight-md);
  color: var(--text-color-60);
  white-space: nowrap;
}

.category-tabs .tabs-container .category-tab.active {
  border-bottom: 2px solid var(--primary-color);
}

.category-tabs .tabs-container .category-tab.active span {
  color: var(--primary-color);
}

@media screen and (max-width: 500px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .category-tabs {
    padding-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/CategoryTab/styles.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".category-tabs {\n  background-color: var(--primary-color-5);\n}\n\n.category-tabs .landing-section-content {\n  min-height: 0px !important;\n}\n\n.category-tabs .tabs-container {\n  min-height: 50px;\n  align-items: center;\n  display: flex;\n  overflow-x: auto;\n}\n\n.category-tabs .tabs-container .category-tab {\n  min-height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 1.5rem;\n  cursor: pointer;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.category-tabs .tabs-container .category-tab span {\n  font-weight: var(--font-weight-md);\n  color: var(--text-color-60);\n  white-space: nowrap;\n}\n\n.category-tabs .tabs-container .category-tab.active {\n  border-bottom: 2px solid var(--primary-color);\n}\n\n.category-tabs .tabs-container .category-tab.active span {\n  color: var(--primary-color);\n}\n\n@media screen and (max-width: 500px) {\n  .category-tabs {\n    padding-top: 2rem;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .category-tabs {\n    padding-top: 2rem;\n  }\n}\n\n@media screen and (max-width: 980px) {\n  .category-tabs {\n    padding-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
