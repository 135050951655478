import User from "../../widgets/User";

export default (that, { account, actionStatus, date }) => {
  return (
    <User
      name={that.setUserName(account)}
      userTitle={
        that.setDepartmentName(account) + " " + that.setPositionName(account)
      }
      institution={that.setInstitution(account)}
      actionStatus={
        actionStatus === "Approval"
          ? "Approved"
          : actionStatus === "Rejection"
          ? "Rejected"
          : actionStatus
      }
      date={date}
    />
  );
};
