import React from "react";
import "./App.css";
import "./app/common/styles/scrollbar.styles.css";
import "./app/common/styles/auth.form.styles.css";
import "./app/common/styles/media.queries/general.media.queries.css";
import "./app/common/styles/border.styles.css";
import "./app/common/styles/card.styles.css";
import "./app/common/styles/dropdown.styles.css";
import "./app/common/styles/icons.styles.css";
import "./app/common/styles/label.styles.css";
import "./app/common/styles/margin.styles.css";
import "./app/common/styles/modals.styles.css";
import "./app/common/styles/text.styles.css";
import "./app/common/styles/ul.styles.css";
import "./app/common/styles/badge.styles.css";
import "./app/common/styles/hr.styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AdminRoutes, CommonRoutes, PublicServants } from "./app/routes";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { connect } from "react-redux";
import {
  handleNotification,
  fetchCountsForRequestSidebar,
} from "./app/utils/socket/listeners/notifications";
import UserGuideRoutes from "./app/routes/user-guide.routes";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

class App extends React.Component {
  componentDidMount = async () => {
    this.getCounts();

    //alert notifications
    if (this.props.requestCounts.pending)
      document.title = `(${this.props.requestCounts.pending}) Pending Requests - ${process.env.REACT_APP_TITLE}`;
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getCounts();
    }
  }
  getCounts = async () => {
    await handleNotification(this);
    await fetchCountsForRequestSidebar(this, "pending");
  };

  render() {
    return (
      <>
        <CommonRoutes />
        <AdminRoutes />
        <PublicServants />
        <UserGuideRoutes />
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { notifications } = state.Notifications;
  const { requestCounts } = state.Request;
  const { filters } = state.Filters;
  return {
    notifications,
    requestCounts,
    filters,
  };
};
export default connect(mapStateToProps)(App);
