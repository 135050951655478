import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import { Button } from "../Button";
import icons from "../../constants/icons";
import PDF_EXT from "../../assets/pdf.png";
import FILE_EXT from "../../assets/docs.png";
import DOCS_EXT from "../../assets/docs.png";

const AttachmentItem = (props) => {
  let imageSrc = props.url;

  if (props.extension === "pdf") {
    imageSrc = PDF_EXT;
  } else if (props.extension === "doc" || props.extension === "docx") {
    imageSrc = DOCS_EXT;
  } else {
    imageSrc = FILE_EXT;
  }
  return (
    <div key={props.key}>
      <div className="attachment-item-container">
        <div className="attachment-item-extension-container">
          <img src={imageSrc} />
        </div>
        <div className="attachment-item-file-info">
          <h3 onClick={() => window.open(props.url, "_blank")}>{props.name}</h3>
          {props.size && <p className="text-disabled">{props.size}</p>}
        </div>
        <div className="attachment-item-action-button-container">
          {props.showRemove && (
            <Button
              title="Delete"
              className="btn-sm btn-transparent danger"
              icon={icons.delete}
              onPress={props.handleRemoveFile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AttachmentItem);
