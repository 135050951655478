import isJsonString from "./isJsonString";

const getTextFromObject = (obj, path, def) => {
  if (!path) return "nofound";
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== "string") return path;

    // Create new array
    var output = [];

    // Split to an array with dot notation
    path.split(".").forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  var current = obj;

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)

    if (current[path[i]] !== false && !current[path[i]]) return def;

    // Otherwise, update the current  value
    current = current[path[i]];
  }

  return current + "";
};

export default (obj, path, def) => {
  return getTextFromObject(obj, path, def);
};

export const JSONStringRowView = (str = "") => {
  try {
    if (!isJsonString(str)) return;

    const data = JSON.parse(str);

    return (
      <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        <span>{data["label"] + " " + data["value"]}</span>
      </div>
    );

    // return Object.keys(data).map((key) => {
    //   return (
    //     <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
    //       <span>{key}:</span>
    //       <span>
    //         <b>{data[key] + ""}</b>
    //       </span>
    //     </div>
    //   );
    // });
  } catch (error) {
    console.log(error);
  }
};

export const returnMultiAnswer = (obj, path, def) => {
  let multiAnswer = [];

  const current = getTextFromObject(obj, path, def);

  if (typeof current === "object") {
    let title = [];
    for (let i = 0; i < current.length; i++) {
      multiAnswer.push(
        <div className="table-multi-answer" key={i}>
          {!title.includes(current[i].title) && (
            <div className="table-multi-answer-title">
              <span>{current[i].title}</span>
            </div>
          )}

          <div className="table-multi-answer-body">
            <span>{current[i].field + ": "}</span>
            <span className="badge badge-success">{current[i].answer}</span>
          </div>
        </div>
      );

      title.push(current[i].title);
    }
  }

  return multiAnswer.length > 0 ? multiAnswer : undefined;
};
