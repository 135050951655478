import { Route, Routes, Outlet } from "react-router-dom";
import UserGuide from "../screens/userGuide";

const routes = [
  {
    path: "/user-guide/*",
    element: <UserGuide />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
