import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import accountQuery from "../../../utils/queries/accountQuery";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import accessRoleQuery from "../../../utils/queries/accessRoleQuery";
class NewLevel extends React.Component {
  state = {
    error: {},
    accounts: [],
    employeeId: "",
    profileData: {},
    username: "",
    department: "",
    institution: "",
    position: "",
    individualMaxResponseTime: "",
    level: "",
    remainingIndividualMaxResponseTime: 0,
    roles: [],
  };

  componentDidMount() {
    this.getRoles(true);
    this.getAccounts(true);
    this.checkRemainingResponseTime();
    this.setLevel();

    this.handlePrefill();
  }

  handlePrefill() {
    if (this.props._id) {
      let accessRole, account;

      if (this.props.accessRole && this.props.accessRole._id) {
        accessRole = {
          label: this.props.accessRole.name,
          value: this.props.accessRole._id,
        };
      }

      if (this.props.account && this.props.account._id) {
        account = {
          label:
            this.props.account.firstName + " " + this.props.account.lastName,
          value: this.props.account._id,
        };
      }
      this.setState({
        individualMaxResponseTime: this.props.individualMaxResponseTime,
        level: this.props.level,
        accessRole,
        account,
      });
    }
  }

  checkRemainingResponseTime() {
    const { serviceData } = this.props;
    const { maxResponseTime, approver } = serviceData;
    let { remainingIndividualMaxResponseTime } = this.state;

    let totalIndividualMaxResponseTime = 0;

    approver?.forEach((el) => {
      totalIndividualMaxResponseTime += el.individualMaxResponseTime;
    });

    remainingIndividualMaxResponseTime =
      maxResponseTime - totalIndividualMaxResponseTime;

    this.setState({ remainingIndividualMaxResponseTime });
  }

  setLevel() {
    const { serviceData } = this.props;
    const { approver = [] } = serviceData;

    let level = 1;

    if (approver.length === 0) {
      this.setState({ level });
      return;
    }

    const highestLevel = Math.max(...approver?.map((o) => o.level));
    level = highestLevel + 1;
    this.setState({ level });
  }

  getAccounts = async (isFetchingAccount) => {
    try {
      this.setState({
        isFetchingAccount,
      });

      const { data } = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
        accessRole: this.state.accessRole?.value || undefined,
      });

      let accounts = [];

      for (let account of data) {
        if (
          !this.props.serviceData.approver.find(
            (elAcc) => (elAcc?.account?._id || elAcc.account) === account._id
          )
        ) {
          accounts.push(account);
        }
      }

      this.setState({
        accounts,
        isFetchingAccount: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccount: false });
    }
  };

  getRoles = async (isFetchingAccessRole) => {
    try {
      this.setState({
        isFetchingAccessRole,
      });

      const data = await accessRoleQuery(this.props.defaultLanguage, {
        type: "dropdown",
        outsideOrganization: false,
      });

      let roles = [];

      for (let role of data) {
        if (!role.outsideOrganization) {
          roles.push(role);
        }
      }

      this.setState({
        roles,
        isFetchingAccessRole: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccessRole: false });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];
    delete error.errorMessage;

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });

    if (field === "account") {
      this.setState({
        username: e.username,
        position: e.position ? e.position.name : {},
        department: e.department ? e.department.name : "",
        employeeId: e.employeeId,
        institution: e.institution,
      });
    }

    if (field === "accessRole") {
      this.getAccounts(true);
    }
  }

  validateForm() {
    let {
      error,
      level,
      individualMaxResponseTime,
      account,
      remainingIndividualMaxResponseTime,
      accessRole,
    } = this.state;

    let { serviceData } = this.props;
    let { approver = [] } = serviceData;

    if (!account && !accessRole) {
      error.errorMessage =
        language[this.props.defaultLanguage].approver_required;
      error.account = "";
      error.accessRole = "";
    } else if (
      !this.props.edit &&
      approver.find(
        (fEl) =>
          (fEl.account && account && fEl?.account._id === account.value) ||
          (!fEl.account &&
            fEl.accessRole &&
            accessRole &&
            fEl.accessRole._id === accessRole.value)
      )
    ) {
      error.errorMessage =
        language[this.props.defaultLanguage].approver_already_exist;
    }

    if (level === "") {
      error.level = language[this.props.defaultLanguage].level_required;
    } else if (
      !this.props.edit &&
      approver.find((fEl) => fEl.level === level)
    ) {
      error.level = language[this.props.defaultLanguage].level_already_taken;
    }

    if (individualMaxResponseTime === "") {
      error.individualMaxResponseTime =
        language[
          this.props.defaultLanguage
        ].individual_max_response_time_required;
    } else if (
      !this.props.edit &&
      remainingIndividualMaxResponseTime < individualMaxResponseTime
    ) {
      error.individualMaxResponseTime =
        language[this.props.defaultLanguage]
          .individual_max_response_time_must_less_than +
        " " +
        remainingIndividualMaxResponseTime +
        " " +
        language[this.props.defaultLanguage].days;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    let { serviceData, defaultLanguage, dataIndex } = this.props;
    let { approver = [] } = serviceData;

    if (!serviceData._id) {
      return toastMessage(
        "error",
        language[defaultLanguage].service_is_missing
      );
    }

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      let user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      const { individualMaxResponseTime, level, account, accessRole } =
        this.state;

      let method = "PUT";
      //update
      if (dataIndex !== undefined) {
        approver[dataIndex].account = account.value || account;
        approver[dataIndex].level = level;
        approver[dataIndex].individualMaxResponseTime =
          individualMaxResponseTime;
        approver[dataIndex].accessRole = accessRole?.value || accessRole;
      } else {
        approver.push({
          account: account?.value || account,
          individualMaxResponseTime,
          level,
          accessRole: accessRole?.value || accessRole,
        });
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: {
          id: serviceData._id,
          approver,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].add_approver_success
          );

          this.props.handleCloseModal();
          this.props.getData();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].error_add_approver
          );
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              {this.state.error.errorMessage}
            </div>
          )}
          <div className="col-md-12">
            <Select
              leftIcon={icons.role}
              options={this.state.roles}
              label={language[this.props.defaultLanguage].access_role}
              placeholder={language[this.props.defaultLanguage].select}
              value={this.state.accessRole}
              onChange={(e) => this.onChangeText("accessRole", e)}
              error={this.state.error.accessRole}
              isLoading={this.state.isFetchingAccessRole}
            />
          </div>
          <Select
            options={this.state.accounts}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].account}
            value={this.state.account}
            onChange={(e) => this.onChangeText("account", e)}
            error={this.state.error.account}
            leftIcon={icons.user}
            isLoading={this.state.isFetchingAccount}
          />
          {this.state?.account?._id && (
            <>
              <Input
                placeholder={
                  language[this.props.defaultLanguage].employee_id_placeholder
                }
                label={language[this.props.defaultLanguage].employee_id}
                required
                value={this.state.employeeId}
                onChange={(e) => this.onChangeText("employeeId", e)}
                error={this.state.error.employeeId}
                leftIcon={icons.identification}
                disabled
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage].username_placeholder
                }
                label={language[this.props.defaultLanguage].username}
                required
                value={this.state.username}
                onChange={(e) => this.onChangeText("username", e)}
                error={this.state.error.username}
                leftIcon={icons.user}
                disabled
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage].job_position_placeholder
                }
                label={language[this.props.defaultLanguage].job_position}
                required
                value={this.state.position}
                error={this.state.error.position}
                leftIcon={icons.position}
                disabled
              />
            </>
          )}
          <Input
            placeholder={language[this.props.defaultLanguage].level_placeholder}
            label={language[this.props.defaultLanguage].level}
            required
            // value={this.state.position.level}
            value={this.state.level}
            error={this.state.error.level}
            leftIcon={icons.level}
            onChange={(e) => this.onChangeText("level", e)}
            type="number"
            min={1}
          />
          <Input
            placeholder={
              language[this.props.defaultLanguage]
                .individual_max_response_time_placeholder
            }
            label={
              language[this.props.defaultLanguage].individual_max_response_time
            }
            required
            value={this.state.individualMaxResponseTime}
            error={this.state.error.individualMaxResponseTime}
            leftIcon={icons.time}
            type="number"
            onChange={(e) => this.onChangeText("individualMaxResponseTime", e)}
            helperText={
              this.state.remainingIndividualMaxResponseTime > 0
                ? language[this.props.defaultLanguage].remainingDays +
                  " " +
                  this.state.remainingIndividualMaxResponseTime +
                  " day(s)"
                : ""
            }
            max={this.state.remainingIndividualMaxResponseTime}
            min={this.state.remainingIndividualMaxResponseTime}
          />
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
            disabled={
              !this.props.edit &&
              this.state.remainingIndividualMaxResponseTime <= 0
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewLevel);
