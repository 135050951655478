import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import moment from "moment";
import { UserSMProfile } from "../../User";
import { Button, DropdownButton } from "../../Button";
import icons from "../../../constants/icons";
import AttachmentItem from "../../Attachment/AttachmentItem";
import { Attachments } from "../../Attachment";
import { ListItem } from "../../List";
import { Modal } from "../../Modal";
import RequestChanges from "../../RequestChanges/RequestChanges";
import toastMessage from "../../../utils/toastMessage";

class Comment extends React.Component {
  state = {
    comment: "",
    error: {},
    user: {},
    isRequestingChanges: false,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  validateForm = () => {
    const { comment, error } = this.state;

    if (comment === "") {
      error.comment = language[this.props.defaultLanguage].comment_required;
    }

    this.setState({
      error,
    });
  };

  findAndUpdateSender = (data) => {
    let { user, comment } = this.state;

    return data.map((el) => {
      if ((el.account._id || el.account) === user.id) {
        el.actionComment = comment;
      }

      return el;
    });
  };

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleRequestChanges() {
    if (
      (!this.props.commentText || this.props.commentText === "") &&
      (!this.props.file || this.props.file === "")
    ) {
      console.log("====================================");
      console.log();
      console.log("====================================");
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].cant_send_empty_request
      );
    }
    this.setState({
      isRequestingChanges: true,
    });
  }

  handleDoneRequesting() {
    this.setState({
      isRequestingChanges: false,
    });
  }

  render() {
    return (
      <>
        <div>
          <div className="comments-list-container">
            {this.props.comments.map((el, i) => {
              if (!el.user) return;

              return (
                <div style={{ paddingTop: "1.5rem" }} key={i}>
                  {el.user && (
                    <UserSMProfile
                      firstName={el.user.username}
                      department={el.user.department}
                      position={el.user.position}
                      institution={el.user.institution}
                      actionStatus={el.actionStatus}
                    />
                  )}
                  <div style={{ marginLeft: 75, paddingTop: "1.5rem" }}>
                    <div className="mb-3">
                      <span>{el.text}</span>
                    </div>
                    {el?.questionnaireAction?.length > 0 && (
                      <Attachments data={el.questionnaireAction} />
                    )}
                    <br />
                    <small className="text-disabled">
                      {moment(el.date).format("lll")}
                    </small>
                  </div>
                  {i !== this.props.data.length - 1 && (
                    <>
                      <hr />
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {this.props.showApproverActions && (
            <div
              style={this.props.data.length > 0 ? { marginTop: "1.5rem" } : {}}
            >
              {this.props.data.length > 0 && <hr />}
              {this.props.approver && this.props.approver.account && (
                <UserSMProfile
                  firstName={this.props.approver?.account?.firstName}
                  lastName={this.props.approver?.account?.lastName}
                  department={this.props.approver?.account?.department}
                  position={this.props.approver?.account?.position}
                  institution={this.props.approver?.account?.institution}
                  actionStatus={this.props.approver?.account?.actionStatus}
                />
              )}
              <Input
                textarea
                placeholder={language[this.props.defaultLanguage].write_comment}
                value={this.props.commentText}
                onChange={this.props.onChangeCommentText}
                error={this.props.error.commentText}
              />
            </div>
          )}

          {this.props?.file && (
            <div className="mb-3">
              <AttachmentItem
                extension={
                  this.props?.file &&
                  this.props?.file?.name?.split(".")[
                    this.props?.file?.name?.split(".")?.length - 1
                  ]
                }
                url={URL.createObjectURL(this.props.file)}
                name={this.props?.file?.name}
                showRemove
                handleRemoveFile={this.props.handleRemoveFile}
                handleShowModal={this.props.handleShowModal}
                showFullscreen
              />
            </div>
          )}
          {this.props.showApproverActions && (
            <div className="d-flex">
              <div style={{ flex: 1 }}>
                <Button
                  text={language[this.props.defaultLanguage].attach_file}
                  className="btn-default"
                  icon={icons.attach_file_add}
                  onPress={() => this.props.fileUploadAction()}
                />
              </div>
              <div className="d-flex gap-2">
                <Button
                  text={language[this.props.defaultLanguage].request_changes}
                  className="btn-default"
                  icon={icons.update}
                  onPress={this.handleRequestChanges.bind(this)}
                  isSubmitting={this.state.isRequestingChanges}
                />
                <Button
                  text={language[this.props.defaultLanguage].reject}
                  className="btn-danger"
                  icon={icons.fail}
                  onPress={this.props.handleShowRejectModal}
                />
                {this.state?.data?.subApprovalStatus !== "rejected" && (
                  <Button
                    text={language[this.props.defaultLanguage].approve}
                    icon={icons.success}
                    onPress={this.props.handleShowApproveModal}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {/* <Modal
          handleClose={this.handleCloseModal.bind(this, "requestChangesModal")}
          show={this.state.requestChangesModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >

        </Modal> */}
        <RequestChanges
          {...this.props}
          handleDone={this.handleDoneRequesting.bind(this)}
          isRequestingChanges={this.state.isRequestingChanges}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Comment);
