// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
}
.slide-item-container .slide-item-image {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.slide-item-container .slide-item-image img {
  aspect-ratio: 2/2;
  width: 100%;
  object-fit: contain;
}

.slide-item-container .slide-item-content {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.slide-item-container .slide-item-content p {
  margin-top: 1rem;
  color: var(--text-color-60);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Slides/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".slide-item-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  max-width: 300px;\n}\n.slide-item-container .slide-item-image {\n  width: 100px;\n  height: 100px;\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n}\n.slide-item-container .slide-item-image img {\n  aspect-ratio: 2/2;\n  width: 100%;\n  object-fit: contain;\n}\n\n.slide-item-container .slide-item-content {\n  margin: 1.5rem 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  text-align: center;\n}\n\n.slide-item-container .slide-item-content p {\n  margin-top: 1rem;\n  color: var(--text-color-60);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
