import MyRequestsDashboard from "../screens/PublicServant/Requests/MyRequestsDashboard";
import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import NotificationScreen from "../screens/common/NotificationScreen";
import language from "../language";
import icons from "./icons";

const data = (lng = "english") => {
  return [
    {
      parentPath: "/public-servant",
      path: "/my requests",
      name: language[lng].my_requests,
      icon: icons.request,
      component: <MyRequestsDashboard />,
    },
    {
      parentPath: "/public-servant",
      path: "/notifications",
      name: language[lng].notifications,
      icon: icons.notification,
      component: <NotificationScreen />,
      notificationStorageName: "notifications",
      showNotification: true,
    },
    {
      parentPath: "/public-servant",
      path: "/settings",
      name: language[lng].settings,
      icon: icons.setting,
      component: <SettingScreen />,
    },
  ];
};

export default data;
